import React from 'react'
import Highcharts from 'highcharts'

import cn from 'classnames'

import * as s from './Legend.module.scss'

interface ILegend {
  serie: Highcharts.Series
  onClick: (serie: Highcharts.Series, visible: boolean) => void
  visible?: boolean
}

const Legend: React.FC<ILegend> = ({ serie, onClick, visible }) => {
  const handleLegend = () => {
    onClick(serie, !serie.visible)
  }

  return (
    <button
      type="button"
      onClick={handleLegend}
      className={cn(s.legend, { [s.inactive]: !visible })}
      style={{ color: serie.color as string }}
    >
      <span className={s.title}>{serie.name}</span>
    </button>
  )
}

export default Legend
