import { formatObjectKey } from '~utils'
import { METRIC_TYPES } from '~constants'

const getKey = (name) => formatObjectKey(name)

const getMetricTypeKey = (name, metricTypes) => {
  const key = getKey(name)
  const metricTypeKey = metricTypes?.[key]
  return metricTypeKey
}

export const getIsNPSParameter = (name, metricTypes) => {
  const metricTypeKey = getMetricTypeKey(name, metricTypes)
  const isNPS = metricTypeKey === METRIC_TYPES.NPS
  return isNPS
}

export const getIsScoreParameter = ({
  name,
  metricTypes,
  isNPS,
  isScoreMode,
}) => {
  if (!isNPS && isScoreMode) {
    const metricTypeKey = getMetricTypeKey(name, metricTypes)
    return metricTypeKey
  }
  return false
}
