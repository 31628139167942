import React from 'react'
import cn from 'classnames'

import { useBrandLogo } from '~hooks'

import { option, active, optionText } from './style.module.scss'

export const Option = ({ brand, isActive, handleOption }) => {
  const brandLogo = useBrandLogo(brand)

  const content = (
    <>
      {brandLogo} <span className={optionText}>{brand}</span>
    </>
  )

  return isActive ? (
    <span className={cn(option, active)}>{content}</span>
  ) : (
    <button type="button" className={option} onClick={handleOption(brand)}>
      {content}
    </button>
  )
}
