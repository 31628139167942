import React from 'react'

import { Error } from '~pages/Error'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  render() {
    const { state, props } = this
    if (state.hasError) {
      return <Error withRedirect={props.withRedirect} />
    }

    return props.children
  }
}

export { ErrorBoundary }
