import React, { Suspense, useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import { ROUTES } from '~routes'
import { useAccount } from '~hooks'

import './App.scss'

import { Error } from './pages/Error'
import { LoginSSO } from './pages/LoginSSO'
import { CallbackSSO } from './pages/CallbackSSO'
import { PasswordInitReset } from './pages/PasswordInitReset'
import { PasswordReset } from './pages/PasswordReset'
import { IDP } from './pages/IDP'
import { ErrorBoundary } from './components/ErrorBoundary'
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute'

const Competitive = React.lazy(() =>
  import(/* webpackChunkName: "Competitive" */ './pages/Dashboard/Competitive')
)
const Market = React.lazy(() =>
  import(/* webpackChunkName: "Market" */ './pages/Dashboard/Market')
)
const CustomerProfile = React.lazy(() =>
  import(
    /* webpackChunkName: "CustomerProfile" */ './pages/Dashboard/CustomerProfile'
  )
)
const CustomerExperience = React.lazy(() =>
  import(
    /* webpackChunkName: "CustomerExperience" */ './pages/Dashboard/CustomerExperience'
  )
)
const PetcoCustomerExperience = React.lazy(() =>
  import(
    /* webpackChunkName: "PetcoCustomerExperience" */ './pages/Dashboard/PetcoCustomerExperience'
  )
)
const CustomerMigration = React.lazy(() =>
  import(
    /* webpackChunkName: "CustomerMigration" */ './pages/Dashboard/CustomerMigration'
  )
)
const GenericDashboard = React.lazy(() =>
  import(
    /* webpackChunkName: "GenericDashboard" */ './pages/Dashboard/GenericDashboard'
  )
)
const KeyPerformance = React.lazy(() =>
  import(
    /* webpackChunkName: "KeyPerformance" */ './pages/Dashboard/KeyPerformance'
  )
)
const Login = React.lazy(() =>
  import(/* webpackChunkName: "Login" */ './pages/Login')
)
const BrandPerception = React.lazy(() =>
  import(
    /* webpackChunkName: "BrandPerception" */ './pages/Dashboard/BrandPerception'
  )
)
const Home = React.lazy(() =>
  import(/* webpackChunkName: "Home" */ './pages/Home')
)
const Settings = React.lazy(() =>
  import(/* webpackChunkName: "Settings" */ './pages/Settings')
)
const InsightsBriefing = React.lazy(() =>
  import(
    /* webpackChunkName: "InsightsBriefing" */ './pages/Dashboard/InsightsBriefing'
  )
)
const Unsubscribe = React.lazy(() =>
  import(/* webpackChunkName: "Unsubscribe" */ './pages/Unsubscribe')
)
const TrackerBuilder = React.lazy(() =>
  import(
    /* webpackChunkName: "TrackerBuilder" */ './pages/Dashboard/TrackerBuilder'
  )
)

const BrandLogos = React.lazy(() =>
  import(/* webpackChunkName: "BrandLogos" */ './pages/BrandLogos')
)

const SpriteIcons = React.lazy(() =>
  import(/* webpackChunkName: "SpriteIcons" */ './pages/SpriteIcons')
)

const PublicMarketing = React.lazy(() =>
  import(/* webpackChunkName: "PublicMarketing" */ './pages/PublicMarketing')
)

const App = () => {
  const { data } = useAccount()
  const { update } = useIntercom()

  useEffect(() => {
    if (!data) return

    const { account_id, user_hash, name, email, company } = data
    update({
      name,
      userId: account_id,
      userHash: user_hash,
      email,
      created_at: Date.now(),
      company: {
        name: company?.name,
        companyId: `${company?.id}`,
        customAttributes: {
          account_manager_email_: company?.account_manager_email,
          account_manager_name_: company?.account_manager_name,
          market_: company?.market,
        },
      },
    })
  }, [data])

  // Route should be direct children of Switch or else router bugs out
  return (
    <ErrorBoundary>
      <Suspense fallback={<div />}>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route exact path={ROUTES.HOME} element={<Home />} />
            <Route
              exact
              path={ROUTES.COMPETITIVE_PERFORMANCE}
              element={
                <ErrorBoundary withRedirect>
                  <Competitive />
                </ErrorBoundary>
              }
            />
            <Route
              exact
              path={ROUTES.BRAND_PERCEPTION}
              element={
                <ErrorBoundary withRedirect>
                  <BrandPerception />
                </ErrorBoundary>
              }
            />
            <Route
              exact
              path={ROUTES.CUSTOMER_PROFILE}
              element={
                <ErrorBoundary withRedirect>
                  <CustomerProfile />
                </ErrorBoundary>
              }
            />
            <Route
              exact
              path={ROUTES.CUSTOMER_EXPERIENCE}
              element={
                <ErrorBoundary withRedirect>
                  <CustomerExperience />
                </ErrorBoundary>
              }
            />
            <Route
              exact
              path={ROUTES.PETCO_INSTORE_CUSTOMER_EXPERIENCE}
              element={
                <ErrorBoundary withRedirect>
                  <PetcoCustomerExperience
                    channel="instore"
                    benchmark="PetSmart"
                    title="Petco In-Store Customer Experience"
                  />
                </ErrorBoundary>
              }
            />
            <Route
              exact
              path={ROUTES.PETCO_DIGITAL_CUSTOMER_EXPERIENCE}
              element={
                <ErrorBoundary withRedirect>
                  <PetcoCustomerExperience
                    brand="Petco.com"
                    channel="online"
                    benchmark="Chewy.com"
                    title="Petco.com Digital Customer Experience"
                  />
                </ErrorBoundary>
              }
            />
            <Route
              exact
              path={ROUTES.CUSTOMER_MIGRATION}
              element={
                <ErrorBoundary withRedirect>
                  <CustomerMigration />
                </ErrorBoundary>
              }
            />
            <Route
              exact
              path={ROUTES.MARKET_PERFORMANCE}
              element={
                <ErrorBoundary withRedirect>
                  <Market />
                </ErrorBoundary>
              }
            />
            <Route
              exact
              path={ROUTES.GENERIC_DASHBOARD}
              element={
                <ErrorBoundary withRedirect>
                  <GenericDashboard />
                </ErrorBoundary>
              }
            />
            <Route
              exact
              path={ROUTES.KPI_PERFORMANCE}
              element={
                <ErrorBoundary withRedirect>
                  <KeyPerformance />
                </ErrorBoundary>
              }
            />
            <Route
              exact
              path={ROUTES.INSIGHTS_BRIEFINGS}
              element={
                <ErrorBoundary withRedirect>
                  <InsightsBriefing />
                </ErrorBoundary>
              }
            />
            <Route
              exact
              path={ROUTES.TRACKER_BUILDER}
              element={
                <ErrorBoundary withRedirect>
                  <TrackerBuilder />
                </ErrorBoundary>
              }
            />
            <Route
              exact
              path={`${ROUTES.TRACKER_BUILDER}/:id`}
              element={
                <ErrorBoundary withRedirect>
                  <TrackerBuilder />
                </ErrorBoundary>
              }
            />
            <Route
              exact
              path={ROUTES.SETTINGS}
              element={
                <ErrorBoundary withRedirect>
                  <Settings />
                </ErrorBoundary>
              }
            />
            <Route exact path={ROUTES.IDP} element={<IDP />} />
          </Route>

          <Route exact path={ROUTES.LOGIN} element={<Login />} />
          <Route exact path={ROUTES.LOGIN_SSO} element={<LoginSSO />} />
          <Route exact path={ROUTES.CALLBACK} element={<CallbackSSO />} />
          <Route
            exact
            path={ROUTES.PASSWORD_INIT_RESET}
            element={<PasswordInitReset />}
          />
          <Route
            exact
            path={ROUTES.PASSWORD_RESET}
            element={<PasswordReset />}
          />
          <Route exact path={ROUTES.HEALTH} element={<h1>I&apos;m alive</h1>} />
          <Route exact path={ROUTES.ERROR} element={<Error />} />
          <Route exact path={ROUTES.UNSUBSCRIBE} element={<Unsubscribe />} />

          <Route
            exact
            path={ROUTES.PUBLIC_MARKETING}
            element={<PublicMarketing />}
          />

          {(process.env.REACT_APP_ENV === 'sandbox' ||
            process.env.REACT_APP_VERCEL_ENV === 'preview') && (
            <>
              <Route exact path={ROUTES.BRAND_LOGOS} element={<BrandLogos />} />
              <Route
                exact
                path={ROUTES.SPRITE_ICONS}
                element={<SpriteIcons />}
              />
            </>
          )}

          <Route path="*" element={<Navigate to={ROUTES.HOME} replace />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  )
}

export default App
