export enum TBMode {
  VIEWING = 'viewing',
  EDITING = 'editing',
}

export enum TBBlockType {
  CHART = 'chart',
  DIVIDER = 'divider',
}

export enum TBChartType {
  LINE_CHART_TREND = 'line-chart-trend',
  LINE_CHART_PIT = 'line-chart-pit',

  SINGLE_METRIC_BAR_CHART_PIT = 'single-metric-bar-chart-pit',
  SINGLE_METRIC_BAR_CHART_TREND = 'single-metric-bar-chart-trend',

  SINGLE_METRIC_TABLE_PIT = 'single-metric-table-pit',
  SINGLE_METRIC_TABLE_TREND = 'single-metric-table-trend',

  MULTI_METRIC_BAR_CHART_PIT = 'multi-metric-bar-chart-pit',
  MULTI_METRIC_BAR_CHART_TREND = 'multi-metric-bar-chart-trend',

  MULTI_METRIC_TABLE_PIT = 'multi-metric-table-pit',
  MULTI_METRIC_TABLE_TREND = 'multi-metric-table-trend',

  TOP_DRIVERS = 'top-drivers',
  UNIQUE_DRIVERS = 'unique-drivers',
}

export enum TBChartTrendType {
  POINT_IN_TIME = 'pit',
  YEAR_OVER_YEAR = 'yoy',
  PERIOD_OVER_PERIOD = 'pop',
  VS_2019 = '2019',
}

export enum TBChartScoreType {
  BIP_SCORE = 'bip',
  PERCENT_SCORE = 'pct',
  TOP_BOX = 'topbox',
  AVERAGE_SCORE = 'average',
}

export enum TBAction {
  /* EDITING MODE */
  SETTINGS = 'settings',
  SAVE = 'save',
  DELETE = 'delete',

  /* VIEWING MODE */
  EDIT = 'edit',
  EXPORT = 'export',
  SUBSCRIBE = 'subscribe',
}

export enum TBTopicType {
  TOTAL_PERFORMANCE = 'Total Performance',
  TRANSACTION_KPIS = 'Transaction KPIs',
  FOOT_TRAFFIC = 'Foot Traffic KPIs',
  CUSTOMER_EXPERIENCE_RESTAURANT = 'Customer Experience (Restaurant)',
  CUSTOMER_EXPERIENCE_RETAIL = 'Customer Experience (Retail)',
  BRAND_PERCEPTION = 'Brand Perception',
}

export enum TBMetricType {
  PANEL_SALES = 'snp-total_performance_panel_sales',
  THIRD_PARTY_DELIVERY_PANEL_SALES = 'snp-transaction_kpis_3rd_party_delivery_panel_sales',
  PENETRATION = 'snp-transaction_kpis_penetration',
  FREQUENCY = 'snp-transaction_kpis_frequency',
  AVERAGE_CHECK_SIZE = 'snp-transaction_kpis_average_check_size',
  VISIT_SHARE = 'snp-foot_traffic_kpis_visit_share',
  BRAND_DRAW = 'snp-foot_traffic_kpis_brand_draw',
  PHYSICAL_PANEL_SALES = 'snp-transaction_kpis_physical_panel_sales',
  DIGITAL_PANEL_SALES = 'snp-transaction_kpis_digital_panel_sales',
}

export enum TBPeriodValue {
  WEEK = 'Week',
  MONTH = 'Month',
  QUARTER = 'Quarter',
  MONTHS_3 = '3 Months',
  MONTHS_6 = '6 Months',
  MONTHS_12 = '12 Months',
  QUARTER_1 = '1',
  QUARTERS_2 = '2',
  QUARTERS_3 = '3',
  QUARTERS_4 = '4',
}

export enum TBCreateCardModalMode {
  CREATION = 'creation',
  EDITING = 'editing',
}
