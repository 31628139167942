import React from 'react'
import cn from 'classnames'

import * as palette from '~styles/palette.scss'

import Icon from '../../Icon'
import * as s from './style.module.scss'

export const MarkIcon = ({
  positive = false,
  withBg = true,
  fillColor = palette.green60,
  iconSize = 24,
  size = 40,
  className,
}) => (
  <div
    className={cn(s.icon, { [s.iconWithBg]: withBg }, className)}
    style={{
      color: fillColor,
      width: size,
      height: size,
    }}
  >
    {positive ? (
      <Icon name="arrow_up" size={iconSize} />
    ) : (
      <Icon name="arrow_down" size={iconSize} />
    )}
  </div>
)
