/* eslint-disable no-unsafe-optional-chaining */
import Highcharts from 'highcharts'
import { formatNum, displayPercent } from '~utils'
import * as palette from '~styles/palette.scss'

const LINE_PROTRUSION_DISTANCE = 18

function drawPlotLine(this: Highcharts.Chart, { startX, endX, y }: any) {
  this.renderer
    .path(['M', startX, y, 'L', endX, y])
    .attr({
      'stroke-width': 1,
      stroke: palette.gray30,
      zIndex: 2,
    })
    .add()
}

function drawAxisLines(
  this: Highcharts.Chart,
  { y, index, offset, nonNegative }: any
) {
  // calculate the x position for the plot line
  const plotLinePosStart = this?.series?.[0]?.points?.[index].shapeArgs
  const plotLinePosEnd = this?.series?.[1]?.points?.[index].shapeArgs

  // calculate the x positions for the start and end of the plot line
  const startX = plotLinePosStart?.x - LINE_PROTRUSION_DISTANCE
  const endX =
    plotLinePosEnd?.x + plotLinePosEnd?.width + LINE_PROTRUSION_DISTANCE

  drawPlotLine.call(this, { startX, endX, y })

  if (!nonNegative) {
    // draw bottom line
    const bottomLineY = this.plotTop + this.plotHeight + offset
    drawPlotLine.call(this, { startX, endX, y: bottomLineY })
  }
}

export const getAdditionalOptionsByType = ({
  type,
  categories,
  nonNegative, // to decrees offset and hide plot line under legend
}: ChartBarMultimetric.GetChartOptionsProps):
  | Highcharts.Options
  | undefined => {
  if (type !== 'mixed') {
    return undefined
  }

  const offset = nonNegative ? 8 : 32

  return {
    chart: {
      spacingLeft: 0,
      events: {
        load() {
          const yAxis = this.yAxis[0]
          const y = yAxis?.getPlotLinePath({ value: 0 })?.[0]?.at(-1)

          categories.forEach((_, index) => {
            drawAxisLines.call(this, { y, index, offset, nonNegative })
          })
        },
      },
    },

    yAxis: {
      gridLineWidth: 1,
      gridLineColor: palette.gray200,
      gridLineDashStyle: 'LongDash',

      plotLines: [
        {
          value: 0, // to hide the y=0 grid line
          width: 3,
          color: 'white',
          zIndex: 1,
        },
      ],

      labels: {
        enabled: false,
      },
    },
    xAxis: {
      lineWidth: 0,
      offset,
    },

    plotOptions: {
      series: {
        dataLabels: {
          formatter() {
            // @ts-ignore
            const { isNumber } = this.point
            const value = isNumber
              ? formatNum((this.y || 0) * 100, false, 2).full
              : displayPercent(this.y || 0, 2, false)
            return value
          },
        },
      },
    },
  }
}
