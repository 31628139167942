import React, { createContext, useContext } from 'react'
import { TBMode } from '~dashboard/TrackerBuilder/utils/enums'

const TrackerBuilderContext = createContext<TrackerBuilder>({
  isDataLoaded: false,
  mode: TBMode.VIEWING,
  setMode: () => {},
  dashboardSettings: null,
  setDashboardSettings: () => {},
  trackerBuilderBlocks: [],
  revertToSavedState: () => {},
  setTrackerBuilderBlocks: () => {},
  insertChartAt: () => {},
  updateChartAt: () => {},
  insertDividerAt: () => {},
  updateDividerAt: () => {},
  moveBlockUp: () => {},
  moveBlockDown: () => {},
  deleteBlockAt: () => {},
  duplicateChartAt: () => {},
  disableScrollIntoViewAt: () => {},
})

interface TrackerBuilderProviderProps extends React.PropsWithChildren {
  trackerBuilder: TrackerBuilder
}

export const TrackerBuilderProvider: React.FC<TrackerBuilderProviderProps> = ({
  children,
  trackerBuilder,
}) => {
  return (
    <TrackerBuilderContext.Provider value={trackerBuilder}>
      {children}
    </TrackerBuilderContext.Provider>
  )
}

export const useTrackerBuilderContext = () => {
  return useContext(TrackerBuilderContext)
}
