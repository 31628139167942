import React from 'react'
import cn from 'classnames'

import { RULES } from './constants'

import * as s from './style.module.scss'

export const PasswordValidation = ({ hasValue, errors }) => (
  <ul className={s.list}>
    {RULES.map(({ title, key }) => {
      const isActive = hasValue && !errors?.[key]
      return (
        <li key={key} className={cn(s.listItem, { [s.active]: isActive })}>
          {title}
        </li>
      )
    })}
  </ul>
)
