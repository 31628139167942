import React from 'react'
import cn from 'classnames'
import { isDataDifferencePositive } from '~utils'
import { getBadgeClass } from './utils'
import Icon from '../../Icon'

import * as s from './Badge.module.scss'

export const Badge = ({
  isIndex,
  data,
  text,
  icon,
  iconSize,
  benchmark,
  trend,
  isColorInverted,
  variant,
  className,
  children,
}) => {
  const zeroValues = data === 0 && benchmark === 0
  const dataClass =
    data != null &&
    getBadgeClass({ data, benchmark, isInverted: isColorInverted, zeroValues })

  return (
    <span
      className={cn('badge badge-pill', {
        [className]: className,
        [`badge-${variant}`]: variant,
        [dataClass]: dataClass,
      })}
    >
      {children}

      {icon && <Icon name={icon} size={iconSize || 16} />}

      {text || (
        <>
          {isIndex ? 'INDEX' : trend}
          <span className={cn({ [s.iconZero]: zeroValues })}>
            {isDataDifferencePositive(data, benchmark) ? (
              <Icon name={isIndex ? 'plus' : 'arrow_up'} size={16} />
            ) : (
              <Icon name={isIndex ? 'minus' : 'arrow_down'} size={16} />
            )}
          </span>
        </>
      )}
    </span>
  )
}
