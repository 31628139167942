import React from 'react'
import cn from 'classnames'

import * as s from './style.module.scss'

export const MetricValue = ({ value, children, className }) => (
  <div className={cn(s.metric, className)}>
    {children}
    <span>{value}</span>
  </div>
)
