// TODO: remove file after adding drivers chart types

import { QueryFunctionContext } from '@tanstack/react-query'
import { set } from 'lodash-es'
import api from '~api'
import { TBChartType } from '../enums'
import { HIDDEN_CHARTS } from './constants'

const filterHiddenCharts = (charts: TBOptionsResponse.Chart[]) =>
  charts?.reduce((chartsAcc: any, chart) => {
    const ommitedChartTypes: string[] | undefined = chart?.chart_types?.filter(
      (chartType: string) => !HIDDEN_CHARTS.includes(chartType as TBChartType)
    )

    if (!ommitedChartTypes?.length) return chartsAcc
    return [...chartsAcc, { ...chart, chart_types: ommitedChartTypes }]
  }, [])

const hideChartsFromTopics = (topics?: TBOptionsResponse.Topic[]) => {
  if (!topics?.length) return null

  const updatedTopics = topics.reduce((acc: any, topic) => {
    const updatedLayers = topic?.layers?.reduce(
      (
        layersAcc: TBOptionsResponse.Layer[] | [],
        layer: TBOptionsResponse.Layer
      ) => {
        const charts = filterHiddenCharts(layer?.charts)
        if (charts?.length) {
          return [...layersAcc, { ...layer, charts }]
        }
        return layersAcc
      },
      []
    )

    if (updatedLayers?.length) {
      return [...acc, { ...topic, layers: updatedLayers }]
    }

    return acc
  }, [])

  return updatedTopics
}

export const queryFn = ({ queryKey }: QueryFunctionContext) => {
  const [endpoint, keys] = queryKey

  // @ts-ignore
  return api.get(endpoint, { params: keys?.params }).then((res) => {
    if (res?.data?.error) {
      return Promise.reject(res?.data?.error)
    }
    if (!res?.data) return res?.data

    // TODO: hide drivers charts and empty topics/layers
    const topics = hideChartsFromTopics(res?.data?.options?.topics)
    return set(res?.data, 'options.topics', topics)
  })
}
