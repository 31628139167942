/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { chunk } from 'lodash-es'

import Tab from 'react-bootstrap/Tab'

import NavList from './components/NavList'
import { MAX_ONE_COLUMN_LENGTH } from './constants'

import * as s from './TabPane.module.scss'

const menuLists = ({ children, name, handleDisabledLink }) =>
  children &&
  (children.every(({ dashboard }) => dashboard) ? (
    // Nested child is a column that has 1 list
    <NavList
      key={name}
      list={children}
      header={name}
      handleDisabledLink={handleDisabledLink}
    />
  ) : (
    // Nested child is an array of groups
    children.map((group) =>
      // Recursive call
      menuLists({
        children: group.children,
        name: group.name,
        handleDisabledLink,
      })
    )
  ))

const TabContent = ({
  tabPaneList,
  tabPaneChildren,
  noGroupNameDashboards,
  handleDisabledLink,
  isCustomShop,
}) => {
  switch (true) {
    case isCustomShop && tabPaneList?.length > MAX_ONE_COLUMN_LENGTH: {
      const groupList = chunk(tabPaneList, Math.ceil(tabPaneList.length / 2))

      return groupList?.map((group) => (
        <NavList
          key={group?.[0]?.id}
          list={group}
          handleDisabledLink={handleDisabledLink}
        />
      ))
    }

    case !!tabPaneList:
      return (
        // TabPane has 1 list - an array of NavLinks
        <NavList list={tabPaneList} handleDisabledLink={handleDisabledLink} />
      )

    case !!tabPaneChildren:
      return tabPaneChildren.every(({ dashboard }) => dashboard) ? (
        // TabPane `children` is 1 list - an array of NavLinks
        // Never the case?!
        <NavList list={tabPaneChildren} />
      ) : (
        // TabPane children are columns and have nested children
        <>
          {tabPaneChildren.map((column) => (
            <div key={column.name}>
              <p className={s.tabPaneHeader}>{column.name}</p>
              {menuLists({
                children: column.children,
                handleDisabledLink,
              })}
            </div>
          ))}
          {noGroupNameDashboards && (
            <div
              className={cn({
                [s.tabPaneNoNameGroup]: tabPaneChildren.length >= 3,
              })}
            >
              <NavList list={noGroupNameDashboards} />
            </div>
          )}
        </>
      )

    default:
      return null
  }
}

const TabPane = (props) => {
  const {
    className,
    name: tabPaneName,
    list: tabPaneList,
    children: tabPaneChildren,
    noGroupNameDashboards,
    handleDisabledLink,
    isCustomShop,
    isIntelligence,
    ...rest
  } = props

  return (
    <Tab.Pane
      {...rest}
      key={tabPaneName}
      eventKey={tabPaneName}
      className={cn(s.tabPane, { [s.intelligence]: isIntelligence })}
    >
      <TabContent
        {...{
          tabPaneList,
          tabPaneChildren,
          noGroupNameDashboards,
          handleDisabledLink,
          isCustomShop,
        }}
      />
    </Tab.Pane>
  )
}

TabPane.defaultProps = {
  className: undefined,
}

TabPane.propTypes = {
  className: PropTypes.string,
}

export default TabPane
