import { LABELS } from '~constants'

const { COMPETITIVE_SET } = LABELS

export const getNotAvailableCompetitors = ({
  benchmark,
  competitors,
  brands,
}) => {
  if (benchmark !== COMPETITIVE_SET) return null

  const notAvailaleCompetitors = competitors?.filter(
    (competitor) => !brands?.includes(competitor)
  )

  if (!notAvailaleCompetitors?.length) return null

  return notAvailaleCompetitors
}
