import React, { memo, useRef } from 'react'
import { FixedSizeList as List, areEqual } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import { BrandItem } from './BrandItem'
import { GradientBoundaries } from '../../UI'

const Row = ({ index, style, data: { brands, onAddBrand } }) => (
  <BrandItem name={brands[index] ?? ''} onAddBrand={onAddBrand} style={style} />
)

const MemoizedBrandItem = memo(Row, areEqual)

export const AddBrandsList = ({ brands = [], onAddBrand }) => {
  const listRef = useRef()

  return (
    <GradientBoundaries scrollContainerRef={listRef}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            outerRef={listRef}
            className="v-list"
            height={height}
            itemData={{ brands, onAddBrand }}
            itemCount={brands.length}
            itemSize={48}
            width={width}
            innerElementType="ul"
          >
            {MemoizedBrandItem}
          </List>
        )}
      </AutoSizer>
    </GradientBoundaries>
  )
}
