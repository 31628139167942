import React from 'react'
import cn from 'classnames'

import { LABELS } from '~constants'
import Icon from '../../Icon'
import { EditButton } from '../EditButton'
import * as s from './Option.module.scss'

export const Option = ({
  innerProps,
  isDisabled,
  isSelected,
  children,
  selectProps,
}) =>
  !isDisabled && (
    <div
      {...innerProps}
      className={cn(s.option, {
        [s[selectProps.variant]]: selectProps.variant,
      })}
    >
      {children}
      <div className={s.optionRight}>
        {children === LABELS.COMPETITIVE_SET && (
          <EditButton
            withText={!selectProps?.isShort}
            handleClick={selectProps?.handleEditClick}
          />
        )}
        {isSelected && (
          <span className={s.optionCheck}>
            <Icon name="check_small" size={24} />
          </span>
        )}
      </div>
    </div>
  )
