import { round, displayPercent, getPPLabel } from '~utils'

export const getBrandString = (value: any, isNumber: boolean) => {
  if (value == null) return '—'
  return isNumber ? round(value, 2) : displayPercent(value, 1)
}

export const getTooltipTableHeaders = ({
  dateTitle,
  withSample,
}: {
  dateTitle: string
  withSample?: boolean
}): { title: string; colSpan?: string }[] =>
  withSample
    ? [
        { title: dateTitle, colSpan: '2' },
        { title: 'PoP' },
        { title: 'Sample' },
      ]
    : [{ title: dateTitle, colSpan: '2' }, { title: 'PoP' }]

export const getTooltipData = ({
  title,
  brand,
  trend,
  sample,
  color,
  trendColor,
  isNumber,
  withSample,
}: any) => {
  const values = [
    {
      value: getBrandString(brand, isNumber),
      color,
      isCenter: true,
    },
    {
      value: trend != null ? getPPLabel(trend, isNumber) : '—',
      color: trendColor ?? color,
      isCenter: true,
    },
  ]

  if (withSample) {
    values.push({ value: sample ?? '—', color, isCenter: true })
  }

  return { title, values }
}
