export const formatSingleTableData = (data: any) => {
  if (!data.data?.length) return { chartData: undefined }

  const cols = data.data.map((row: any) => row.date)

  const dataRows = data.cols.map((brand: string, brandIndex: number) => {
    const brandValues = data.data.map((row: any) => row?.values[brandIndex])
    return [brand, ...brandValues]
  })

  return { chartData: { cols, data: dataRows } }
}
