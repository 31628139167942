import React from 'react'
import PropTypes from 'prop-types'
import { Placeholder, Tab } from 'react-bootstrap'
import cn from 'classnames'

import { IconButton } from '~globalComponents/UI'
import { INTELLIGENCE, REPORT_BUILDER } from '../../constants'
import HamburgerButton from './components/HamburgerButton'
import TabNav from './components/TabNav'
import TabPane from './components/TabPane'

import * as s from './HeaderBottom.module.scss'

const HeaderBottom = (props) => {
  const {
    className,
    isLoading,
    isMenuOpened,
    hamb,
    handleMenu,
    title,
    page,
    node,
    activeKey,
    setActiveKey,
    menuItems,
    handleCreateDashboard,
    handleDisabledLink,
    ...rest
  } = props

  const isIntelligence = activeKey === INTELLIGENCE

  return (
    <div {...rest} className={cn(className, s.headerBottom)}>
      <div
        className={cn('container', s.headerBottomContainer, {
          'placeholder-glow': isLoading,
        })}
      >
        <HamburgerButton
          isMenuOpened={isMenuOpened}
          hamb={hamb}
          handleMenu={handleMenu}
        />
        {!isLoading ? (
          <button
            type="button"
            className={s.headerBottomTitle}
            ref={title}
            onClick={handleMenu}
          >
            {page}
          </button>
        ) : (
          <Placeholder
            style={{ height: '24px', width: '200px', marginLeft: '13px' }}
          />
        )}
      </div>
      <nav
        aria-labelledby="menu"
        className={cn(s.headerBottomDropdown, {
          [s.show]: isMenuOpened,
        })}
        ref={node}
      >
        <div className={cn('container', s.headerBottomDropdownContainer)}>
          <Tab.Container
            id="left-tabs-example"
            activeKey={activeKey}
            onSelect={(key) => setActiveKey(key)}
          >
            <TabNav menuItems={menuItems} />
            <Tab.Content
              className={cn(s.headerBottomDropdownContent, {
                [s.intelligence]: isIntelligence,
              })}
            >
              {menuItems.map(
                ({
                  name,
                  list,
                  children,
                  noGroupNameDashboards,
                  isCustomShop,
                }) => (
                  <TabPane
                    key={name}
                    name={name}
                    list={list}
                    noGroupNameDashboards={noGroupNameDashboards}
                    handleDisabledLink={handleDisabledLink}
                    isCustomShop={isCustomShop}
                    isIntelligence={isIntelligence}
                  >
                    {children}
                  </TabPane>
                )
              )}
            </Tab.Content>
            {activeKey === REPORT_BUILDER && (
              <div className={s.buttonNewDashboard}>
                <IconButton
                  onClick={handleCreateDashboard}
                  iconHref="plusCircle"
                  variant="green60"
                >
                  Create Custom Dashboard
                </IconButton>
              </div>
            )}
          </Tab.Container>
        </div>
      </nav>
    </div>
  )
}

HeaderBottom.defaultProps = {
  className: undefined,
}

HeaderBottom.propTypes = {
  className: PropTypes.string,
}

export default HeaderBottom
