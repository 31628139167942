import React from 'react'
import cn from 'classnames'
import { LoadingPlaceholder, GradientBoundaries } from '../../UI'
import { CompetitorItem } from './CompetitorItem'
import { useDeleteCompetitor } from '../hooks'

export const InnerContent = ({
  competitors,
  loaded,
  hasLimitedHeight,
  handleDeleteCompatitor,
}) => {
  const { mutate: onDeleteBrand, isLoading } = useDeleteCompetitor()

  switch (true) {
    case loaded && competitors.length === 0:
      return <p>You have not added any brands to the competitive set</p>

    case hasLimitedHeight && competitors.length > 6:
      return (
        <div className="cs__list__wrap">
          <LoadingPlaceholder loading={isLoading} />
          <GradientBoundaries>
            <ul className="cs__list cs__list--limited">
              {loaded &&
                competitors.map((c) => (
                  <CompetitorItem
                    key={c}
                    name={c}
                    onDeleteBrand={handleDeleteCompatitor ?? onDeleteBrand}
                  />
                ))}
            </ul>
          </GradientBoundaries>
        </div>
      )

    default:
      return (
        <ul
          className={cn('cs__list cs__list__wrap', {
            'cs__list--limited': hasLimitedHeight,
          })}
        >
          <LoadingPlaceholder loading={isLoading} />
          {loaded &&
            competitors.map((c) => (
              <CompetitorItem
                key={c}
                name={c}
                onDeleteBrand={handleDeleteCompatitor ?? onDeleteBrand}
              />
            ))}
        </ul>
      )
  }
}
