import React, { FC } from 'react'

import { useAccount, useLoadUserCompetitors } from '~hooks'
import Tooltip from '~globalComponents/Tooltip'

import * as s from './CompetitiveSetTooltip.module.scss'

type CompetitiveSetTooltipProps = {
  competitors?: string[]
  selectProps?: any
  placement?: string
}

const CompetitiveSetTooltip: FC<CompetitiveSetTooltipProps> = ({
  competitors,
  selectProps,
  placement = 'topRight',
}) => {
  const { data: accountInfo } = useAccount()
  const { data: competitorsResponse } = useLoadUserCompetitors(
    accountInfo?.brand
  )

  const competitorsList =
    competitors ?? selectProps?.competitors ?? competitorsResponse?.competitors

  return (
    <Tooltip
      content={
        <>
          Competitive set:
          <ul className={s.list}>
            {competitorsList?.map((competitor: string) => (
              <li key={competitor}>{competitor}</li>
            ))}
          </ul>
        </>
      }
      size="maxContent"
      icon="help_filled"
      withIcon
      variant="competitiveSet"
      placement={placement}
    />
  )
}

export default CompetitiveSetTooltip
