import { useState } from 'react'

export const useTableSort = (defaultState) => {
  const [tableSort, setTableSort] = useState(defaultState)

  const handleTableSort = (key) => () =>
    setTableSort((prev) => {
      const isSameElement = prev.sortKey === key
      const isResetMode = isSameElement && !prev.ascMode
      return {
        sortKey: isResetMode ? null : key,
        ascMode: !isSameElement,
      }
    })

  return [tableSort, handleTableSort]
}
