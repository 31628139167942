import React from 'react'
import ReactDOMServer from 'react-dom/server'
import cn from 'classnames'

import '~globalComponents/Card/charts.scss'
import * as s from './ChartTooltipContent.module.scss'

interface TitleProps {
  title: any
  isCenter: any
  color: any
  hasTableHeading?: boolean
}

interface ChartTooltipContentProps extends React.PropsWithChildren {
  title: TitleProps
  data: any
  isBordered: any
}

/* Content for charts tooltip  */
const ChartTooltipContent: React.FC<ChartTooltipContentProps> = ({
  title: { title, isCenter, color: titleColor, hasTableHeading },
  data,
  isBordered,
  children,
}) => {
  return (
    <>
      {!hasTableHeading && !!title && (
        <div
          className={cn(s.title, s.titleBlock, { [s.center]: isCenter })}
          style={{ color: titleColor }}
        >
          {Array.isArray(title) ? (
            title.map((item) => <p key={item}>{item}</p>)
          ) : (
            <p className={s.titleText}>{title}</p>
          )}
        </div>
      )}
      <table className={cn(s.content, { [s.divider]: isBordered })}>
        {hasTableHeading && (
          <thead>
            <tr>
              {/* @ts-ignore */}
              {title.map(({ title: thTitle, colSpan = 1 }) => (
                <th
                  key={thTitle}
                  colSpan={colSpan}
                  className={cn(s.title, s.tableHead)}
                  style={{ color: titleColor }}
                >
                  {thTitle}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {/* @ts-ignore */}
          {data?.map(({ title: itemTitle, values }) => (
            <tr key={itemTitle}>
              <td>{itemTitle || ''}</td>
              {values.map(
                // @ts-ignore
                ({ value, color, isThin, isCenter: isCellCenter }, index) => (
                  <td
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${value}-${index}`}
                    className={cn(s.value, {
                      [s.thin]: isThin,
                      [s.center]: isCellCenter,
                    })}
                    style={{ color }}
                  >
                    {value}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {children}
    </>
  )
}

/* Render as string for HighChart  */
const renderChartTooltipToString = (
  headings: any,
  data: any,
  isBordered = false
) =>
  ReactDOMServer.renderToString(
    <ChartTooltipContent title={headings} data={data} isBordered={isBordered} />
  )

export { ChartTooltipContent, renderChartTooltipToString }
