import React, { useState, useEffect, useRef, useMemo, memo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'
import qs from 'query-string'
import PropTypes from 'prop-types'

import { ROUTES } from '~routes'
import {
  useDashboards,
  useAccount,
  useClickOutside,
  useLoadUserCompetitors,
} from '~hooks'
import {
  searchRecursive,
  searchMenuByDashboard,
  getRedirectMenuItem,
} from '~utils/menu'

import ModalCreateDashboard from '~globalComponents/ModalCreateDashboard'
import ModalDelete from '~globalComponents/ModalDelete'
import ModalNotify from '~globalComponents/ModalNotify'
import {
  TBMode,
  useTrackerBuilderContext,
  saveTrackerBuilderToLS,
} from '~dashboard/TrackerBuilder/utils'
import {
  handleDashboardData,
  isDashboadAvailable,
  isIntelligenceDashboard,
  getMenuGroupList,
  getNotifyMessage,
} from './utils'
import { HOME, MENU, MODAL_SETTINGS, REPORT_BUILDER } from './constants'
import HeaderTop from './components/HeaderTop'
import HeaderBottom from './components/HeaderBottom'
import DisabledLinkModal from './components/DisabledLinkModal'

import * as s from './Header.module.scss'

const usePageViews = (data, page) => {
  const location = useLocation()

  useEffect(() => {
    if (data?.account_id && process.env.REACT_APP_ENV === 'prod') {
      const { account_id, parent, email, type } = data

      ReactGA.initialize([
        {
          trackingId: process.env.REACT_APP_GA4_MEASUREMENT_ID,
          gaOptions: { userId: account_id },
        },
      ])

      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
        title: page,
        user_properties: {
          email,
          client: parent,
          category: type,
        },
      })
    }
  }, [data, location, page])
}

const Header = ({ isLoading, page, omittedParams, mergeParams }) => {
  const { data: accountInfo, isFetching: isFetchingAccountInfo } = useAccount()
  const navigate = useNavigate()
  const { search: searchParams, pathname } = useLocation()
  const { id, no_animation } = qs.parse(searchParams)

  usePageViews(accountInfo, page)

  const [menuItems, setMenuItems] = useState(MENU)
  const [activeKey, setActiveKey] = useState(null)
  const [isHomeAvailable, setIsHomeAvailable] = useState(null) // to wrap logo into link or span
  const [isDisabledLinkModalOpen, setIsDisabledLinkModalOpen] = useState(null)
  const [isCreatingModalOpen, setIsCreatingModalOpen] = useState(null)
  const [createDashboardData, setCreateDashboardData] = useState(null)
  const [isDeleteChangesOpen, setIsDeleteChangesOpen] = useState(false)
  const [isNotifyChangesOpen, setIsNotifyChangesOpen] = useState(false) // to show that page is not available and user will be redirected to first available page

  const [draftDashboard, setDraftDashboard] = useState(null)

  const {
    mode,
    dashboardSettings,
    setMode,
    setDashboardSettings,
    setTrackerBuilderBlocks,
  } = useTrackerBuilderContext()

  const { data: userCompetitors } = useLoadUserCompetitors(accountInfo?.brand)

  const handleDashboard = (isAvailable, key) => {
    if (isAvailable) {
      setActiveKey(key)
    } else {
      setIsNotifyChangesOpen(true)
    }
  }

  const {
    data: {
      modules,
      children,
      features,
      custom_dashboards: customDashboards,
    } = {},
  } = useDashboards(accountInfo?.brand, () => {
    const menuData = handleDashboardData({
      modules,
      children,
      features,
      customDashboards,
    })
    setMenuItems(menuData) // set menu list

    const hasHomeModule = !!modules?.find(({ name }) => name === HOME)?.active
    setIsHomeAvailable(hasHomeModule)

    // if the dashboard is available - set it as active menu item
    // if the "Home" page isn't available - redirect the user to the first available page
    // if the user is on an unavailable dashboard except "Home" show the modal that he will be redirected to the first available page
    switch (true) {
      // for Home page
      case pathname === ROUTES.HOME: {
        if (!hasHomeModule) {
          const redirectPath = getRedirectMenuItem(modules, children)?.link
          navigate(redirectPath)
        }

        setActiveKey(menuData[0].name)
        break
      }

      // for Children Dashboard
      case pathname.includes(ROUTES.GENERIC_DASHBOARD): {
        const searchedDashboard = searchMenuByDashboard(menuData, id)
        const isAvailable = searchRecursive(children, id)
        handleDashboard(isAvailable, searchedDashboard?.name)
        break
      }

      //  for Custom Dashboards
      case pathname.includes(`${ROUTES.TRACKER_BUILDER}/`): {
        const reportBuilderList = getMenuGroupList(REPORT_BUILDER, menuData)
        const isAvailable = isDashboadAvailable({
          pathname,
          list: reportBuilderList,
        })
        handleDashboard(isAvailable, REPORT_BUILDER)
        break
      }

      //  for Intelligence Dashboards
      case isIntelligenceDashboard(page): {
        const intelligenceList = getMenuGroupList(MENU[0].name, menuData)
        const isAvailable = isDashboadAvailable({
          name: page,
          list: intelligenceList,
        })
        handleDashboard(isAvailable, menuItems[0].name)
        break
      }

      default:
        setActiveKey(menuData[0].name)
        break
    }
  })

  const node = useRef()
  const hamb = useRef()
  const title = useRef()

  const [isMenuOpen, setIsMenuOpen] = useState(null)

  const handleMenu = () => setIsMenuOpen((prevState) => !prevState)

  // menu closing
  useClickOutside(
    [node, hamb, title],
    () => {
      setIsMenuOpen(false)
    },
    isDisabledLinkModalOpen || isCreatingModalOpen || isDeleteChangesOpen
  )
  useEffect(() => {
    if (searchParams) setIsMenuOpen(false)
  }, [searchParams])

  useEffect(() => {
    setIsMenuOpen(false)
  }, [pathname])

  const resetTrackerBuilderData = (dashboardData) => {
    setMode(TBMode.EDITING)
    setTrackerBuilderBlocks([])
    setDashboardSettings(dashboardData)
    saveTrackerBuilderToLS({
      mode: TBMode.EDITING,
      trackerBuilderBlocks: [],
      dashboardSettings: dashboardData,
    })
  }

  const handleSubmitForm = (formData) => {
    if (mode === TBMode.EDITING) {
      const isDraftDashboard = pathname === ROUTES.TRACKER_BUILDER
      setDraftDashboard(isDraftDashboard && dashboardSettings?.dashboardName)
      setCreateDashboardData(formData)
      setIsCreatingModalOpen(false)
      setIsDeleteChangesOpen(true)
    } else {
      resetTrackerBuilderData(formData)
      setIsMenuOpen(false)
      setIsCreatingModalOpen(false)
      navigate(ROUTES.TRACKER_BUILDER)
    }
  }

  const handleDeleteModal = () => {
    resetTrackerBuilderData(createDashboardData)
    setIsMenuOpen(false)
    setIsDeleteChangesOpen(false)
    navigate(ROUTES.TRACKER_BUILDER, {
      state: { formData: createDashboardData },
    })
  }

  const handleDisabledLink = () => setIsDisabledLinkModalOpen(true)
  const handleDisableLinkModalClose = () => setIsDisabledLinkModalOpen(false)
  const handleCreateDashboard = () => setIsCreatingModalOpen(true)

  const handleCloseModalNotify = () => {
    setIsNotifyChangesOpen(false)

    const redirectPath = isHomeAvailable
      ? ROUTES.HOME
      : getRedirectMenuItem(modules, children)?.link
    navigate(redirectPath)
    setActiveKey(menuItems[0].name)
  }

  // used useMemo to avoid unnesessary rerenders in ModalCreateDashboard
  const formData = useMemo(() => {
    const defaultBrand = accountInfo?.default_brand

    return defaultBrand
      ? { brand: { label: defaultBrand, value: defaultBrand } }
      : null
  }, [accountInfo])

  return (
    <>
      <header className={s.header}>
        <HeaderTop
          isHomeAvailable={isHomeAvailable}
          showPlaceholder={isFetchingAccountInfo}
          accountInfo={accountInfo}
          omittedParams={omittedParams}
          mergeParams={mergeParams}
        />
        <HeaderBottom
          isLoading={isLoading}
          isMenuOpened={isMenuOpen}
          hamb={hamb}
          handleMenu={handleMenu}
          title={title}
          page={page}
          node={node}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          menuItems={menuItems}
          handleCreateDashboard={handleCreateDashboard}
          handleDisabledLink={handleDisabledLink}
        />
        <DisabledLinkModal
          isOpen={isDisabledLinkModalOpen}
          handleClose={handleDisableLinkModalClose}
        />

        <ModalCreateDashboard
          {...MODAL_SETTINGS}
          show={isCreatingModalOpen}
          handleClose={setIsCreatingModalOpen}
          formData={formData}
          data={accountInfo}
          initCompetitors={userCompetitors?.competitors}
          submitForm={handleSubmitForm}
          isCreateModal
        />

        <ModalDelete
          show={isDeleteChangesOpen}
          handleClose={setIsDeleteChangesOpen}
          handleDelete={handleDeleteModal}
          deleteBtnText="Proceed"
        >
          <h2>
            Create a new dashboard? <br />
            Unsaved changes will be lost
          </h2>
          <p>
            {!!draftDashboard && (
              <>
                <s>&quot;{draftDashboard}&quot;</s>
                <br />
              </>
            )}
            &quot;{createDashboardData?.dashboardName}&quot;
          </p>
        </ModalDelete>
      </header>

      <ModalNotify
        show={!no_animation && isNotifyChangesOpen}
        handleClose={handleCloseModalNotify}
        notifyMessage={getNotifyMessage({
          currentPage: page,
          brand: accountInfo?.brand,
          isHomeAvailable,
          modules,
          children,
        })}
      />
    </>
  )
}

Header.propTypes = {
  page: PropTypes.any,
  isLoading: PropTypes.bool,
  omittedParams: PropTypes.any,
  mergeParams: PropTypes.any,
}

export default memo(Header)
