import { useEffect, useReducer, useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useParams } from 'react-router-dom'

import { arrayMoveImmutable } from '~utils'

import { TBBlockType, TBMode } from './enums'
import {
  getTrackerBuilderFromLS,
  removeTrackerBuilderFromLS,
  saveTrackerBuilderToLS,
} from './utils'

const createChartBlock = (chartOptions: TBChartOptions): TBChartBlock => ({
  id: uuid(),
  blockType: TBBlockType.CHART,
  chartOptions,
})

const createDividerBlock = (
  dividerOptions: TBDividerOptions
): TBDividerBlock => ({
  id: uuid(),
  blockType: TBBlockType.DIVIDER,
  dividerOptions,
})

const duplicateChart = (block: TBChartBlock): TBChartBlock => ({
  ...block,
  id: uuid(),
  scrollIntoView: true,
})

const enableScrollIntoView = (block: TBBlock): TBBlock => ({
  ...block,
  scrollIntoView: true,
})

const disableScrollIntoView = (block: TBBlock): TBBlock => ({
  ...block,
  scrollIntoView: false,
})

const updateDivider = (
  dividerBlock: TBDividerBlock,
  newDividerOptions: TBDividerOptions
): TBDividerBlock => ({
  ...dividerBlock,
  dividerOptions: newDividerOptions,
})

const updateChart = (
  chartBlock: TBChartBlock,
  newChartOptions: TBChartOptions
) => ({ ...chartBlock, chartOptions: newChartOptions })

enum TBAction {
  SET_TRACKER_BUILDER_BLOCKS = 'setTrackerBuilderBlocks',
  INSERT_CHART_AT = 'insertChartAt',
  INSERT_DIVIDER_AT = 'insertDividerAt',
  UPDATE_CHART_AT = 'updateChartAt',
  UPDATE_DIVIDER_AT = 'updateDividerAt',
  MOVE_BLOCK_TO = 'moveBlockTo',
  DELETE_BLOCK_AT = 'deleteBlockAt',
  DUPLICATE_CHART_AT = 'duplicateChartAt',
  DISABLE_SCROLL_INTO_VIEW_AT = 'disableScrollIntoViewAt',
}

type ReducerAction =
  | {
      type: TBAction.SET_TRACKER_BUILDER_BLOCKS
      blocks: TBBlock[]
    }
  | {
      type: TBAction.INSERT_CHART_AT
      chartOptions: TBChartOptions
      pos: number
    }
  | {
      type: TBAction.INSERT_DIVIDER_AT
      dividerOptions: TBDividerOptions
      pos: number
    }
  | {
      type: TBAction.UPDATE_CHART_AT
      chartOptions: TBChartOptions
      index: number
    }
  | {
      type: TBAction.UPDATE_DIVIDER_AT
      dividerOptions: TBDividerOptions
      index: number
    }
  | {
      type: TBAction.MOVE_BLOCK_TO
      index: number
      pos: number
      scrollIntoView?: boolean
    }
  | {
      type: TBAction.DELETE_BLOCK_AT
      index: number
      shouldDeleteUnavailable?: boolean
    }
  | {
      type: TBAction.DUPLICATE_CHART_AT
      index: number
    }
  | {
      type: TBAction.DISABLE_SCROLL_INTO_VIEW_AT
      index: number
    }

const reducer = (state: TBBlock[], action: ReducerAction): TBBlock[] => {
  switch (action.type) {
    // Set tracker builder state as a whole
    case TBAction.SET_TRACKER_BUILDER_BLOCKS:
      return action.blocks

    // Insert CHART at a specific position
    case TBAction.INSERT_CHART_AT:
      return [
        ...state.slice(0, action.pos),
        createChartBlock(action.chartOptions),
        ...state.slice(action.pos),
      ]

    // Insert DIVIDER at a specific position
    case TBAction.INSERT_DIVIDER_AT:
      return [
        ...state.slice(0, action.pos),
        createDividerBlock(action.dividerOptions),
        ...state.slice(action.pos),
      ]

    // Update CHART at a specific position
    case TBAction.UPDATE_CHART_AT: {
      const newState = [...state]
      const oldChart = newState[action.index]

      if (!oldChart.chartOptions) return state

      newState[action.index] = updateChart(oldChart, action.chartOptions)

      return newState
    }

    // Update DIVIDER at a specific position
    case TBAction.UPDATE_DIVIDER_AT: {
      const newState = [...state]
      const oldDivider = newState[action.index]

      if (!oldDivider.dividerOptions) return state

      newState[action.index] = updateDivider(oldDivider, action.dividerOptions)

      return newState
    }

    // Mover block (by index) to a specific position
    case TBAction.MOVE_BLOCK_TO: {
      const newArray = arrayMoveImmutable(state, action.index, action.pos)
      if (action.scrollIntoView)
        newArray[action.pos] = enableScrollIntoView(newArray[action.pos])
      return newArray
    }

    // Delete block (by index)
    case TBAction.DELETE_BLOCK_AT:
      return state.filter((item, index) =>
        action.shouldDeleteUnavailable
          ? !item?.chartOptions?.unavailable
          : index !== action.index
      )

    // Duplicate chart (by index)
    case TBAction.DUPLICATE_CHART_AT: {
      const block = state[action.index]

      if (block.blockType !== TBBlockType.CHART) return state

      return [
        ...state.slice(0, action.index + 1),
        duplicateChart(block),
        ...state.slice(action.index + 1),
      ]
    }

    // Set scrollInViewOnMount to false (by index)
    case TBAction.DISABLE_SCROLL_INTO_VIEW_AT: {
      const newState = [...state]
      const oldBlock = newState[action.index]

      newState[action.index] = disableScrollIntoView(oldBlock)

      return newState
    }

    default:
      throw new Error()
  }
}

export const useTrackerBuilder = (): TrackerBuilder => {
  const { id: dashboardId } = useParams()

  const [trackerBuilderBlocks, dispatch] = useReducer(
    reducer,
    getTrackerBuilderFromLS(dashboardId)?.trackerBuilderBlocks || []
  )
  const [mode, setMode] = useState(
    getTrackerBuilderFromLS(dashboardId)?.mode || TBMode.VIEWING
  )
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [dashboardSettings, setDashboardSettings] =
    useState<TBDashboardSettings | null>(
      getTrackerBuilderFromLS(dashboardId)?.dashboardSettings || null
    )

  const setTrackerBuilderBlocks: TrackerBuilder['setTrackerBuilderBlocks'] = (
    blocks: TBBlock[]
  ) => {
    dispatch({
      type: TBAction.SET_TRACKER_BUILDER_BLOCKS,
      blocks,
    })

    setIsDataLoaded(true)
  }

  // Retrieve TB blocks from local storage if editing mode was saved to it
  useEffect(() => {
    const savedState = getTrackerBuilderFromLS(dashboardId)

    if (savedState?.mode === TBMode.EDITING) {
      setMode(TBMode.EDITING)
      setTrackerBuilderBlocks(savedState?.trackerBuilderBlocks)
      setDashboardSettings(savedState?.dashboardSettings)
    } else {
      // If nothing found in LS, set mode to Viewing
      setMode(TBMode.VIEWING)
    }
  }, [dashboardId])

  // Save TB blocks to LS if TB is in Editing mode
  // Remove TB blocks from LS if TB is in Viewing mode
  useEffect(() => {
    if (mode === TBMode.EDITING) {
      saveTrackerBuilderToLS(
        {
          mode,
          trackerBuilderBlocks,
          dashboardSettings,
        },
        dashboardId
      )
    } else {
      removeTrackerBuilderFromLS(dashboardId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, trackerBuilderBlocks, dashboardSettings])

  const savedTrackerBuilderState = useRef<TBBlock[]>([])

  const revertToSavedState: TrackerBuilder['revertToSavedState'] = () =>
    dispatch({
      type: TBAction.SET_TRACKER_BUILDER_BLOCKS,
      blocks: savedTrackerBuilderState.current,
    })

  const insertChartAt: TrackerBuilder['insertChartAt'] = (chartOptions, pos) =>
    dispatch({
      type: TBAction.INSERT_CHART_AT,
      pos,
      chartOptions,
    })

  const insertDividerAt: TrackerBuilder['insertDividerAt'] = (
    dividerOptions,
    pos
  ) =>
    dispatch({
      type: TBAction.INSERT_DIVIDER_AT,
      pos,
      dividerOptions,
    })

  const updateDividerAt: TrackerBuilder['updateDividerAt'] = (
    dividerOptions,
    index
  ) =>
    dispatch({
      type: TBAction.UPDATE_DIVIDER_AT,
      dividerOptions,
      index,
    })

  const updateChartAt: TrackerBuilder['updateChartAt'] = (
    chartOptions,
    index
  ) =>
    dispatch({
      type: TBAction.UPDATE_CHART_AT,
      chartOptions,
      index,
    })

  const moveBlockUp: TrackerBuilder['moveBlockUp'] = (index) =>
    dispatch({
      type: TBAction.MOVE_BLOCK_TO,
      index,
      pos: index - 1,
      scrollIntoView: true,
    })

  const moveBlockDown: TrackerBuilder['moveBlockDown'] = (index: number) =>
    dispatch({
      type: TBAction.MOVE_BLOCK_TO,
      index,
      pos: index + 1,
      scrollIntoView: true,
    })

  const deleteBlockAt: TrackerBuilder['deleteBlockAt'] = (
    index,
    shouldDeleteUnavailable
  ) => {
    savedTrackerBuilderState.current = trackerBuilderBlocks

    dispatch({
      type: TBAction.DELETE_BLOCK_AT,
      index,
      shouldDeleteUnavailable,
    })
  }

  const duplicateChartAt: TrackerBuilder['duplicateChartAt'] = (index) =>
    dispatch({
      type: TBAction.DUPLICATE_CHART_AT,
      index,
    })

  const disableScrollIntoViewAt: TrackerBuilder['disableScrollIntoViewAt'] = (
    index
  ) => dispatch({ type: TBAction.DISABLE_SCROLL_INTO_VIEW_AT, index })

  return {
    isDataLoaded,
    mode,
    setMode,
    dashboardSettings,
    setDashboardSettings,
    trackerBuilderBlocks,
    revertToSavedState,
    setTrackerBuilderBlocks,
    insertChartAt,
    updateChartAt,
    insertDividerAt,
    updateDividerAt,
    moveBlockUp,
    moveBlockDown,
    deleteBlockAt,
    duplicateChartAt,
    disableScrollIntoViewAt,
  }
}
