import {
  UPPERCASE_RULE,
  SPECIAL_CHARACTER_RULE,
  LOWERCASE_RULE,
  NUMBER_RULE,
} from '~regExp'

export const RULES = [
  { title: '8 characters minimum', key: 'MIN_LENGTH', rule: 8 },
  { title: 'One uppercase character', key: 'UPPER_CASE', rule: UPPERCASE_RULE },
  {
    title: 'One special character',
    key: 'SPECIAL_CHARACTER',
    rule: SPECIAL_CHARACTER_RULE,
  },
  { title: 'One lowercase character', key: 'LOWER_CASE', rule: LOWERCASE_RULE },
  { title: 'One number', key: 'NUMBER', rule: NUMBER_RULE },
]
