import Highcharts from 'highcharts'

import { formatNum, displayPercent } from '~utils'
import { MIDDLE_DASH } from '~constants'
import * as palette from '~styles/palette.scss'
import { renderChartTooltipToString } from '~globalComponents/ChartTooltipContent'

export const formatDataValue = ({
  value,
  isNumber,
  isNotPercentValue,
  multiplier,
}: {
  value?: number | null
  isNumber?: boolean
  isNotPercentValue: boolean
  multiplier: number
}) => {
  if (value == null) return MIDDLE_DASH

  return isNumber || isNotPercentValue
    ? formatNum(value * multiplier, false, 2).full
    : displayPercent(value, 2, false)
}

export const simpleTooltipFormatter = ({
  isNotPercentValue,
}: any): Highcharts.TooltipFormatterCallbackFunction =>
  function () {
    const expectedNumberTitle = this.points?.[0]?.series?.name
    const segmentTitle = this.points?.[1]?.series?.name

    // @ts-ignore
    const isNumber = this.points?.[0]?.point?.isNumber

    const multiplier = isNumber || !isNotPercentValue ? 100 : 1

    const expectedNumber = formatDataValue({
      value: this.points?.[0].y,
      isNumber,
      isNotPercentValue,
      multiplier,
    })

    const segment = formatDataValue({
      value: this.points?.[1].y,
      isNumber,
      isNotPercentValue,
      multiplier,
    })

    const data = [
      {
        title: expectedNumberTitle,
        values: [{ value: expectedNumber, color: palette.gray60 }],
      },
      {
        title: segmentTitle,
        values: [{ value: segment, color: palette.green50 }],
      },
    ]

    return renderChartTooltipToString({ title: this.x }, data)
  }
