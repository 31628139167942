import { getSeries, ValuesType } from './getSeries'

type MetricType = Array<string | number | null>

interface IFormatMixedDataChart {
  table: MetricType[]
  brand: string
  types?: any
}

const getValues = (table: MetricType[], index: number) =>
  table.map((metric) => metric?.[index])

const transformValues = (values: any[], types: any[]) =>
  values.map((value: null | number, i) => {
    if (value == null) {
      return null
    }
    return {
      y: !types[i].isPercent ? value / 100 : value,
      isNumber: !types[i].isPercent,
    }
  }) as ValuesType

const formatMixedDataChart = ({
  table,
  brand,
  types,
}: IFormatMixedDataChart) => {
  if (!table?.length) return undefined

  const categories = getValues(table, 0)

  const brandValues = transformValues(getValues(table, 1), types)
  const benchmarkValues = transformValues(getValues(table, 2), types)

  const series = getSeries({ brand, brandValues, benchmarkValues })

  return {
    categories,
    series,
  }
}

export default formatMixedDataChart
