import React, { ReactElement, ReactNode, FC } from 'react'
import cn from 'classnames'

import Header from '~globalComponents/Header'
import Footer from '~globalComponents/Footer'

import * as s from './Layout.module.scss'

type VideoSettings = { videoUrl: string; aspectRatio?: number[] }

interface HeaderProps {
  isLoading?: boolean
  page?: string
  omittedParams?: string[]
  mergeParams?: string[]
}

interface FooterProps {
  variant?: string
  guide?: string
  videoSettings?: VideoSettings
  hasHelpCenter?: boolean
}

interface LayoutProps {
  variant?: string
  showHeader?: boolean
  headerProps?: HeaderProps
  footerProps?: FooterProps
  children: ReactElement | ReactNode
}

const Layout: FC<LayoutProps> = (props) => {
  const {
    variant = '',
    showHeader = true,
    headerProps,
    footerProps = {},
    children,
  } = props

  return (
    <div className={cn('wrap', s.layout, { [s[variant]]: variant })}>
      {showHeader && <Header {...headerProps} />}

      {children}

      <Footer {...footerProps} />
    </div>
  )
}

export default Layout
