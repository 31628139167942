const importLogos = (r: any): { [logoName: string]: string } => {
  return r.keys().reduce(
    (acc: any, item: any, i: any) => ({
      ...acc,
      [item.replace(/\.\/|\..*/g, '')]: r.keys().map(r)[i],
    }),
    {}
  )
}

export const brandLogos = importLogos(
  // @ts-ignore
  require.context('~img/brands/', false, /\.(png|jpe?g|svg)$/)
)
