import { useMemo, useState } from 'react'

import { TBCreateCardModalMode } from './enums'

export const useOverlayManager = () => {
  const [createCardModalMode, setCreateCardModalMode] = useState(
    TBCreateCardModalMode.CREATION
  )
  const [createCardModalPosition, setCreateCardModalPosition] = useState(-1) // -1 for hidden

  const [isDeleteBlockModalVisible, setIsDeleteBlockModalVisible] =
    useState(false)

  const [isDeleteOnSaveModalVisible, setIsDeleteOnSaveModalVisible] =
    useState(false)

  const [isSubscribeOffcanvasVisible, setIsSubscribeOffcanvasVisible] =
    useState(false)

  const [deleteBlockModalOptions, setDeleteBlockModalOptions] =
    useState<TBModalDeleteBlockOptions | null>(null)

  const [deleteOnSaveModalOptions, setDeleteOnSaveModalOptions] =
    useState<TBModalDeleteOnSaveOptions | null>(null)

  return useMemo<TBOverlayManager>(
    () => ({
      // Create card modal
      createCardModalMode,
      createCardModalPosition,
      isCreateCardModalVisible: createCardModalPosition >= 0,
      showInitialCreateCardModal: () => {
        setCreateCardModalPosition(0)
        setCreateCardModalMode(TBCreateCardModalMode.CREATION)
      },
      showCreateCardModalAtPosition: (
        pos: number,
        mode: TBCreateCardModalMode
      ) => {
        setCreateCardModalMode(mode)
        setCreateCardModalPosition(pos)
      },
      hideCreateCardModal: () => setCreateCardModalPosition(-1),

      // Delete block modal
      isDeleteBlockModalVisible,
      deleteBlockModalOptions,
      showDeleteBlockModalWithOptions: (options: TBModalDeleteBlockOptions) => {
        setIsDeleteBlockModalVisible(true)
        setDeleteBlockModalOptions(options)
      },
      hideDeleteBlockModal: () => setIsDeleteBlockModalVisible(false),

      // DeleteOnSave modal
      isDeleteOnSaveModalVisible,
      deleteOnSaveModalOptions,
      showDeleteOnSaveModal: (options: TBModalDeleteOnSaveOptions) => {
        setIsDeleteOnSaveModalVisible(true)
        setDeleteOnSaveModalOptions(options)
      },
      hideDeleteOnSaveModal: () => setIsDeleteOnSaveModalVisible(false),

      // Subscribe offcanvas
      isSubscribeOffcanvasVisible,
      showSubscribeOffcanvas: () => setIsSubscribeOffcanvasVisible(true),
      hideSubscribeOffcanvas: () => setIsSubscribeOffcanvasVisible(false),
    }),
    [
      createCardModalMode,
      setCreateCardModalMode,
      createCardModalPosition,
      setCreateCardModalPosition,
      isDeleteBlockModalVisible,
      deleteBlockModalOptions,
      isDeleteOnSaveModalVisible,
      deleteOnSaveModalOptions,
      isSubscribeOffcanvasVisible,
    ]
  )
}
