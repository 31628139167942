import { getSeries, ValuesType } from './getSeries'

type MetricType = Array<string | number | null>

interface IFormatSingleDataChart {
  table: MetricType[]
  brand: string
}

const getValues = (table: MetricType[], index: number) =>
  table.map((metric) => metric?.[index])

const formatSingleDataChart = ({ table, brand }: IFormatSingleDataChart) => {
  if (!table?.length) return undefined

  const categories = getValues(table, 0)

  const brandValues = getValues(table, 1) as ValuesType
  const benchmarkValues = getValues(table, 2) as ValuesType

  const series = getSeries({ brand, brandValues, benchmarkValues })

  return {
    categories,
    series,
  }
}

export default formatSingleDataChart
