import React from 'react'
import { BACKUP_LOGO_NAME, BRAND_LOGO_SIZES } from '~constants'

import { getBrandLogoPath } from './getBrandLogoPath'

export const renderBrandLogo = (
  brand: string,
  size: number = BRAND_LOGO_SIZES.DEFAULT
) => {
  const brandLogoPath = getBrandLogoPath(brand)
  const backupLogoPath = getBrandLogoPath(BACKUP_LOGO_NAME)

  return (
    <img
      src={brandLogoPath || backupLogoPath}
      width={size}
      height={size}
      alt={brand}
      aria-hidden
    />
  )
}
