import React from 'react'
import { InnerContent } from './InnerContent'

export const YourCompetitiveSet = ({
  competitors = [],
  loaded = false,
  hasLimitedHeight = false,
  handleDeleteCompatitor,
}) => (
  <>
    <h3>Your Competitive Set</h3>
    <InnerContent
      {...{ competitors, loaded, hasLimitedHeight, handleDeleteCompatitor }}
    />
  </>
)
