import React from 'react'
import cn from 'classnames'

import { formatNum, displayPercent } from '~utils'

import * as s from './NumberLabel.module.scss'

interface NumberLabelProps {
  value: number
  unit?: string
  decimals?: number
  hasTrailingZeros?: boolean
  withOutline?: boolean
}

export const NumberLabel: React.FC<NumberLabelProps> = ({
  value: rawValue,
  unit,
  decimals,
  hasTrailingZeros,
  withOutline,
}) => {
  const isPercent = unit === '%'
  const prefix = isPercent ? '' : unit

  const value = isPercent
    ? displayPercent(rawValue, decimals, false, '%', hasTrailingZeros)
    : formatNum(rawValue, false, decimals, hasTrailingZeros).full

  return (
    <span className={cn({ [s.textOutline]: withOutline })}>
      {prefix}
      {value}
    </span>
  )
}
