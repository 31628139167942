import React from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

import Tooltip from '~globalComponents/Tooltip'

import { NavLink } from './components/NavLink'

import * as s from './NavList.module.scss'

const NavList = ({ list, header, handleDisabledLink }) => {
  const { pathname, search: searchParams } = useLocation()
  const activeDashboardId = qs.parse(searchParams).id

  return (
    <div key={header}>
      {header && <p className={s.header}>{header}</p>}

      <ul className={s.navList}>
        {list.map((link) => {
          const { id, hidden, name, tooltip } = link || {}
          const linkProps = {
            ...link,
            activeDashboardId,
            handleDisabledLink,
            pathname,
          }

          return (
            !hidden && (
              <li key={id ?? name}>
                {tooltip ? (
                  <Tooltip
                    variant="nav"
                    placement="right"
                    size="xxl"
                    content={tooltip}
                  >
                    <NavLink {...linkProps} />
                  </Tooltip>
                ) : (
                  <NavLink {...linkProps} />
                )}
              </li>
            )
          )
        })}
      </ul>
    </div>
  )
}

export default NavList
