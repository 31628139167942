import React, { useState } from 'react'
import cn from 'classnames'

import { Note } from '~globalComponents/Note'
import { Select } from '../../Select'

export const SelectBlock = ({
  name,
  id,
  label,
  options,
  control,
  rules,
  error,
  watch,
  onMenuOpen,
  onMenuClose,
  isSearchable,
  variant,
  selectAdditionalProps,
  isDisabled,
  disabledMessage,
  withSearchIcon,
  isCompetitors,
  competitors,
  menuPortalTarget,
  warning,
}) => {
  const [isOpen, setIsOpen] = useState(null)

  const watchValue = watch(name)

  const handleOpen = () => {
    setIsOpen(true)
    if (onMenuOpen) onMenuOpen()
  }

  const handleClose = () => {
    setIsOpen(false)
    if (onMenuClose) onMenuClose()
  }

  return (
    <div className={cn('field field--select', variant)}>
      <label
        htmlFor={id}
        className={cn('field_label', {
          'field_label--error': error,
          is_empty: !isOpen && !watchValue?.value,
        })}
      >
        {label}
      </label>
      <div className="field_input field_input--search">
        <Select
          control={control}
          id={id}
          name={name}
          rules={rules}
          hasError={error}
          options={options || []}
          onMenuOpen={handleOpen}
          onMenuClose={handleClose}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          disabledMessage={disabledMessage}
          withSearchIcon={withSearchIcon}
          menuPortalTarget={menuPortalTarget}
          selectedValue={watchValue?.value}
          isCompetitors={isCompetitors}
          competitors={competitors}
          {...selectAdditionalProps}
        />
      </div>
      <div className={cn('field_error', { visible: error })}>
        {error?.message}
      </div>
      {warning && <Note content={warning} variant="warning" iconSize={20} />}
    </div>
  )
}

SelectBlock.defaultProps = {
  onMenuOpen: undefined,
  onMenuClose: undefined,
  disabledMessage: '',
  isDisabled: false,
  isSearchable: true,
  isCompetitors: false,
  competitors: null,
  menuPortalTarget: null,
}
