import { getSeries, ValuesType } from './getSeries'

interface IMultipleDataChartMetric {
  name: string
  brand?: number
  trend?: number
  benchmark?: number
  benchmark_trend?: number
}

interface IGetValues {
  data: IMultipleDataChartMetric[]
  isPIT: boolean
  isBrand?: boolean
}

interface IFormatMultipleDataChart {
  data: IMultipleDataChartMetric[]
  brand: string
  isPIT: boolean
}

const getValues = ({ data, isPIT, isBrand }: IGetValues) =>
  data.map((metric) => {
    const { brand, trend, benchmark, benchmark_trend } = metric
    if (isPIT) {
      return isBrand ? brand : benchmark
    }
    return isBrand ? trend : benchmark_trend
  })

const formatMultipleDataChart = ({
  data,
  brand,
  isPIT,
}: IFormatMultipleDataChart) => {
  const categories = data.map((metric: IMultipleDataChartMetric) => metric.name)

  const brandValues = getValues({ data, isPIT, isBrand: true }) as ValuesType
  const benchmarkValues = getValues({ data, isPIT }) as ValuesType

  const series = getSeries({ brand, brandValues, benchmarkValues })

  return {
    categories,
    series,
  }
}

export default formatMultipleDataChart
