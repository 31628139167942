import { PHASES, TYPES } from '../constants'

export const getIsCellDisabled = ({ date, type, minDate, range, phase }) => {
  switch (type) {
    case TYPES.MONTH: {
      const cellDiff = date.diff(minDate, ['month'])
      return cellDiff.months < 11
    }

    case TYPES.QUARTER:
      return range.startDate && phase !== PHASES.FROM
        ? Math.abs(range.startDate.diff(date, ['years']).years) >= 1
        : false

    default:
      return null
  }
}

export const getActiveClass = (date, { startDate, endDate }) => {
  const condition =
    (startDate <= date && date <= endDate) ||
    date.equals(startDate) ||
    (endDate !== null && date.equals(endDate))

  return condition
}
