import { useRef, useState, useMemo } from 'react'

const getCoords = ({
  startPointCoords: { clientX, clientY },
  width,
  height,
  isBottom,
}) => {
  const OFFSET_HEIGHT = isBottom ? -height - 30 : 20

  const coords = { top: 0, left: 0 }
  const screenWidth = window.innerWidth

  if (clientX - width / 2 < 0) {
    coords.left = clientX
  } else if (clientX + width / 2 <= screenWidth) {
    coords.left = clientX - width / 2
  } else {
    coords.left = clientX - width
  }

  if (clientY - height - OFFSET_HEIGHT >= 0) {
    coords.top = clientY - height - OFFSET_HEIGHT
  } else {
    coords.top = clientY + OFFSET_HEIGHT
  }

  return coords
}

export const useTooltip = ({ isBottom = false }) => {
  const tooltipRef = useRef(null)

  const [cursorCoords, setCursorCoords] = useState({
    clientX: null,
    clientY: null,
  })

  const coords = useMemo(() => {
    const width = tooltipRef.current?.offsetWidth
    const height = tooltipRef.current?.offsetHeight

    return !width || !height
      ? { top: 0, left: 0 }
      : getCoords({ width, height, startPointCoords: cursorCoords, isBottom })
  }, [cursorCoords])

  const handleMouseMove = (e) => {
    setCursorCoords({ clientX: e.clientX, clientY: e.clientY })
  }

  return {
    tooltipRef,
    handleMouseMove,
    coords,
  }
}
