import React, { PropsWithChildren } from 'react'
import { Button } from 'react-bootstrap'
import cn from 'classnames'

import SenseModal from '~globalComponents/SenseModal'
import Icon from '~globalComponents/Icon'
import * as s from './ModalNotify.module.scss'

interface ModalNotifyProps extends PropsWithChildren {
  notifyMessage: string
  show: boolean
  handleClose: (v: boolean) => void
}

const ModalNotify: React.FC<ModalNotifyProps> = (props) => {
  const { notifyMessage, show, handleClose, ...rest } = props

  return (
    <SenseModal
      {...rest}
      size="xs"
      variant="deletePrompt"
      show={show}
      handleClose={handleClose}
    >
      <span className={s.iconWrapper}>
        <Icon name="info_filled" size={20} />
      </span>

      <h2>Oops</h2>
      <p>{notifyMessage}</p>

      <div className={cn(s.bottom)}>
        <Button variant="green60 btn-center" onClick={() => handleClose(false)}>
          Ok
        </Button>
      </div>
    </SenseModal>
  )
}

export default ModalNotify
