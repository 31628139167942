export const getTableRows = (rows: any) =>
  rows.map((row: any) =>
    row.map((cell: any, index: number) => {
      switch (index) {
        case 0:
          return { title: cell }

        case 2: // BENCHMARK_LABEL cell
          return { value: cell, suffix: ' pp' }

        default:
          return { value: cell }
      }
    })
  )
