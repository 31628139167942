import React, { useState, useEffect } from 'react'

import { GradientBoundaries } from '../UI'
import { SearchBar } from '../SearchBar'
import { NoDataPlaceholder } from '../NoDataPlaceholder'
import { OptionList } from './OptionList'

export const SearchedList = (props) => {
  const [searchField, setSearchField] = useState('')
  const { options, isVisible } = props

  const handleSearch = (e) => setSearchField(e.target.value)
  const handleClear = () => setSearchField('')

  const searchedOptions = options.filter((brand) =>
    brand.toLowerCase().includes(searchField?.toLowerCase())
  )
  const searchedOptionsLength = searchedOptions?.length
  const hasMatches = !!searchedOptionsLength

  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => {
        handleClear()
      }, 250)
    }
  }, [isVisible])

  return (
    <>
      <SearchBar
        id="changeBrandSearch"
        value={searchField}
        onChange={handleSearch}
        onClear={handleClear}
        variant="change_brand"
        hasLabel={false}
        hasAutoFocus
      />
      {/* eslint-disable-next-line no-nested-ternary */}
      {hasMatches ? (
        <GradientBoundaries>
          <OptionList {...props} options={searchedOptions} />
        </GradientBoundaries>
      ) : (
        <NoDataPlaceholder message="No matches" variant="changeBrand" />
      )}
    </>
  )
}
