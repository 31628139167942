import React from 'react'
import { useBrandLogo } from '~hooks'
import Icon from '../../Icon'

export const CompetitorItem = ({ name, onDeleteBrand }) => {
  const brandLogo = useBrandLogo(name)

  const handleDeleteBrand = (brand) => () => onDeleteBrand(brand)

  return (
    <li className="cs__list-item">
      <div className="cs__list-item-image">{brandLogo}</div>
      <span>{name}</span>
      <button
        className="icon-close"
        type="button"
        onClick={handleDeleteBrand(name)}
      >
        <Icon name="cross" size={24} />
      </button>
    </li>
  )
}
