import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import qs from 'query-string'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'

import api, { QUERY_KEYS } from '~api'

import { ROUTES } from '~routes'
import { getValidateObj } from '~utils'
import logo from '~img/logo.svg'
import Layout from '~globalComponents/Layout'
import { Loader } from '~globalComponents/Loader'
import { SubmitButton } from '~globalComponents/UI'
import { PasswordValidation } from '~globalComponents/PasswordValidation'

import { RULES } from '~globalComponents/PasswordValidation/constants'

import '../Login/style.scss'

const resetPassword = (params) =>
  api
    .post(QUERY_KEYS.PASSWORD_RESET, qs.stringify(params))
    .then((res) => res.data?.token)

export const PasswordReset = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const params = qs.parse(search)
  const queryClient = useQueryClient()
  const routeToLogin = sessionStorage.getItem('routeToLogin')

  const [isFormVisible, setIsFormVisible] = useState(true)

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    shouldUseNativeValidation: false,
    criteriaMode: 'all',
    mode: 'onChange',
  })

  const watchNewPassword = watch('newPassword')

  const { mutate: resetPasswordMutation, isLoading } = useMutation(
    resetPassword,
    {
      onSuccess: (token) => {
        if (!token) return
        localStorage.setItem('token', token)
        queryClient.invalidateQueries([QUERY_KEYS.ACCOUNT])
        navigate(routeToLogin || ROUTES.HOME)
      },
      onError: ({ response }) => {
        const message = response.data.error
        navigate(`${ROUTES.PASSWORD_INIT_RESET}?error=${message}`)
      },
    }
  )

  const submitForm = ({ newPassword }) =>
    resetPasswordMutation({ password: newPassword, token: params.token })

  useEffect(() => {
    api
      .get(`${QUERY_KEYS.PASSWORD_TOKEN}?token=${params.token}`)
      .then((res) => {
        if (res.data?.valid) {
          setIsFormVisible(true)
        } else {
          navigate(`${ROUTES.PASSWORD_INIT_RESET}?error=${res.data?.msg}`)
        }
      })
  }, [navigate, params.token])

  return isFormVisible ? (
    <Layout
      variant="service"
      showHeader={false}
      footerProps={{
        hasHelpCenter: false,
      }}
    >
      <section className="login login_medallia">
        <div className="login_logo">
          <img src={logo} alt="Sense 360" />
        </div>
        <div className="login_box">
          <h2 className="title" style={{ textAlign: 'center' }}>
            Reset My Password
          </h2>
          <form
            onSubmit={handleSubmit(submitForm)}
            className="form form--login form--reset"
          >
            <div className="field field--text">
              <div className="field_input">
                <input
                  {...register('newPassword', {
                    required: true,
                    validate: getValidateObj(RULES),
                  })}
                  type="password"
                  id="lNewPassword"
                  className={classNames('form-control', {
                    error: Object.keys(errors).includes('newPassword'),
                  })}
                  placeholder="Password"
                />
                <label htmlFor="lNewPassword" className="field_label">
                  New Password
                </label>
              </div>
            </div>
            <div className="field field--text">
              <div className="field_input">
                <input
                  {...register('confirmNewPassword', {
                    required: true,
                    validate: {
                      value: (value) => value === watchNewPassword,
                    },
                  })}
                  type="password"
                  id="lConfirmNewPassword"
                  className={classNames('form-control', {
                    error: Object.keys(errors).includes('confirmNewPassword'),
                  })}
                  placeholder="Confirm New Password"
                />
                <label htmlFor="lConfirmNewPassword" className="field_label">
                  Confirm New Password
                </label>
              </div>
            </div>

            <PasswordValidation
              hasValue={!!watchNewPassword}
              errors={errors?.newPassword?.types}
            />

            <div className="form_bottom form_bottom--reset">
              <SubmitButton
                title="Set New Password"
                isLoading={isLoading}
                iconProps={{
                  name: 'arrow_big',
                  style: { transform: 'scaleX(-1)' },
                }}
              />
            </div>
          </form>

          <div className="login_box_bottom login_box_bottom--reset">
            <Link to={ROUTES.LOGIN}>Back to Login</Link>
          </div>
        </div>
      </section>
    </Layout>
  ) : (
    <Loader variant="fullScreen" />
  )
}
