import React, { useState, useEffect, useMemo } from 'react'
import cn from 'classnames'
import { useForm } from 'react-hook-form'
import Button from 'react-bootstrap/Button'

import { useDashboards } from '~hooks'
import { LABELS, LEADING_SPACE_ERROR } from '~constants'
import { NO_LEADING_SPACE_RULE } from '~regExp'
import { Input, SelectBlock } from '~globalComponents/UI'
import SenseModal from '~globalComponents/SenseModal'
import { CompetitiveSetEditModal } from '~globalComponents/CompetitiveSetEditModal'
import {
  TBMode,
  TBBlockType,
  useTrackerBuilderContext,
} from '~dashboard/TrackerBuilder/utils'
import { validateDashboardName } from './utils'
import { DEFAULT_VALUES } from './constants'

import * as s from './ModalCreateDashboard.module.scss'

const { COMPETITIVE_SET } = LABELS

const ModalCreateDashboard = (props) => {
  const {
    title,
    description,
    primaryButtonText,
    formData,
    show,
    handleClose,
    data,
    submitForm,
    initCompetitors = [],
    isCreateModal,
    dashboardId,
    className,
    ...rest
  } = props

  const { mode, dashboardSettings, trackerBuilderBlocks } =
    useTrackerBuilderContext()

  const { data: dashboardsData } = useDashboards(data?.brand)

  const hasCharts = !!trackerBuilderBlocks.filter(
    (item) => item?.blockType === TBBlockType.CHART
  )?.length

  const [isCompetitiveSetOpen, setIsCompetitiveSetOpen] = useState(false)
  const [competitors, setCompetitors] = useState([])

  const { brands, market, submarkets, filterlabel } = data || {}
  const benchmarkValues = [COMPETITIVE_SET, market, ...(submarkets ?? [])]

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: DEFAULT_VALUES,
  })

  const watchBrand = watch('brand')
  const isBrandChanged = useMemo(() => {
    if (isCreateModal) return false

    if (mode === TBMode.EDITING) {
      return watchBrand?.value !== dashboardSettings?.brand?.value
    }
    return false
  }, [watchBrand, isCreateModal])

  useEffect(() => {
    if (formData) {
      const { updatedBrand, brand, ...restData } = formData
      reset({ ...restData, brand: updatedBrand || brand })
    }
  }, [formData])

  const handleAddCompatitor = (brand) => {
    setCompetitors((prev) => {
      const competitiveSet = prev.length ? competitors : initCompetitors
      const sortedData = [...competitiveSet, brand].sort()
      return sortedData
    })
  }

  const handleDeleteCompatitor = (brand) => {
    const competitiveSet = competitors.length ? competitors : initCompetitors
    const res = competitiveSet.filter((competitor) => competitor !== brand)
    setCompetitors(res.length ? res : initCompetitors)
  }

  const onSubmit = ({ brand, dashboardName, ...res }) => {
    const competitiveSet = competitors.length ? competitors : initCompetitors
    submitForm({
      ...res,
      dashboardName: dashboardName.trim(),
      updatedBrand: brand,
      brand: isCreateModal ? brand : formData.brand,
      competitiveSet,
    })
  }

  const onClose = () => {
    handleClose()
    setCompetitors([])
  }

  // reset form values after closing the modal
  const handleExitedModal = () => {
    const { updatedBrand, brand, ...restData } = formData || {}
    reset({ ...DEFAULT_VALUES, ...restData, brand: updatedBrand || brand })
  }

  return (
    <SenseModal
      show={show}
      size="md"
      isTitleAccent
      handleClose={onClose}
      handleExited={handleExitedModal}
      title={title}
      backdrop="static"
      restoreFocus={false}
    >
      <div {...rest} className={cn(s.createDashboard, className)}>
        <p className={s.descriptions}>{description}</p>
        <form className="form form--login" onSubmit={handleSubmit(onSubmit)}>
          {/* Input dashboard name */}
          <Input
            register={register}
            errors={errors}
            showError
            type="text"
            name="dashboardName"
            label="Dashboard name"
            placeholder="Dashboard name"
            rules={{
              required: true,
              pattern: {
                value: NO_LEADING_SPACE_RULE,
                message: LEADING_SPACE_ERROR,
              },
              validate: validateDashboardName(
                dashboardsData?.custom_dashboards,
                dashboardId
              ),
            }}
            variant="field--text field--required"
          />

          {/* Dropdown with search for select brand */}
          {!!brands && (
            <SelectBlock
              id="tbBrand"
              name="brand"
              label={data?.filterlabel}
              variant="field--required"
              options={brands.map((el) => ({ label: el, value: el }))}
              control={control}
              error={errors?.brand}
              watch={watch}
              rules={{
                required: true,
              }}
              selectAdditionalProps={{
                placeholder: false,
              }}
              withSearchIcon
              warning={
                isBrandChanged &&
                hasCharts &&
                `Some charts may not be available for your new ${filterlabel?.toLowerCase()} selection`
              }
            />
          )}

          {/* Dropdown for select benchmark */}
          <SelectBlock
            name="benchmark"
            id="tbBenchmark"
            label="Benchmark"
            variant="field--required"
            options={benchmarkValues.map((el) => ({ label: el, value: el }))}
            control={control}
            error={errors?.benchmark}
            watch={watch}
            isCompetitors
            competitors={competitors.length ? competitors : initCompetitors}
            rules={{
              required: true,
            }}
            isSearchable={false}
            selectAdditionalProps={{
              handleEditClick: () => setIsCompetitiveSetOpen(true),
              placeholder: false,
            }}
          />

          {/* Buttons */}
          <div className={s.buttonsWrapper}>
            <Button variant="outline-gray btn-center" onClick={onClose}>
              Cancel
            </Button>

            <Button type="submit" variant="green60 btn-center">
              {primaryButtonText}
            </Button>
          </div>
        </form>
        <CompetitiveSetEditModal
          isOpen={isCompetitiveSetOpen}
          competitors={competitors.length ? competitors : initCompetitors}
          handleAddCompatitor={handleAddCompatitor}
          handleDeleteCompatitor={handleDeleteCompatitor}
          handleClose={() => setIsCompetitiveSetOpen(false)}
        />
      </div>
    </SenseModal>
  )
}

export default ModalCreateDashboard
