export type ValuesType = Array<
  number | null | { y: number | null; isNumber: boolean }
>

interface IGetSeries {
  brand: string
  brandValues: ValuesType
  benchmarkValues: ValuesType
}

export const getSeries = ({
  brand,
  brandValues,
  benchmarkValues,
}: IGetSeries) => {
  const series = [
    { name: brand, type: 'column', data: brandValues },
    {
      name: 'Benchmark',
      type: 'column',
      data: benchmarkValues,
    },
  ] as ChartBarMultimetric.ChartSeries

  return series
}
