import React, { useState } from 'react'
import Highcharts from 'highcharts'
import cn from 'classnames'

import Legend from './Legend'
import * as s from './LegendsWrap.module.scss'

type HighchartsProps = {
  callback: Highcharts.ChartCallbackFunction
}

interface LegendsWrapProps {
  legendAlignment?: 'center' | ''
  children: (props: HighchartsProps) => React.ReactElement
}

const LegendsWrap: React.FC<LegendsWrapProps> = ({
  legendAlignment = '',
  children,
}) => {
  const [chart, setChart] = useState<Highcharts.Chart | null>(null)
  const [seriesVisibility, setSeriesVisibility] = useState<{
    [key: string]: boolean
  } | null>(null)

  const handleSetSeriesVisibility = (
    series: Highcharts.Series[],
    visibility?: boolean
  ) => {
    const newSeriesVisibility = series.reduce(
      (acc, serie) => ({
        ...acc,
        [serie?.name]: visibility ?? serie?.visible,
      }),
      {}
    )

    setSeriesVisibility((prev) => ({ ...prev, ...newSeriesVisibility }))
  }

  const handleLegend = (serie: Highcharts.Series, visible: boolean) => {
    const otherSeries = serie.chart.series.filter(
      (item) => item.name !== serie.name
    )

    const isLastVisibleSerie = !otherSeries.some((item) => item.visible)

    if (isLastVisibleSerie && !visible) {
      otherSeries.forEach((item) => {
        item.show()
      })

      handleSetSeriesVisibility(otherSeries, true)
    }

    serie?.setVisible(visible)
    setSeriesVisibility((prev) => ({ ...prev, [serie.name]: visible }))
  }

  const handleChartCallback = (highchart: Highcharts.Chart) => {
    handleSetSeriesVisibility(highchart.series)
    setChart(highchart)
  }

  return (
    <div
      className={cn(s.wrap, {
        [s[legendAlignment]]: legendAlignment,
      })}
    >
      {children({ callback: handleChartCallback })}

      {chart && (
        <div className={s.legends}>
          {chart.series.map((serie) => (
            <Legend
              key={serie.name}
              serie={serie}
              onClick={handleLegend}
              visible={seriesVisibility?.[serie.name]}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default LegendsWrap
