import qs from 'query-string'
import { DateTime } from 'luxon'
import { orderBy, isObject } from 'lodash-es'
import { SERIES_COLORS } from '~constants'

export const chartPeriodFromEndDate = (end, period) =>
  DateTime.fromISO(end).minus({ month: period }).toISODate()

const getStartDateByPeriod = ({ startDate, endDate, period }) =>
  period ? chartPeriodFromEndDate(endDate, period) : startDate

/**
 * TODO: TypeScript
 * @param data
 * @param period
 * @returns {*}
 */
export const getChartDataLink = (data, period) => {
  if (!data?.cols?.length && !data?.set_id) return null

  const {
    pathname,
    cols,
    start_date: startDate,
    end_date: endDate,
    ...filters
  } = data
  const startDateByPeriod = getStartDateByPeriod({ startDate, endDate, period })

  const colsString =
    cols
      .map((col) => (isObject(col) ? `${col.field}=${col.value}` : col))
      .join(';') || ''

  const dataLink = `${pathname}?${qs.stringify({
    ...filters,
    start_date: startDateByPeriod,
    end_date: endDate,
    cols: colsString,
  })}`

  return dataLink
}

const replaceMarket = ({ brands, market, currentMarket }) =>
  brands.map((brand) => (brand === currentMarket ? market : brand))

export const getChartBrandsFromUrl = ({
  data,
  brand,
  market,
  currentMarket,
}) => {
  if (!data?.cols) return null

  const brands = data.cols.map(({ value }) => value)
  const formattedBrands = replaceMarket({ brands, market, currentMarket })
  const sortedBrands = orderBy(
    formattedBrands,
    [(v) => v === brand, (v) => v === market],
    ['desc', 'desc']
  )
  const chartBrands = sortedBrands.map((b, i) => ({
    brand: b,
    color: SERIES_COLORS[i],
  }))
  return chartBrands
}

export const dateDiff = (startDate, endDate) => {
  if (!startDate || !endDate) return 0

  const start = DateTime.fromISO(startDate)
  const end = DateTime.fromISO(endDate)

  return end.diff(start, ['months']).values
}
