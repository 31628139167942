import * as palette from '~styles/palette.scss'
import Highcharts from 'highcharts'

export const NO_CHART_DATA_TEXT = 'No chart data available'

export const LINE_CHART_LINE_COLORS = [
  palette.green50,
  palette.negative40,
  palette.orange50,
  palette.lightblue,
  palette.green,
  palette.yellow,
  palette.violet,
  palette.flatblue,
  palette.pink,
  palette.softmagenta,
  palette.brown,
  palette.darkgreen,
  palette.blue,
  palette.purple30,
  palette.pinkgold,
  palette.teal,
]

export const DEFAULT_CHART_OPTIONS: Highcharts.Options = {
  chart: {
    zooming: {
      type: 'x',
      resetButton: {
        position: {
          align: 'left',
          x: -10,
          y: -10,
        },
        theme: {
          r: 4,
        },
      },
    },
    height: null, // important (add height via css to div that holds charts to achieve needed chart height)
    spacingBottom: 0,
    spacingRight: 10,
  },
}
