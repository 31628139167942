import React from 'react'

import { useBrandLogo } from '~hooks'
import { BRAND_LOGO_SIZES } from '~constants'

export const BrandItem = ({ name, style, onAddBrand }) => {
  const brandLogo = useBrandLogo(name, BRAND_LOGO_SIZES.SM)

  const handleAddBrand = (brand) => () => onAddBrand(brand)

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <li onClick={handleAddBrand(name)} style={style}>
      {brandLogo}
      <span>{name}</span>
    </li>
  )
}
