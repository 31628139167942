import { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import api, { QUERY_KEYS } from '~api'

const searchCompetitorsByName = (term, competitors) =>
  competitors.filter((brand) => brand.toLowerCase().includes(term))

export const useSearchBrands = (
  // eslint-disable-next-line default-param-last
  debouncedSearchTerm = '',
  excludedItems,
  currentBrand
) => {
  const queryClient = useQueryClient()
  const cache = queryClient.getQueryCache()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const competitors = cache.find([QUERY_KEYS.ACCOUNT]).state.data?.brands ?? []

  const [filteredCompetitors, setFilteredCompetitors] = useState([])
  const [excludedCompetitors, setExcludedCompetitors] = useState([])

  // Exclude user's competitors from list
  useEffect(() => {
    if (!Array.isArray(excludedItems) || !Array.isArray(competitors)) return
    setExcludedCompetitors(
      competitors.filter((c) => !excludedItems.includes(c))
    )
  }, [competitors, excludedItems])

  useEffect(() => {
    if (!Array.isArray(excludedCompetitors)) return

    if (debouncedSearchTerm) {
      const searchedCompetitors = searchCompetitorsByName(
        debouncedSearchTerm.toLowerCase(),
        excludedCompetitors
      )
      setFilteredCompetitors(searchedCompetitors)
    } else {
      setFilteredCompetitors(excludedCompetitors)
    }
  }, [competitors, debouncedSearchTerm, excludedCompetitors])

  return {
    filteredCompetitors: filteredCompetitors.filter(
      (brand) => brand !== currentBrand
    ),
  }
}

const addUserCompetitor = (brand) =>
  api.post('/competitors', undefined, { params: { brand } })
export const useAddCompetitor = () => {
  const queryClient = useQueryClient()
  return useMutation(addUserCompetitor, {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.USER_COMPETITORS]) // refetch Competitors,
    },
  })
}

const deleteUserCompetitor = (brand) =>
  api.delete('/competitors', { params: { brand } })
export const useDeleteCompetitor = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteUserCompetitor, {
    onSuccess: () =>
      queryClient.invalidateQueries([QUERY_KEYS.USER_COMPETITORS]), // refetch Competitors
  })
}
