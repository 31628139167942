import React, { createElement } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { ROUTES } from '~routes'
import logoWhite from '~img/logo-white.svg'
import logoBlack from '~img/logo.svg'
import PropTypes from 'prop-types'

const Logo = ({ isHomeAvailable, isBlack, className }) => {
  const { pathname } = useLocation()
  const isHomePage = pathname === ROUTES.HOME
  const isLink = !isHomePage && isHomeAvailable

  const logo = isBlack ? logoBlack : logoWhite

  return createElement(
    isLink ? Link : 'div',
    { to: isLink ? ROUTES.HOME : null, className },
    <img src={logo} width="180" height="45" alt="Sense 360" />
  )
}

Logo.propTypes = {
  isHomeAvailable: PropTypes.any,
  isBlack: PropTypes.any,
  className: PropTypes.any,
}

export default Logo
