import { getCols } from './getCols'
import { getTableRows } from './getTableRows'

const BENCHMARK_LABEL = 'Absolute Difference\n\nvs. Comp Avg.'

export const formatBrandPerceptionData = (data: any) => {
  if (!data.data?.length) return { chartData: undefined }

  const benchmark = { markdown: BENCHMARK_LABEL }

  const brands = data.data?.[0]?.brands.map((name: string) => ({
    icon: name,
  }))

  const cols = getCols(brands, benchmark)
  const dataRows = getTableRows(data.data?.[0]?.table)

  return { chartData: { cols, data: dataRows } }
}
