import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '~api'

/**
 *
 * @returns {UseQueryResult<unknown, unknown>}
 */
export const useLoadUserCompetitors = (brand, onSuccess) => {
  return useQuery([QUERY_KEYS.USER_COMPETITORS, brand], {
    enabled: !!brand,
    onSuccess,
  })
}
