import React, { forwardRef } from 'react'
import cn from 'classnames'

import { OptionList } from './OptionList'
import { SearchedList } from './SearchedList'

import * as s from './style.module.scss'

export const ChangeBrand = forwardRef(({ variant, ...props }, ref) => {
  const { options, isVisible } = props

  return (
    <div
      className={cn(s.wrap, {
        [s.isVisible]: isVisible,
        [s[variant]]: variant,
      })}
      ref={ref}
    >
      <div className={s.head}>Change brand</div>
      {options.length > 8 ? (
        <SearchedList {...props} />
      ) : (
        <OptionList {...props} />
      )}
    </div>
  )
})
