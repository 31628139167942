// define double-click timer object
export const dblClickTimer = {
  clickedOnce: false,
  timer: null,
  timeBetweenClicks: 400,
}

// reset double-click timer
export const resetDoubleClick = () => {
  clearTimeout(dblClickTimer.timer)
  dblClickTimer.timer = null
  dblClickTimer.clickedOnce = false
}

// handle double-click event on chart legend
export const handleLegendDblClick = (e, legend, index) => {
  if (legend) {
    legend.chart.series.forEach((series, i) =>
      i === index ? series.show() : series.hide()
    )
  }
}

// show all series if none are visible
export const handleAllHiddenSeries = (allSeries) => {
  setTimeout(() => {
    const allHidden = allSeries.every(({ visible }) => !visible)
    if (allHidden) {
      allSeries.forEach((series) => series.show())
    }
  }, 0)
}
