import Highcharts from 'highcharts'

import { formatNum, displayPercent } from '~utils'
import * as palette from '~styles/palette.scss'

import { tableTooltipFormatter } from './tableTooltipFormatter'
import { simpleTooltipFormatter } from './simpleTooltipFormatter'

const getGroupPadding = (dataLength: number) => {
  // 8px for charts with 40px column width
  switch (dataLength) {
    case 1:
      return 0.454
    case 2:
      return 0.407
    case 3:
      return 0.361
    case 4:
      return 0.31
    case 5:
      return 0.262
    case 6:
      return 0.221
    default:
      return 0.2 // default groupPadding
  }
}

export const getChartBarMultimetricOptions = ({
  series,
  categories, // names of groups (ex. breakfast, lunch, afternoon, dinner, late night) of 2 bars (brand, competitive set)
  tooltipPeriodHeader,
  isNotPercentValue,
  barColors = [palette.green50, palette.gray30],
  swapBarColors,
  tooltipProps = {},
  tooltipFormatterVariant,
  barStyleVariant,
  chartHeight = 350,
}: ChartBarMultimetric.GetChartOptionsProps): Highcharts.Options => {
  const itemsCount = categories.length

  const colors = swapBarColors ? [...barColors].reverse() : barColors

  const columnOptions =
    barStyleVariant === 'fixedWidth'
      ? {
          pointWidth: 40,
          groupPadding: getGroupPadding(categories.length),
          minPointLength: 3,
        }
      : {}

  const tooltipFormatter =
    tooltipFormatterVariant === 'tableTooltip'
      ? tableTooltipFormatter({
          tooltipPeriodHeader,
          isNotPercentValue,
          ...tooltipProps,
          series,
        })
      : simpleTooltipFormatter({ isNotPercentValue })

  return {
    chart: {
      spacing: [10, 0, 0, 10],
      height: chartHeight,
      width: itemsCount < 7 ? null : itemsCount * 160,
    },
    series,
    colors,
    xAxis: {
      categories,
      labels: {
        distance: 8,
        useHTML: true,
        style: {
          color: palette.gray60,
          textAlign: 'center',
        },
        overflow: 'allow',
        formatter() {
          if (typeof this.value === 'string' && this.value.length > 43) {
            return `${this.value.slice(0, 40)}...`
          }
          return `${this.value}`
        },
      },
    },
    yAxis: {
      labels: {
        formatter() {
          return isNotPercentValue
            ? `${formatNum(this.value, false, 2).full}`
            : `${displayPercent(this.value, 2, false)}`
        },
      },
    },
    tooltip: {
      formatter: tooltipFormatter,
    },
    plotOptions: {
      column: columnOptions,
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          formatter() {
            const value = isNotPercentValue
              ? formatNum(this.y || 0, false, 2).full
              : displayPercent(this.y || 0, 2, false)
            return value
          },
          style: {
            color: palette.gray90,
            fontWeight: '400',
            fontSize: '11px',
          },
        },
      },
    },
    legend: { enabled: false },
  }
}
