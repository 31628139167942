import { DateTime } from 'luxon'

import { getYearsList } from '../../utils'

export const getCalendarData = (startDate, endDate) => {
  const yearsList = getYearsList(startDate, endDate)

  const calendarData = yearsList.reduce((acc, year) => {
    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) =>
      DateTime.local(year, month + 1, 1)
    )
    acc.push({ year, months })
    return acc
  }, [])

  return calendarData
}
