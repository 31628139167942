import { DateTime } from 'luxon'

export const formatDateTooltipForAggregation = (
  aggregation: 'Week' | 'Month' | 'Quarter' | string,
  date: DateTime
) => {
  switch (aggregation) {
    case 'Week':
      return `week ending ${date.plus({ days: 6 }).toFormat('L/d/yyyy')}`
    case 'Month':
      return date.toFormat('LLLL yyyy')
    case 'Quarter':
      return `Q${date.toFormat('q yyyy')}`
    default:
      return new Error('Invalid Period')
  }
}
