import formatMultipleDataChart from './formatMultipleDataChart'
import formatSingleDataChart from './formatSingleDataChart'

const formatBrandPerceptionData = ({ data, brand, isPIT }: any) => {
  if (!data.data?.length) return { chartData: undefined }

  if (data.data.length > 1) {
    const chartData = formatMultipleDataChart({
      data: data.data,
      brand,
      isPIT,
    })

    return { chartData }
  }

  const chartData = formatSingleDataChart({
    table: data.data[0]?.table,
    brand,
  })

  return { chartData }
}

export default formatBrandPerceptionData
