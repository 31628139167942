import { ROUTES } from '~routes'
import { getRedirectMenuItem } from '~utils/menu'
import { HOME, INTELLIGENCE, MENU, REPORT_BUILDER } from './constants'

const sortMenuGroupData = (menuGroup) => {
  if (!menuGroup.children.find((item) => item.children)) {
    return menuGroup
  }
  const childrenArrays = menuGroup.children.filter((item) => item.children)
  const noGroupNameDashboards = menuGroup.children.filter(
    (item) => item.dashboard
  )

  return {
    ...menuGroup,
    children: childrenArrays,
    noGroupNameDashboards,
  }
}

// add additional fields for Intelligence menu items: active, hidden, link...
const modifyIntelligenceMenu = (menu, modules) =>
  menu.map((menuGroup) => {
    if (menuGroup.name === INTELLIGENCE) {
      const listWithActiveKey = modules.map((module) => {
        const currentMenuItem = menuGroup.list.find(
          (listItem) => module.name === listItem.name
        )

        return {
          ...module,
          ...currentMenuItem,
          hidden: !currentMenuItem,
        }
      })
      return { ...menuGroup, list: listWithActiveKey }
    }
    return menuGroup
  })

const getReportBuilderList = (customDashboards) =>
  customDashboards?.map((dashboard) => {
    if (dashboard?.id) {
      const { id, name } = dashboard
      const link = `${ROUTES.TRACKER_BUILDER}/${id}`
      return { id, name, link, active: true }
    }
    return null
  })

export const hasTrackerBuilder = (features) =>
  features?.includes('TrackerBuilder')

export const handleDashboardData = ({
  modules,
  children,
  features,
  customDashboards,
}) => {
  // combine untitled dashboards in one group
  const sortedChildren = children?.map((menuGroup) => {
    const hasNoNameDashboard = menuGroup?.children.find(
      (item) => item.dashboard
    )
    return hasNoNameDashboard ? sortMenuGroupData(menuGroup) : menuGroup
  })

  if (!modules || !modules.length) return sortedChildren

  const intelligenceMenu = modifyIntelligenceMenu(MENU, modules)

  if (!hasTrackerBuilder(features)) {
    return [...intelligenceMenu, ...sortedChildren]
  }

  const reportBuilderMenu = {
    name: REPORT_BUILDER,
    list: getReportBuilderList(customDashboards),
    isCustomShop: true,
  }

  return [...intelligenceMenu, reportBuilderMenu, ...sortedChildren]
}

export const isDashboadAvailable = ({ name, pathname, list }) => {
  const dashboard = list?.find(({ display, link }) =>
    name ? display === name : link === pathname
  )
  return dashboard?.active
}

export const isIntelligenceDashboard = (page) =>
  MENU[0].list.some(({ name }) => name === page)

export const getMenuGroupList = (groupName, menu) =>
  menu?.find((group) => group.name === groupName)?.list

export const getNotifyMessage = ({
  currentPage,
  brand,
  isHomeAvailable,
  modules,
  children,
}) => {
  const redirectPage = isHomeAvailable
    ? HOME
    : getRedirectMenuItem(modules, children)?.name

  return `Sorry but "${currentPage}" is not available for ${brand}, so you will be redirected to the "${redirectPage}" page!`
}
