import React from 'react'

import { DatepickerWrap, QuarterCalendar } from '../components'
import { formatInputValue } from './utils'

export const QuarterDatepicker = ({
  name,
  control,
  rules,
  minDate,
  maxDate,
  clearErrors,
  tooltips,
}) => (
  <DatepickerWrap
    name={name}
    control={control}
    rules={rules}
    defaultDate={maxDate}
    formatValue={formatInputValue}
    clearErrors={clearErrors}
  >
    {(props) => (
      <QuarterCalendar
        minDate={minDate}
        maxDate={maxDate}
        tooltips={tooltips}
        {...props}
      />
    )}
  </DatepickerWrap>
)
