import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { useClickOutside } from '~hooks'
import { ChangeBrand } from '~globalComponents/ChangeBrand'
import Logo from '~globalComponents/Logo'

import HeaderTopPlaceholder from './components/HeaderTopPlaceholder'
import NavLinkExports from './components/Exports/NavLinkExports'
import BtnLogOut from './components/BtnLogOut'

import * as s from './HeaderTop.module.scss'

const HeaderTop = (props) => {
  const {
    className,
    isHomeAvailable,
    showPlaceholder,
    accountInfo,
    omittedParams,
    mergeParams,
    ...rest
  } = props

  const [isChangeBrandDropdownOpen, setIsChangeBrandDropdownOpen] =
    useState(null)
  const changeBrandRef = useRef()
  useClickOutside([changeBrandRef], () => setIsChangeBrandDropdownOpen(false))

  return (
    <div {...rest} id="headerTop" className={cn(s.headerTop, className)}>
      <div className={cn('container', s.headerTopContainer)}>
        <Logo isHomeAvailable={isHomeAvailable} />

        <div className={s.headerTopActions}>
          {showPlaceholder ? (
            <HeaderTopPlaceholder />
          ) : (
            <>
              <NavLinkExports />
              {accountInfo?.options?.length > 1 && (
                <>
                  <button
                    type="button"
                    onClick={() => setIsChangeBrandDropdownOpen(true)}
                  >
                    Change brand
                  </button>
                  <ChangeBrand
                    options={accountInfo.options}
                    handleSuccessQuery={() =>
                      setIsChangeBrandDropdownOpen(false)
                    }
                    ref={changeBrandRef}
                    isVisible={isChangeBrandDropdownOpen}
                    variant="header"
                    omittedParams={omittedParams}
                    mergeParams={mergeParams}
                  />
                </>
              )}
              <BtnLogOut />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

HeaderTop.defaultProps = {
  className: undefined,
  showPlaceholder: false,
}

HeaderTop.propTypes = {
  className: PropTypes.string,
  showPlaceholder: PropTypes.bool,
}

export default HeaderTop
