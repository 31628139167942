import { getCols } from './getCols'
import { getTableRows } from './getTableRows'

const BENCHMARK_LABEL = 'Absolute Diff\n\nvs. Benchmark'

export const formatCustomerExperienceData = ({ data, metricTypes }: any) => {
  const { table, brands } = data

  if (!table?.length || !brands?.length) return { chartData: undefined }

  const benchmark = { markdown: BENCHMARK_LABEL }
  const brandCells = brands.map((name: string) => ({ icon: name }))
  const cols = getCols(brandCells, benchmark)

  const dataRows = getTableRows(table)

  return { chartData: { cols, data: dataRows }, metricTypes }
}
