import { useState } from 'react'

import { useDebounce } from './useDebounce'

// eslint-disable-next-line default-param-last
export const useSearch = (initialValue = '', debounceDelay) => {
  const [searchTerm, setSearchTerm] = useState(initialValue)
  const debouncedSearchTerm = useDebounce(searchTerm, debounceDelay)

  const handleChange = (e) => setSearchTerm(e.target.value)
  const handleClear = () => setSearchTerm('')

  return { searchTerm, debouncedSearchTerm, handleChange, handleClear }
}
