import Highcharts from 'highcharts'

import { getTooltipData, getTooltipTableHeaders } from '~utils/chart/tooltip'
import * as palette from '~styles/palette.scss'
import { renderChartTooltipToString } from '~globalComponents/ChartTooltipContent'

const getColorByName = (name: string) => {
  switch (name) {
    case 'green60':
      return palette.green60
    case 'gray':
      return palette.gray50
    case 'violet':
      return palette.negative40
    default:
      return palette.green70
  }
}

export const tableTooltipFormatter = ({
  tooltipPeriodHeader,
  isNotPercentValue,
  colors,
  series,
}: any): Highcharts.TooltipFormatterCallbackFunction =>
  function () {
    const brandPoint = this.points?.[0]
    const benchmarkPoint = this.points?.[1]
    const brand = brandPoint?.series.name ?? series[0]?.name
    const benchmark = benchmarkPoint?.series.name ?? series[1]?.name
    // @ts-ignore
    const withSample = brandPoint?.point.options?.sample != null

    const headers = getTooltipTableHeaders({
      dateTitle: tooltipPeriodHeader,
      withSample,
    })

    const data = [
      getTooltipData({
        title: brand,
        brand: brandPoint?.y,
        // @ts-ignore
        trend: brandPoint?.point.options?.trend,
        // @ts-ignore
        sample: brandPoint?.point.options?.sample,
        color: colors?.[0] ? getColorByName(colors[0]) : palette.green70,
        isNumber: isNotPercentValue,
        withSample,
      }),
      getTooltipData({
        title: benchmark,
        brand: benchmarkPoint?.y,
        // @ts-ignore
        trend: benchmarkPoint?.point.options?.benchmark_trend,
        // @ts-ignore
        sample: benchmarkPoint?.point.options?.benchmark_sample,
        color: colors?.[1] ? getColorByName(colors[1]) : palette.gray50,
        isNumber: isNotPercentValue,
        withSample,
      }),
    ]

    return renderChartTooltipToString(
      { title: headers, hasTableHeading: true },
      data
    )
  }
