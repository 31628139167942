import { useRef } from 'react'

export const useScrollToRef = () => {
  const ref = useRef(null)

  const scrollToRef = () =>
    setTimeout(() => ref.current?.scrollIntoView({ behavior: 'smooth' }), 0)

  return { ref, scrollToRef }
}
