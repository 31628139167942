import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import qs from 'query-string'

import api from '~api'
import { ROUTES } from '~routes'

export const useSSO = (path, enabled) => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const parsedSearch = qs.parse(search)

  const queryFn = useCallback(
    ({ queryKey }) => {
      const [, { params }] = queryKey
      return api
        .get(`/${path}`, { params })
        .then((res) =>
          res.data?.error ? Promise.reject(res.data.error) : res.data
        )
    },
    [path]
  )

  useQuery([path, { params: parsedSearch }], queryFn, {
    enabled,
    onSuccess: (res) => {
      if (res?.redirect) {
        window.location = res.redirect
      }
    },
    onError: () => {
      navigate(ROUTES.ERROR)
    },
    cacheTime: 0,
  })
}
