import React, { useState, useRef } from 'react'

import { useClickOutside } from '~hooks'
import { Toggle } from './components/Toggle'
import { Menu } from './components/Menu'
import { ModalVideo } from './components/ModalVideo'
import { helpCenter } from './HelpCenter.module.scss'

export const HelpCenter = ({ guide, videoSettings }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(null)

  const toggleRef = useRef(null)
  const dropdownRef = useRef(null)

  const handleClickOutside = () => {
    if (!isModalOpen) setIsDropdownOpen(false)
  }
  useClickOutside([toggleRef, dropdownRef], handleClickOutside)

  const toggleDropdown = () => setIsDropdownOpen((prevState) => !prevState)
  const handleTutorialVideo = () => setIsModalOpen(true)
  const handleModalClose = () => setIsModalOpen(false)

  return (
    <div className={helpCenter}>
      <Toggle ref={toggleRef} onToggle={toggleDropdown} />

      <Menu
        ref={dropdownRef}
        isDropdownOpen={isDropdownOpen}
        videoSettings={videoSettings}
        guide={guide}
        hasTutorialVideo={!!videoSettings}
        handleTutorialVideo={handleTutorialVideo}
      />

      <ModalVideo
        isOpen={isModalOpen}
        handleClose={handleModalClose}
        settings={videoSettings}
      />
    </div>
  )
}
