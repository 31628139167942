import React from 'react'
import cn from 'classnames'

import Icon from '../../Icon'
import { arrow, search, open } from './Icons.module.scss'

export const SearchIcon = () => (
  <span className={search}>
    <Icon name="search" size={24} />
  </span>
)

export const ArrowIcon = ({ selectProps }) => (
  <span className={cn(arrow, { [open]: !selectProps?.menuIsOpen })}>
    <Icon name="caret" size={24} />
  </span>
)
