import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { ROUTES } from '~routes'

export const ProtectedRoute = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const token = localStorage.getItem('token')

  useEffect(() => {
    if (!token) {
      sessionStorage.setItem(
        'routeToLogin',
        `${location.pathname}${location.search || ''}`
      )
      navigate(ROUTES.LOGIN, { replace: true })
    }
  }, [location, token])

  if (!token) {
    return <Navigate to={ROUTES.LOGIN} replace />
  }

  return <Outlet />
}
