import React, { forwardRef } from 'react'

import Icon from '~globalComponents/Icon'
import { toggle, toggleText } from './Toggle.module.scss'

export const Toggle = forwardRef(({ onToggle }, ref) => (
  <button type="button" ref={ref} onClick={onToggle} className={toggle}>
    <Icon name="help_filled" size={24} />
    <span className={toggleText}>Help</span>
  </button>
))
