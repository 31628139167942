import React, { useState, useEffect, memo } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import cn from 'classnames'

import { CHARTS_THEME, CHART_WITHOUT_ANIMATION } from '~constants'
import { ResetAllButton } from '~globalComponents/ResetAllButton'

import { getChartBarOptions } from './utils'

import * as s from './ChartBarSingleMetric.module.scss'

const chartTheme = { ...CHARTS_THEME }

interface ChartBarSingleMetricProps {
  data: ChartBarSingleMetric.ChartData
  benchmarkData: ChartBarSingleMetric.ChartDataItem
  unit?: string
  brandName?: string
  decimals?: number
  hasTrailingZeros?: boolean
  minWidth?: number
  noAnimation?: boolean
  variant?: 'trackerBuilder'
}

const ChartBarSingleMetric: React.FC<ChartBarSingleMetricProps> = ({
  data,
  benchmarkData,
  unit,
  brandName,
  decimals,
  hasTrailingZeros,
  minWidth,
  noAnimation,
  variant,
}) => {
  // Local copy of data (to be able to hide/show columns)
  const [chartData, setChartData] =
    useState<ChartBarSingleMetric.ChartData>(data)

  useEffect(() => {
    setChartData(data)
  }, [data])

  const itemsCount = chartData.length

  const options = getChartBarOptions({
    itemsCount,
    chartData,
    setChartData,
    benchmarkData,
    brandName,
    unit,
    decimals,
    hasTrailingZeros,
    minWidth,
  })

  const merged = Highcharts.merge(
    chartTheme,
    options,
    noAnimation ? CHART_WITHOUT_ANIMATION : undefined
  )

  const handleReset = () => setChartData(data)

  return (
    merged && (
      <div className={cn('chartBar', s.wrap, { [s[variant!]]: variant })}>
        <ResetAllButton
          handleReset={handleReset}
          isVisible={data.length !== chartData.length}
          variant="chartBar"
        />
        <HighchartsReact highcharts={Highcharts} options={merged} />
      </div>
    )
  )
}

const MemoedChartBarSingleMetric = memo(ChartBarSingleMetric)

export { MemoedChartBarSingleMetric as ChartBarSingleMetric }

/*
Usages:

KPI Tracking
http://localhost:3000/kpi_tracking?benchmark=Competitive%20Set&brand=McDonald%27s&date_filter=November%202021&end_date=2021-11-02&geo=National&market=Market%20Benchmark&month=11&period=Month&stab=Wallet%20Share&start_date=2017-11-01&trend=YOY&vtab=Total%20Performance
src/pages/Dashboard/KeyPerformance/components/TabItem/index.ts

Tracker Builder
http://localhost:3000/tracker_builder/152
src/pages/Dashboard/TrackerBuilder/components/SlidesEditor/components/GraphContainer/index.tsx
*/
