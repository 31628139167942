import { TBChartType } from '../enums'
import { formatChartLineData } from './ChartLine'
import { formatSingleBarChartData } from './SingleBarChart'
import { formatMultipleBarChartData } from './MultipleBarChart'
import { formatSingleTableData } from './SingleTable'
import { formatMultipleTableData } from './MultipleTable'

// by ChartType
const formatChartData = ({
  data,
  chartType,
  topicName,
  metricName,
  brand,
  benchmark,
  score,
  isPIT,
  dashboardOptions,
}: any) => {
  // place to provide additional logic for drivers chart types, etc.
  if (!data) return { chartData: undefined }

  switch (chartType) {
    case TBChartType.LINE_CHART_PIT:
    case TBChartType.LINE_CHART_TREND:
      return formatChartLineData({
        data,
        topicName,
        metricName,
        score,
        dashboardOptions,
        isPIT,
      })

    case TBChartType.SINGLE_METRIC_BAR_CHART_PIT:
    case TBChartType.SINGLE_METRIC_BAR_CHART_TREND:
      return formatSingleBarChartData({
        data,
        topicName,
        metricName,
        benchmark,
        score,
        dashboardOptions,
        isPIT,
      })

    case TBChartType.SINGLE_METRIC_TABLE_PIT:
    case TBChartType.SINGLE_METRIC_TABLE_TREND:
      return formatSingleTableData(data)

    case TBChartType.MULTI_METRIC_TABLE_PIT:
    case TBChartType.MULTI_METRIC_TABLE_TREND:
      return formatMultipleTableData({
        data,
        topicName,
        dashboardOptions,
      })

    case TBChartType.MULTI_METRIC_BAR_CHART_PIT:
    case TBChartType.MULTI_METRIC_BAR_CHART_TREND:
      return formatMultipleBarChartData({
        data,
        topicName,
        brand,
        benchmark,
        score,
        isPIT,
        dashboardOptions,
      })

    default:
      return { chartData: data, isPIT }
  }
}

export default formatChartData
