import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'

import { QUERY_KEYS } from '~api'
import { ROUTES } from '~routes'

/**
 *
 * @returns {UseQueryResult<unknown, unknown>}
 */
export const useAccount = () => {
  const navigate = useNavigate()

  return useQuery([QUERY_KEYS.ACCOUNT], {
    enabled: window.self === window.top && !!localStorage.getItem('token'),
    staleTime: 5 * 60 * 1000,
    onError: () => {
      localStorage.removeItem('token')
      navigate(ROUTES.LOGIN)
    },
  })
}
