import React from 'react'

import Icon from '../../Icon'
import { editBtn, editBtnText, editBtnIcon } from './EditButton.module.scss'

export const EditButton = ({ withText, handleClick }) => (
  <button
    className={editBtn}
    type="button"
    onClick={(e) => {
      handleClick()
      e.stopPropagation()
    }}
  >
    {withText && <span className={editBtnText}>Edit</span>}
    <span className={editBtnIcon}>
      <Icon name="check_settings" size={16} />
    </span>
  </button>
)
