import React, { forwardRef } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'

import {
  CHART_WITHOUT_ANIMATION,
  CHARTS_THEME,
  LEGEND_DBCLICK,
} from '~constants'

import { CHART_HISTORY } from '~utils/chartHistory/constants'
import SelectUnderline from '~globalComponents/SelectUnderline'

import { sortChartData } from '~utils'
import { DEFAULT_CHART_OPTIONS } from './constants'
import { getChartLineOptions } from './utils'

import * as s from './ChartLine.module.scss'

export interface ChartLineCommonProps {
  brand: string
  aggregation: string
  showPercentSymbolSuffix?: boolean
  shortDashLineSeriesNames?: string[]
  noAnimation?: boolean
  unit?: string
  decimals?: number

  // Chart history dropdown
  isHistorySelectorVisible?: boolean
}

interface ChartLineProps extends ChartLineCommonProps {
  chartData: ChartLine.ChartData

  // Chart history dropdown
  historyOptions?: { label: string; value: number }[]
  chartPeriodMonths?: {
    label: string
    value: number
  }
  setChartPeriodMonths?: React.Dispatch<
    React.SetStateAction<{
      label: string
      value: number
    }>
  >
}

export const ChartLine = forwardRef<any, ChartLineProps>((props, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    chartData,
    brand,
    aggregation,
    showPercentSymbolSuffix,
    shortDashLineSeriesNames,
    noAnimation,
    isHistorySelectorVisible,
    unit,
    decimals,
    historyOptions,
    setChartPeriodMonths,
    chartPeriodMonths,
  } = props

  const sortedChartData = sortChartData({
    data: chartData,
    brand,
    dottedItems: shortDashLineSeriesNames,
  }) as ChartLine.ChartData

  const options = getChartLineOptions({
    sortedChartData,
    aggregation,
    showPercentSymbolSuffix,
    unit,
    decimals,
    shortDashLineSeriesNames,
  })

  const mergedOptions = Highcharts.merge(
    // Global options
    CHARTS_THEME,
    LEGEND_DBCLICK,
    noAnimation ? CHART_WITHOUT_ANIMATION : undefined,

    // Local options
    DEFAULT_CHART_OPTIONS,
    options
  )

  const chartPeriodOption =
    CHART_HISTORY.find(({ value }) => value === chartPeriodMonths?.value) ||
    CHART_HISTORY[0]

  return isHistorySelectorVisible ? (
    <div className={s.chartLineWrapper}>
      <div className={s.chartLineTop}>
        <SelectUnderline
          value={chartPeriodMonths}
          onChange={setChartPeriodMonths}
          options={historyOptions}
          defaultOption={chartPeriodOption}
        />
        {!!aggregation && `${aggregation}ly trend`}
      </div>

      <HighchartsReact
        highcharts={Highcharts}
        options={mergedOptions}
        ref={ref}
      />
    </div>
  ) : (
    <HighchartsReact
      highcharts={Highcharts}
      options={mergedOptions}
      ref={ref}
    />
  )
})
