import { useEffect } from 'react'

export const useClickOutside = (refs, onClick, isModalOpen) => {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        !isModalOpen &&
        refs.every((ref) => ref.current && !ref.current.contains(e.target))
      ) {
        onClick()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClick, refs])
}
