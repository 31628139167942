import React from 'react'
import { Placeholder } from 'react-bootstrap'

const HeaderTopPlaceholder = () => (
  <>
    <Placeholder animation="glow" style={{ order: '-1' }}>
      <Placeholder style={{ width: '52px' }} />
    </Placeholder>
    <Placeholder animation="glow">
      <Placeholder style={{ width: '105px' }} />
    </Placeholder>
    <Placeholder animation="glow">
      <Placeholder style={{ width: '62px' }} />
    </Placeholder>
  </>
)

export default HeaderTopPlaceholder
