const formatBrandPerceptionData = ({ data, benchmark }: any) => {
  const table = data.table?.[0]

  if (!data || !table?.length || !data.brands?.length) {
    return { chartData: undefined }
  }

  const [brand, ...rest] = data.brands
  const cols = [brand, benchmark, ...rest]

  const chartData = cols.map((col: string, i: number) => ({
    name: col,
    value: table[i + 1],
  }))

  // set unit in V2
  return { chartData, unit: '%' }
}

export default formatBrandPerceptionData
