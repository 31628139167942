import React from 'react'
import cn from 'classnames'

import Icon from '~globalComponents/Icon'
import * as s from './MonthCalendar.module.scss'

export const PickerHeader = ({
  date,
  decreaseYear,
  increaseYear,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
}) => (
  <div className={s.headerInner}>
    <button
      className={cn(s.headerButton, s.left)}
      type="button"
      onClick={decreaseYear}
      disabled={prevYearButtonDisabled}
    >
      <Icon name="chevron_right" size={28} />
    </button>

    <span className={s.headerYear}>
      {date.minus({ years: 1 }).toFormat('yyyy')}
    </span>
    <span className={s.headerYear}>{date.toFormat('yyyy')}</span>

    <button
      className={s.headerButton}
      type="button"
      onClick={increaseYear}
      disabled={nextYearButtonDisabled}
    >
      <Icon name="chevron_right" size={28} />
    </button>
  </div>
)
