import React, { forwardRef } from 'react'
import cn from 'classnames'

import Icon from '~globalComponents/Icon'
import { useAccount } from '~hooks'

import { menu, open, block, title, list, row } from './Menu.module.scss'

export const Menu = forwardRef(
  (
    {
      isDropdownOpen,
      videoSettings,
      guide,
      hasTutorialVideo,
      handleTutorialVideo,
    },
    ref
  ) => {
    const { data: accountInfo } = useAccount()
    const { name, phone, email } = accountInfo?.rep || {}

    return (
      <div ref={ref} className={cn(menu, { [open]: isDropdownOpen })}>
        {(guide || !!videoSettings) && (
          <>
            <div className={block}>
              <h3 className={title}>Help center</h3>
              <ul className={list}>
                {hasTutorialVideo && (
                  <li>
                    <button
                      type="button"
                      onClick={handleTutorialVideo}
                      className={row}
                    >
                      <Icon name="video_settings" size={24} />
                      <span>Tutorial Video</span>
                    </button>
                  </li>
                )}
                {guide && (
                  <li>
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a
                      target="_blank"
                      rel="noopener"
                      href={guide}
                      className={row}
                    >
                      <Icon name="live_help" size={24} />
                      <span>Dashboard Guide</span>
                    </a>
                  </li>
                )}
              </ul>
            </div>

            <hr />
          </>
        )}

        <div className={block}>
          <h3 className={title}>Your Customer Success Manager</h3>
          <ul className={list}>
            <li className={row}>
              <Icon name="face" size={24} />
              <span>{name}</span>
            </li>

            <li>
              <a className={row} href={`tel:${phone}`}>
                <Icon name="phone" size={24} />
                <span>{phone}</span>
              </a>
            </li>

            <li>
              <a
                className={row}
                target="_blank"
                rel="noopener noreferrer"
                href={`mailto:${email}`}
              >
                <Icon name="email" size={24} />
                <span>{email}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
)
