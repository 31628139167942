import React from 'react'

import SenseModal from '~globalComponents/SenseModal'

import { CompetitiveSet } from '../CompetitiveSet'

export const CompetitiveSetEditModal = ({
  isOpen,
  handleClose,
  competitors,
  handleAddCompatitor,
  handleDeleteCompatitor,
}) => (
  <SenseModal size="xl" show={isOpen} handleClose={handleClose}>
    <CompetitiveSet
      competitors={competitors}
      handleAddCompatitor={handleAddCompatitor}
      handleDeleteCompatitor={handleDeleteCompatitor}
      hasLimitedHeight
    />
  </SenseModal>
)
