import React, { memo } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import cn from 'classnames'

import { CHART_WITHOUT_ANIMATION, CHARTS_THEME } from '~constants'
import {
  getChartBarMultimetricOptions,
  getAdditionalOptionsByType,
} from './utils'
import LegendsWrap from '../LegendsWrap'
import * as s from './ChartBarMultimetric.module.scss'

const chartTheme = { ...CHARTS_THEME }

interface ChartBarMultimetricProps
  extends ChartBarMultimetric.GetChartOptionsProps {
  noAnimation?: boolean
  legendAlignment?: 'center' | ''
}

const ChartBarMultimetric: React.FC<ChartBarMultimetricProps> = ({
  noAnimation,
  legendAlignment = '',
  ...rest
}) => (
  <LegendsWrap legendAlignment={legendAlignment}>
    {({ callback }) => (
      <div className={cn('chart', s.chart)}>
        <HighchartsReact
          highcharts={Highcharts}
          options={Highcharts.merge(
            chartTheme,
            getChartBarMultimetricOptions(rest),
            noAnimation ? CHART_WITHOUT_ANIMATION : undefined,
            getAdditionalOptionsByType(rest)
          )}
          callback={callback}
        />
      </div>
    )}
  </LegendsWrap>
)

const MemoedChartBarMultimetric = memo(ChartBarMultimetric)

export { MemoedChartBarMultimetric as ChartBarMultimetric }

/*
Usages:

Customer Migration (inner tab - Deep Dive Content)
http://localhost:3000/customer_migration?brand=McDonald%27s&start_date=2020-11-01&end_date=2021-10-01&vtab=New%20and%20Acquired%20Customers
src/pages/Dashboard/CustomerMigration/components/CardWithChartBar/index.js
src/pages/Dashboard/CustomerMigration/components/DeepDiveContent/Content.js

Customer Experience
http://localhost:3000/customer_experience?brand=McDonald%27s&benchmark=Competitive%20Set&trend=POP&period=1&start_date=2022-01-01&end_date=2022-01-01&vtab=Customer%20Experience%20by%20Daypart&score=topbox&order=Total&daypart=Entire%20Day
src/pages/Dashboard/CustomerExperience/components/ChartSelectionsPanel/index.js

Petco In-Store Customer Experience
http://localhost:3000/petco_instore_customer_experience?benchmark=PetSmart&brand=Petco&channel=instore&end_date=2022-04-01&period=1&score=topbox&start_date=2022-04-01&trend=POP&vtab=Customer%20Service%20Experience
src/pages/Dashboard/CustomerExperience/components/Tabs/CustomerServiceExperienceTab/BottomCard/ChartSection/index.js

Tracker Builder
http://localhost:3000/tracker_builder/156
src/pages/Dashboard/TrackerBuilder/components/SlidesEditor/components/GraphContainer/index.tsx

TODO: Customer Profile (Expanded card) - needs overhaul
http://localhost:3000/customer_profile?bbrand=Competitive%20Set&bgeo=National&brand=McDonald%27s&trend=POP&period=3%20Months&geo=National&end_date=2021-11-30&bengagement=Total&engagement=Total
src/components/CustomerProfileSubcards/components/ExpandedSubcard.js


TODO (if can be generalized) - Wallet Share (purple bar chart with logos):
Customer Migration (inner tab - Deep Dive Content)
http://localhost:3000/customer_migration?brand=McDonald%27s&start_date=2020-11-01&end_date=2021-10-01&vtab=New%20and%20Acquired%20Customers
src/pages/Dashboard/CustomerMigration/components/CardWalletShare/ChartBar.js\

TODO: Customer Experience (Walmart) - NonBuyerAnalysisTab (very custom style)
http://localhost:3000/customer_experience?benchmark=Competitive%20Set&brand=Walmart&buyer_status=Total&channel=Total&end_date=2022-01-01&period=1&score=topbox&start_date=2022-01-01&trend=POP&vtab=Non-Buyer%20Analysis
src/pages/Dashboard/CustomerExperience/components/Tabs/NonBuyerAnalysisTab/components/CardDemographicProfile/index.js
*/
