import React from 'react'
import cn from 'classnames'
import { renderBrandLogo } from '~utils/brand'
import { BRAND_LOGO_SIZES } from '~constants'

import * as s from './Label.module.scss'

interface BrandIconLabelProps {
  brandName: any
  isFirst?: boolean
  isLast?: boolean
  isDisabled?: boolean
}

export const BrandIconLabel: React.FC<BrandIconLabelProps> = ({
  brandName,
  isFirst,
  isLast,
  isDisabled,
}) => {
  return (
    <span
      className={cn(s.label, {
        [s.first]: isFirst,
        [s.last]: isLast,
        [s.disabled]: isDisabled,
      })}
    >
      {renderBrandLogo(brandName, BRAND_LOGO_SIZES.XL)}
      <span className={s.tooltip}>
        <span className={s.tooltipContent}>{brandName}</span>
      </span>
    </span>
  )
}
