import React from 'react'

import cn from 'classnames'
import { useAccount, useLoadUserCompetitors } from '~hooks'
import { Loader } from '../Loader'
import { YourCompetitiveSet } from './YourCompetitiveSet'
import { AddBrands } from './AddBrands'
import './style.scss'

export const CompetitiveSet = ({
  hasLimitedHeight,
  handleAddCompatitor,
  handleDeleteCompatitor,
  competitors,
}) => {
  const { data: accountInfo } = useAccount()

  const {
    data: competitorsResponse,
    isSuccess,
    isFetching,
  } = useLoadUserCompetitors(accountInfo?.brand)

  return (
    <div
      className={cn('cs', {
        cs__loader: isFetching,
        isModal: hasLimitedHeight,
      })}
    >
      {isFetching ? (
        <Loader />
      ) : (
        isSuccess && (
          <>
            <div className="cs__left">
              <YourCompetitiveSet
                competitors={competitors ?? competitorsResponse?.competitors}
                loaded={isSuccess}
                hasLimitedHeight={hasLimitedHeight}
                handleDeleteCompatitor={handleDeleteCompatitor}
              />
            </div>
            <div className="cs__right cs__add">
              <AddBrands
                userCompetitors={
                  competitors ?? competitorsResponse?.competitors
                }
                currentBrand={accountInfo?.brand}
                handleAddBrand={handleAddCompatitor}
              />
            </div>
          </>
        )
      )}
    </div>
  )
}
