import { getMetricTypeProps, getMetricTypesField } from '../../utils'
import { TBChartScoreType } from '../../enums'

const formatCustomerExperienceData = ({
  data,
  topicName,
  metricName,
  score,
  dashboardOptions,
  isPIT,
}: any) => {
  const { cols, data: dataPoints } = data

  if (!dataPoints?.length) return { chartData: undefined }

  const isAverageScore = score === TBChartScoreType.AVERAGE_SCORE
  const metricTypesField = getMetricTypesField(topicName)

  const { isNPS, isScore } = getMetricTypeProps({
    name: metricName,
    metricTypes: dashboardOptions?.[metricTypesField],
    isScoreMode: isPIT && isAverageScore,
  })

  const percent = !isNPS && !isScore

  const preparedData = cols.map((col: string, i: number) => {
    const value = dataPoints.at(-1)?.values[i] // use last data array
    return { name: col, value }
  })

  return {
    chartData: preparedData,
    unit: percent ? '%' : '',
    isNumber: !percent,
    decimals: isNPS ? 0 : 1,
  }
}

export default formatCustomerExperienceData
