import React from 'react'
import ReactSelect from 'react-select'
import cn from 'classnames'
import PropTypes from 'prop-types'

import Icon from '~globalComponents/Icon'

import { Option } from './Option'
import { SELECT_STYLES } from './constants'
import * as s from './SelectUnderline.module.scss'

export const ArrowIcon = ({ selectProps }) => (
  <span className={cn(s.arrow, { [s.open]: selectProps?.menuIsOpen })}>
    <Icon name="icon-chevron_down_small" size={24} />
  </span>
)

// TODO: refactor
const SelectUnderline = ({
  id,
  options,
  defaultOption,
  handleChange,
  value,
  onChange,
  alignLeft,
}) => (
  <div className={cn(s.wrapper, { [s.alignLeft]: alignLeft })}>
    <ReactSelect
      key={id ? `chart-history-${id}` : null}
      styles={SELECT_STYLES}
      defaultValue={defaultOption}
      options={options}
      onChange={(newOption) => {
        handleChange?.(newOption.value)
        onChange?.(newOption)
      }}
      isSearchable={false}
      components={{
        Option,
        IndicatorsContainer: ArrowIcon,
      }}
      value={value}
    />
  </div>
)

// TODO: TS
SelectUnderline.propTypes = {
  id: PropTypes.any,
  options: PropTypes.any,
  defaultOption: PropTypes.any,
  handleChange: PropTypes.any,
  alignLeft: PropTypes.bool,

  // Controllable props
  onChange: PropTypes.any,
  value: PropTypes.any,
}

export default SelectUnderline
