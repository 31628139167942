import { useEffect } from 'react'

const confirmationMessage = 'Your changes will not be saved.'

// Adds a page reload prompt that warns the user if there are unsaved changes
export const usePageReloadPrompt = (unsavedChanges = false) => {
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (!unsavedChanges) return ''

      e.preventDefault()
      e.returnValue = confirmationMessage
      return confirmationMessage
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [unsavedChanges])
}
