import { useState, useEffect, useRef } from 'react'

import { delay } from '~globalComponents/Tooltip/style.module.scss'

const DURATION = 3500

export const useShowTooltip = (props) => {
  const { duration = DURATION, animationDelay = delay } = props || {}

  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipProps, setTooltipProps] = useState({ content: '' })

  // restart tooltip hidding delay when tooltip content change
  const timeoutRef = useRef(null)
  useEffect(() => {
    if (!tooltipProps.content) return

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setShowTooltip(false)
      setTimeout(() => setTooltipProps({ content: '' }), animationDelay)
    }, duration)
  }, [tooltipProps])

  const handleContent = (content) => {
    setTooltipProps({ content })
    setShowTooltip(true)
  }

  return {
    showTooltip,
    tooltipProps,
    handleContent,
    setShowTooltip,
  }
}
