import React from 'react'
import PropTypes from 'prop-types'
import sprite from './icons.svg'
import trackerBuilderSprite from './trackerBuilderIcons.svg'

const paramToArray = (param) =>
  typeof param === 'object' ? param : [param, param]

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<any> & React.RefAttributes<unknown>>}
 */
const Icon = React.forwardRef((props, ref) => {
  const { isTrackerBuilder, name, size, viewbox, color, className, ...rest } =
    props

  const sizes = paramToArray(size)
  const vbox = viewbox ? paramToArray(viewbox) : sizes
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width={sizes[0]}
      height={sizes[1]}
      viewBox={`0 0 ${vbox[0]} ${vbox[1]}`}
      ref={ref}
      fill={color || 'currentColor'}
      className={className}
    >
      <use
        xlinkHref={`${
          isTrackerBuilder ? trackerBuilderSprite : sprite
        }#${name}`}
      />
    </svg>
  )
})

Icon.defaultProps = {
  color: '',
  size: [24, 24],
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  // eslint-disable-next-line react/require-default-props
  viewbox: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  color: PropTypes.string,
  className: PropTypes.string,
}

export default Icon
