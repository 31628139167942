import React from 'react'
import { useQueryClient } from '@tanstack/react-query'

import api from '~api'
import { ROUTES } from '~routes'
import { useNavigate } from 'react-router-dom'

const BtnLogOut = (props) => {
  const navigate = useNavigate()

  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()

  const handleLogOut = () => {
    api.get('/expire_token')

    queryCache.clear()

    setTimeout(() => {
      localStorage.removeItem('token')
      sessionStorage.removeItem('routeToLogin')
      navigate(ROUTES.LOGIN)
    }, 0)
  }

  return (
    <button {...props} type="button" onClick={handleLogOut}>
      Sign out
    </button>
  )
}

export default BtnLogOut
