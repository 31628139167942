import api, { QUERY_KEYS } from '~api'
import { saveAs } from 'file-saver'
import { FORMAT_EXTENSIONS } from '~constants'

export const hasExtension = (fileName) => !!fileName?.split('.')?.[1]

export const getFallbackName = (name, format) => {
  const extension = FORMAT_EXTENSIONS?.[format] || ''
  return name ? `${name}.${extension}` : `Report.${extension}`
}

export const downloadReport = ({ fileName, queryId }) =>
  api
    .get(`${QUERY_KEYS.REPORT}/${queryId}`, { responseType: 'blob' })
    .then(({ data }) => {
      saveAs(data, fileName)
    })
