import { useCallback, useState, useEffect } from 'react'

export const useArrowClick = ({
  dataWrapperRef,
  contentRef,
  scrollTransition,
  scrollWidth,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const [arrowsState, setArrowsState] = useState({
    left: 'hidden',
    right: 'visible',
  })
  const [movingState, setMovingState] = useState(false)
  const [maxScroll, setMaxScroll] = useState(0)

  const setArrowVisibility = useCallback(
    (bool) => (bool ? 'visible' : 'hidden'),
    []
  )

  const onArrowClick = useCallback(
    (scrollTo) => {
      if (scrollTo === 'right') {
        const scroll =
          maxScroll > scrollPosition + scrollWidth
            ? scrollWidth + scrollPosition
            : maxScroll
        // eslint-disable-next-line no-param-reassign
        dataWrapperRef.current.style.transform = `translate(-${scroll}px)`
        setArrowsState({
          left: setArrowVisibility(scroll > 0),
          right: setArrowVisibility(scroll < maxScroll),
        })
        setMovingState(true)
        setTimeout(() => {
          setMovingState(false)
          setScrollPosition(scroll)
        }, Number(scrollTransition))
      } else {
        const scroll =
          scrollPosition - scrollWidth > 0 ? scrollPosition - scrollWidth : 0
        setArrowsState({
          left: setArrowVisibility(scroll > 0),
          right: setArrowVisibility(scroll < maxScroll),
        })
        setMovingState(true)
        setTimeout(() => {
          setMovingState(false)
          setScrollPosition(scroll)
        }, Number(scrollTransition))
        // eslint-disable-next-line no-param-reassign
        dataWrapperRef.current.style.transform = `translate(-${scroll}px)`
      }
    },
    [
      dataWrapperRef,
      maxScroll,
      scrollPosition,
      scrollTransition,
      scrollWidth,
      setArrowVisibility,
    ]
  )
  useEffect(() => {
    if (contentRef?.current && dataWrapperRef?.current) {
      setMaxScroll(
        dataWrapperRef.current.offsetWidth - contentRef.current.offsetWidth
      )
    }
  }, [contentRef, dataWrapperRef, setMaxScroll])

  return { onArrowClick, arrowsState, movingState }
}
