import { ROUTES } from '~routes'

export const HOME = 'Home'
export const INTELLIGENCE = 'Intelligence'

export const MENU = [
  {
    name: INTELLIGENCE,
    list: [
      // TODO: remove after renaming on server
      {
        name: 'Competitive Landscape',
        link: ROUTES.COMPETITIVE_PERFORMANCE,
      },
      {
        name: 'Competitive Performance',
        link: ROUTES.COMPETITIVE_PERFORMANCE,
      },
      {
        name: 'Market Performance',
        link: ROUTES.MARKET_PERFORMANCE,
      },
      {
        name: 'KPI Tracking',
        link: ROUTES.KPI_PERFORMANCE,
      },
      {
        name: 'Customer Profile',
        link: ROUTES.CUSTOMER_PROFILE,
      },
      {
        name: 'Customer Migration',
        link: ROUTES.CUSTOMER_MIGRATION,
      },
      {
        name: 'Customer Experience',
        link: ROUTES.CUSTOMER_EXPERIENCE,
      },
      {
        name: 'Petco In-Store CX',
        link: ROUTES.PETCO_INSTORE_CUSTOMER_EXPERIENCE,
        nested: true,
      },
      {
        name: 'Petco Digital CX',
        link: ROUTES.PETCO_DIGITAL_CUSTOMER_EXPERIENCE,
        nested: true,
      },
      {
        name: 'Brand Perception',
        link: ROUTES.BRAND_PERCEPTION,
      },
      {
        name: 'Insights Briefings',
        link: ROUTES.INSIGHTS_BRIEFINGS,
      },
    ],
  },
]

export const REPORT_BUILDER = 'Report Builder'

export const MODAL_SETTINGS = {
  title: 'Create custom dashboard',
  description:
    'Create a custom dashboard tailored to your business. Once created, export your custom dashboard to PowerPoint or set up a subscription to have the PowerPoint arrive in your inbox whenever there’s updated data.',
  primaryButtonText: 'Create',
}
