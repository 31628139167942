import {
  getIsNPSParameter,
  getIsScoreParameter,
} from '~dashboard/CustomerExperience/utils'

import { TBBlockType, TBMode, TBTopicType } from './enums'
import { TB_LOCAL_STORAGE_KEY, TB_METRIC_ICONS } from './constants'

export const getBlockHeading = (block: TBBlock) => {
  return block.blockType === TBBlockType.CHART
    ? block.chartOptions?.metricName
    : block.dividerOptions?.text
}

export const getMetricIconById = (metricId: string) =>
  TB_METRIC_ICONS?.[metricId] || { iconName: 'benchmark' }

const trackerBuilderBlockToRequestCharts = (
  trackerBuilderBlocks: TBBlock[]
): TBCreateDashboardRequest.Element[] => {
  return trackerBuilderBlocks.map(
    ({ id, blockType, chartOptions, dividerOptions }) => {
      const chart: TBCreateDashboardRequest.Element =
        blockType === TBBlockType.CHART
          ? {
              id,
              chart: {
                id: chartOptions.metricType,
                filters: chartOptions.chartFilters,
                type: chartOptions.chartType,
                topic: chartOptions.topicName,
                chart: chartOptions.metricName,
              },
            }
          : {
              id,
              separator: {
                text: dividerOptions.text,
              },
            }

      return chart
    }
  )
}

export const trackerBuilderStateToRequestJson = (
  dashboardSettings: TBDashboardSettings,
  trackerBuilderBlocks: TBBlock[]
) => {
  const { dashboardName, brand, updatedBrand, benchmark, competitiveSet } =
    dashboardSettings

  const requestObject: TBCreateDashboardRequest.RootObject = {
    name: dashboardName,
    global_filters: {
      brand: (updatedBrand || brand).value,
      benchmark: benchmark.value,
    },
    competitive_set: competitiveSet,
    elements: trackerBuilderBlockToRequestCharts(trackerBuilderBlocks),
  }

  return requestObject
}

const getAllChartsArray = (chartOptions: TBOptionsResponse.Options) =>
  chartOptions.topics
    .flat()
    .flatMap((topic) => topic.layers)
    .flatMap((layer) => layer.charts)

export const findChartFromOptionsById = (
  chartId: string,
  chartOptions: TBOptionsResponse.Options
): TBOptionsResponse.Chart | undefined => {
  const allCharts = getAllChartsArray(chartOptions)

  return allCharts.find((chart) => chart.id === chartId)
}

export const findTopicFromOptionsByName = (
  topicName: string,
  chartOptions: TBOptionsResponse.Options
): TBOptionsResponse.Topic | undefined =>
  chartOptions.topics.find((topic) => topic.topic_name === topicName)

const getCustomDashboardLSKey = (dashboardId?: string) =>
  [TB_LOCAL_STORAGE_KEY, dashboardId].join(':')

export const saveTrackerBuilderToLS = (
  trackerBuilderState: {
    mode: TBMode
    dashboardSettings: TBDashboardSettings | null
    trackerBuilderBlocks: TBBlock[]
  },
  dashboardId?: string
) => {
  window.localStorage.setItem(
    getCustomDashboardLSKey(dashboardId),
    JSON.stringify(trackerBuilderState)
  )
}

export const getTrackerBuilderFromLS = (
  dashboardId?: string
):
  | {
      mode: TBMode
      dashboardSettings: TBDashboardSettings | null
      trackerBuilderBlocks: TBBlock[]
    }
  | undefined => {
  const savedValue = window.localStorage.getItem(
    getCustomDashboardLSKey(dashboardId)
  )

  return savedValue ? JSON.parse(savedValue) : undefined
}

export const removeTrackerBuilderFromLS = (dashboardId?: string) => {
  window.localStorage.removeItem(getCustomDashboardLSKey(dashboardId))
}

export const isQuartersNumber = (topic?: string) =>
  !!(
    topic === TBTopicType.BRAND_PERCEPTION ||
    topic?.includes('Customer Experience')
  )

export const getMetricTypeProps = ({
  name,
  metricTypes,
  isScoreMode,
}: {
  name?: string
  metricTypes?: any
  isScoreMode?: boolean
}) => {
  if (!metricTypes) return {}

  const isNPS = getIsNPSParameter(name, metricTypes)
  const isScore = getIsScoreParameter({
    name,
    metricTypes,
    isNPS,
    isScoreMode,
  })

  return { isNPS, isScore }
}

export const getMetricTypesField = (topicName: string) => {
  switch (topicName) {
    case TBTopicType.CUSTOMER_EXPERIENCE_RESTAURANT:
      return 'metric_types_restaurant'
    case TBTopicType.CUSTOMER_EXPERIENCE_RETAIL:
      return 'metric_types_retail'

    default:
      return ''
  }
}
