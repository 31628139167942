import Highcharts from 'highcharts'
import { renderToStaticMarkup } from 'react-dom/server'
import React from 'react'
import { orderBy, partition } from 'lodash-es'

import * as palette from '~styles/palette.scss'

import { BrandIconLabel } from './BrandIconLabel'
import { NumberLabel } from '../NumberLabel'
import { MIN_WIDTH } from './constants'

const BAR_CHART_PLOT_LINE_STYLE = {
  backgroundColor: palette.gray60,
  color: palette.white,
  padding: '4px 8px',
  borderRadius: '100px',
  fontWeight: '500',
  fontSize: '12px',
}

const getWidth = (itemsCount: number, minWidth: number) => {
  if (itemsCount <= 11) return null

  const width = itemsCount * 70
  return width < minWidth ? minWidth : width
}

export const getChartBarOptions = ({
  itemsCount,
  chartData,
  setChartData,
  benchmarkData,
  brandName,
  unit,
  decimals,
  hasTrailingZeros,
  minWidth = MIN_WIDTH,
}: {
  itemsCount: number
  chartData: ChartBarSingleMetric.ChartData
  setChartData: React.Dispatch<
    React.SetStateAction<ChartBarSingleMetric.ChartData>
  >
  benchmarkData: ChartBarSingleMetric.ChartDataItem
  brandName?: string
  unit?: string
  decimals?: number
  hasTrailingZeros?: boolean
  minWidth?: number
}): Highcharts.Options => ({
  chart: {
    spacing: [12.5, 16, 0, 22], // left: 22px - to fix cutted big benchmark value
    width: getWidth(itemsCount, minWidth),
    events: {
      render() {
        // add click event listener to x-axis tick labels to remove corresponding brand on click
        const ticks = Object.entries(this.xAxis[0].ticks)
        ticks.forEach(
          ([index, tick]) =>
            tick.label?.element?.addEventListener('click', () => {
              if (chartData.length > 1) {
                setChartData((prevState) =>
                  prevState.filter((_, i) => i !== +index)
                )
              }
            })
        )
      },
    },
  },
  xAxis: {
    type: 'category',
    categories: chartData.map(({ name }) => name),
    labels: {
      distance: 8,
      useHTML: true,
      style: {
        display: 'block',
        paddingBottom: '100%',
        width: 60,
      },
      formatter() {
        const { value, isFirst, isLast } = this
        return renderToStaticMarkup(
          <BrandIconLabel
            brandName={value}
            isFirst={isFirst}
            isLast={isLast}
            isDisabled={chartData.length === 1}
          />
        )
      },
    },
  },
  // @ts-ignore
  yAxis: {
    visible: true,
    gridLineColor: palette.gray200,
    gridLineDashStyle: 'LongDash',
    gridLineWidth: 1,
    labels: {
      x: -7,
      formatter() {
        return renderToStaticMarkup(
          <NumberLabel
            value={this.value as number}
            unit={unit}
            decimals={decimals}
            hasTrailingZeros={hasTrailingZeros}
          />
        )
      },
    },
    plotLines: [
      {
        value: benchmarkData?.value,
        label: {
          text: 'Benchmark',
          align: 'right',
          x: 15,
          y: 3,
          style: BAR_CHART_PLOT_LINE_STYLE,
          useHTML: true,
        },
        zIndex: 1,
        width: 2,
        color: palette.gray60,
        dashStyle: 'Dash',
      },
      {
        value: benchmarkData?.value,
        label: {
          formatter() {
            return renderToStaticMarkup(
              <NumberLabel
                // @ts-ignore
                value={this.options.value}
                unit={unit}
                decimals={decimals}
                hasTrailingZeros={hasTrailingZeros}
              />
            )
          },
          align: 'left',
          x: 0,
          y: 0,
          style: {
            ...BAR_CHART_PLOT_LINE_STYLE,
            transform: 'translateX(-100%)',
          },
          useHTML: true,
        },
        zIndex: 1,
        width: 0,
      },
    ],
  },
  series: [
    {
      type: 'column',
      data: chartData.map(({ name, value }) => ({
        name,
        y: value,
        color: name === brandName ? palette.green50 : palette.gray30,
      })),
    },
  ],
  plotOptions: {
    column: {
      dataLabels: {
        enabled: true,
        useHTML: true,
        align: 'center',
        overflow: 'allow',
        crop: false,
        style: {
          fontWeight: 'normal',
          fontSize: '14px',
          color: palette.gray80,
        },
        formatter() {
          return renderToStaticMarkup(
            <NumberLabel
              value={this.y as number}
              unit={unit}
              decimals={decimals}
              hasTrailingZeros={hasTrailingZeros}
              withOutline
            />
          )
        },
      },
    },
  },
  tooltip: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
})

// separate brand data from benchmarkData
export const separateDataFromBenchmark = (data: any, benchmark: string) =>
  partition(data, (v) => v.name !== benchmark)

// sort order: brand, value in desc, null values to the end
export const sortChartBarData = (data: any, brand: string) =>
  orderBy(
    data,
    [
      (v) => v.value != null, // move null values to the end
      (v) => v.name === brand,
      'value',
    ],
    ['desc', 'desc', 'desc']
  )
