export const safeJSONParse = (value) => {
  try {
    return JSON.parse(value)
  } catch (e) {
    return undefined
  }
}

export const getStorageItem = (key) => {
  const value = sessionStorage.getItem(key)
  return safeJSONParse(value)
}

export const setStorageItem = (key, value) => {
  const valueString = JSON.stringify(value)
  sessionStorage.setItem(key, valueString)
}
