import { partition, zip } from 'lodash-es'

const getChartBarOptions = (
  responseData: {
    cols: string[]
    data: { date: string; values: number[] }[]
  },
  brand: string
): {
  series?: ChartBarMultimetric.ChartSeries
  categories?: string[]
} | null => {
  const { cols, data } = responseData || {}

  if (!data?.length) return null

  const { values } = data[0]

  // Zip column names with respective values
  // [[brand column name, value], [benchmark column name, value], ...]
  const zippedObj = zip(cols, values)

  // Partition benchmark data from brand data
  // [[brand column name, value], ...] and [[benchmark column name, value], ...]
  const [benchmarkData, brandData] = partition(
    zippedObj,
    ([col]) => col?.startsWith('Benchmark')
  )

  // Get clean column names
  // [column name 1, column name 2, ...]
  const categories = benchmarkData.map(
    ([k]) => k?.replace('Benchmark ', '')?.replace(' yoy', '') || ''
  )

  // Get brand values
  // [brand value 1, brand value 2, ...]
  const brandValues = brandData.map(([, brandValue]) => brandValue!)

  // Get benchmark values
  // [benchmark value 1, benchmark value 2, ...]
  const benchmarkValues = benchmarkData.map(
    ([, benchmarkValue]) => benchmarkValue!
  )

  return {
    categories,
    series: [
      { name: brand, type: 'column', data: brandValues },
      {
        name: 'Benchmark',
        type: 'column',
        data: benchmarkValues,
      },
    ],
  }
}

export default getChartBarOptions
