import React from 'react'
import cn from 'classnames'
import Icon from '../Icon'
import * as s from './ResetAllButton.module.scss'

export const ResetAllButton = ({ handleReset, isVisible, variant }) => {
  return (
    <button
      type="button"
      className={cn(s.button, {
        [s.visible]: isVisible,
        [s[variant]]: variant,
      })}
      onClick={handleReset}
    >
      <Icon name="cross" size={24} />
      Reset All
    </button>
  )
}
