import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Nav from 'react-bootstrap/Nav'

import * as s from './TabNav.module.scss'

const TabNav = (props) => {
  const { className, menuItems, ...rest } = props

  return (
    <Nav {...rest} as="ul" className={cn(s.tabNav, className)}>
      {menuItems.map(({ name }) => (
        <Nav.Item as="li" key={name} className={s.navListItem}>
          <Nav.Link as="button" eventKey={name} className={s.navLink}>
            {name}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  )
}

TabNav.defaultProps = {
  className: undefined,
}

TabNav.propTypes = {
  className: PropTypes.string,
}

export default TabNav
