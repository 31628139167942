import React, { useState } from 'react'
import cn from 'classnames'

import { CalendarLayout } from '../CalendarLayout'
import { Cell } from '../Cell'
import { PHASES, TYPES } from '../constants'
import { getCalendarData } from './utils'
import { row, withTooltip, title } from './QuarterCalendar.module.scss'

export const QuarterCalendar = ({
  minDate,
  maxDate,
  range,
  setRange,
  isOpen,
  tooltips,
}) => {
  const [phase, setPhase] = useState(PHASES.FROM)

  const calendarData = getCalendarData(minDate, maxDate)

  const handleOption = (date) => {
    const { startDate } = range

    if (phase === PHASES.FROM) {
      setRange({ startDate: date, endDate: null })
      setPhase(PHASES.TO)
    } else if (phase === PHASES.TO) {
      const isNewQuarterEarlier = date < startDate

      const newRange = {
        startDate: isNewQuarterEarlier ? date : startDate,
        endDate: isNewQuarterEarlier ? startDate : date,
      }

      setRange(newRange)
      setPhase(PHASES.FROM)
    }
  }

  return (
    <CalendarLayout isOpen={isOpen}>
      {calendarData.map(({ year, quarters }, index) => (
        <div className={cn(row, { [withTooltip]: tooltips })} key={year}>
          <div className={title}>{year}</div>
          {quarters.map((date) => (
            <Cell
              {...{ date, range, phase, minDate, maxDate }}
              key={date}
              type={TYPES.QUARTER}
              variant="quarter"
              onSelect={() => handleOption(date)}
              isFirstYear={!index}
              isLastYear={index === calendarData.length - 1}
              tooltip={tooltips?.find(
                (tooltip) =>
                  tooltip?.date.toFormat('q yyyy') === date.toFormat('q yyyy')
              )}
              dateFormat="Qq"
            />
          ))}
        </div>
      ))}
    </CalendarLayout>
  )
}
