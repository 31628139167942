import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import Highcharts from 'highcharts'
import { DateTime } from 'luxon'

import { renderChartTooltipToString } from '~globalComponents/ChartTooltipContent'
import { formatDateTooltipForAggregation } from '~utils/chart'
import {
  formatNum,
  displayPercent,
  getTickInterval,
  formatDateByPeriod,
} from '~utils'
import { PERIOD_FORMAT } from '~constants'
import * as palette from '~styles/palette.scss'

import { NumberLabel } from '../../NumberLabel'
import { LINE_CHART_LINE_COLORS } from '../constants'
import { ChartLineOptions, FormatChartLineData } from './types'

export const getColorFromPredefinedList = (
  index: number,
  colorArray: string[]
) => colorArray[index % colorArray.length]

export const getChartLineOptions = ({
  sortedChartData,
  showPercentSymbolSuffix,
  unit,
  decimals,
  shortDashLineSeriesNames,
  aggregation,
  hasTrailingZeros,
}: ChartLineOptions): Highcharts.Options => {
  const chartDecimals = decimals || (showPercentSymbolSuffix ? 2 : 1)

  return {
    yAxis: {
      labels: {
        formatter() {
          return renderToStaticMarkup(
            <NumberLabel
              value={this.value as number}
              unit={showPercentSymbolSuffix ? '%' : unit}
              decimals={chartDecimals}
              hasTrailingZeros={hasTrailingZeros}
            />
          )
        },
      },
    },
    xAxis: [
      {
        lineColor: palette.gray200,
        tickColor: palette.gray200,
        tickLength: 8,
        minTickInterval: getTickInterval(aggregation),
        type: 'datetime',
        labels: {
          distance: 8,
          style: {
            color: palette.gray500,
            fontSize: '11px',
          },
          formatter() {
            return formatDateByPeriod({
              date: this.value,
              period: aggregation,
              isTimestamp: true,
              prefix: false,
              periodFormat: PERIOD_FORMAT,
            })
          },
        },
      },
    ],
    series: sortedChartData.map(
      ({ name: seriesName, data: seriesData }, index) => ({
        name: seriesName,
        type: 'spline',
        lineWidth: 2,
        color: getColorFromPredefinedList(index, LINE_CHART_LINE_COLORS),
        dashStyle:
          // seriesName.includes('Benchmark') ||
          shortDashLineSeriesNames?.includes(seriesName)
            ? 'ShortDash'
            : 'Solid',
        zIndex: sortedChartData.length - index,
        data: seriesData,
      })
    ),
    tooltip: {
      formatter() {
        const date =
          typeof this.x === 'number' ? DateTime.fromMillis(this.x) : undefined

        // Sort points by last series value
        // (Always sorted by the values of the most recent period)
        // @ts-ignore
        const sortedPoints = [...this.points].sort(
          ({ series: { yData: a } }, { series: { yData: b } }) =>
            b[b.length - 1] - a[a.length - 1]
        )

        const data = sortedPoints.map(
          ({ series: { name, color, tooltipOptions }, y }) => {
            const isPercent = showPercentSymbolSuffix || unit === '%'
            const prefix =
              tooltipOptions?.valuePrefix || (!isPercent && unit) || ''
            const value = isPercent
              ? displayPercent(y, chartDecimals, false, '%', hasTrailingZeros)
              : formatNum(y, false, chartDecimals, hasTrailingZeros).full

            return {
              title: name,
              values: [
                {
                  value: `${prefix}${value}`,
                  color,
                },
              ],
            }
          }
        )

        const title =
          aggregation && date
            ? formatDateTooltipForAggregation(aggregation, date)
            : date?.toFormat('LLLL yyyy')

        return renderChartTooltipToString({ title }, data)
      },
    },
  }
}

export const formatChartLineData = ({
  data,
  showPercentSymbolSuffix = true,
}: FormatChartLineData) => {
  if (!data) return { chartData: undefined, isChartEmpty: false }

  const { cols, data: dataPoints } = data

  if (!dataPoints?.length) return { chartData: undefined, isChartEmpty: true }

  const preparedData = cols.map((col: any, i: any) => ({
    name: col,
    data: dataPoints.map(({ date, values }: any) => [
      DateTime.fromISO(date).toMillis(),
      values[i],
    ]),
  }))

  return {
    chartData: preparedData,
    isChartEmpty: false,
    showPercentSymbolSuffix,
  }
}
