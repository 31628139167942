import React from 'react'
import cn from 'classnames'

import * as s from './CalendarLayout.module.scss'

export const CalendarLayout = ({ variant, isOpen, children }) => (
  <div className={cn(s.calendar, { [s.open]: isOpen, [s[variant]]: variant })}>
    {children}
  </div>
)
