import { uniqWith, isEqual } from 'lodash-es'

import { getMetricTypeProps, getMetricTypesField } from '../../utils'
import { TBChartScoreType } from '../../enums'
import formatSingleDataChart from './formatSingleDataChart'
import formatMixedDataChart from './formatMixedDataChart'

const areNumbersNonNegative = (arr: number[]) => arr.every((el) => el >= 0)

const formatCustomerExperienceData = ({
  data,
  brand,
  score,
  isPIT,
  topicName,
  dashboardOptions,
}: any) => {
  const { table } = data

  if (!table?.length) return { chartData: undefined }

  const isAverageScore = score === TBChartScoreType.AVERAGE_SCORE
  const metricTypesField = getMetricTypesField(topicName)
  const metricTypes = dashboardOptions?.[metricTypesField]

  const types = table.map((metric: any) => {
    const { isNPS, isScore } = getMetricTypeProps({
      name: metric?.[0],
      metricTypes,
      isScoreMode: isPIT && isAverageScore,
    })

    return { isNPS, isScore, isPercent: !isNPS && !isScore }
  })

  const isMixedType = uniqWith(types, isEqual).length !== 1

  const formatFn = isMixedType ? formatMixedDataChart : formatSingleDataChart
  const chartData = formatFn({ table, brand, types })

  const nonNegative = areNumbersNonNegative(
    table.map((row: any) => [row[1], row[2]]).flat()
  )

  return {
    chartData,
    type: isMixedType && 'mixed',
    isNotPercentValue: !isMixedType ? !types[0].isPercent : null,
    nonNegative,
  }
}

export default formatCustomerExperienceData
