import * as palette from '~styles/palette.scss'

export const SELECT_STYLES = {
  menu: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuColor,
    zIndex: 3,
    marginTop: '1px',
    width: 'calc(100% + 24px)',
    left: '50%',
    transform: 'translateX(-50%)',
    boxShadow:
      '0px 2px 5px rgba(0, 0, 0, 0.12), 0px 6px 24px rgba(175, 181, 186, 0.15)',
    borderRadius: '4px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    borderWidth: 0,
    borderBottomWidth: '1px',
    borderRadius: 0,
    boxShadow: 'none',
    // eslint-disable-next-line no-nested-ternary
    borderColor: state?.selectProps?.hasError
      ? palette.orange60
      : state.menuIsOpen
      ? palette.green60
      : palette.gray40,
    backgroundColor: 'none',
    color: state.isDisabled ? palette.gray40 : palette.gray80,
    cursor: 'pointer',
    '&:hover': {
      borderColor: state?.selectProps?.hasError
        ? palette.orange60
        : palette.green60,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    textOverflow: 'ellipsis',
    maxWidth: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: palette.gray100,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    margin: 0,
    color: state.isDisabled ? palette.gray40 : palette.gray80,
  }),
  indicatorSeparator: () => ({}),
}

export const SELECT_STYLES_ABSOLUTE = {
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 1100,
  }),
}
