import React, {
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useResizeDetector } from 'react-resize-detector'
import { debounce } from 'lodash-es'

import * as s from './GradientBoundaries.module.scss'

export const GradientBoundaries = ({
  children,
  className,
  scrollContainerRef: scrollContainerRefProp,
  offset,
  showTopBoundary = true,
}) => {
  const [topBoundaryVisibility, setTopBoundaryVisibility] = useState(false)
  const [bottomBoundaryVisibility, setBottomBoundaryVisibility] =
    useState(false)

  const scrollContainerRefLocal = useRef()

  const scrollContainerRef = scrollContainerRefProp || scrollContainerRefLocal

  const { height } = useResizeDetector({
    targetRef: scrollContainerRef,
    handleWidth: false,
  })

  const handleScroll = useCallback(
    (e) => {
      const scrollContainerEl = e.target

      if (!scrollContainerEl) return

      const { offsetHeight, scrollHeight, scrollTop } = scrollContainerEl

      const scrollBottom = -(scrollTop - scrollHeight + offsetHeight)

      setTopBoundaryVisibility(scrollTop > offset && showTopBoundary)
      setBottomBoundaryVisibility(scrollBottom > offset)
    },
    [offset]
  )

  const throttledHandleScroll = debounce(handleScroll, 20)

  useEffect(() => {
    const scrollContainerEl = scrollContainerRef?.current

    if (!scrollContainerEl) return

    scrollContainerEl?.addEventListener('scroll', throttledHandleScroll, {
      passive: true,
    })

    // eslint-disable-next-line consistent-return
    return () =>
      scrollContainerEl?.removeEventListener('scroll', throttledHandleScroll)
  }, [scrollContainerRef?.current, throttledHandleScroll])

  useEffect(() => {
    handleScroll({ target: scrollContainerRef?.current })
  }, [scrollContainerRef?.current, height])

  return (
    <div
      className={cn(
        s.scrollableList,
        {
          [s.visibleTop]: topBoundaryVisibility,
          [s.visibleBottom]: bottomBoundaryVisibility,
        },
        className
      )}
      onScroll={handleScroll}
    >
      {scrollContainerRefProp
        ? children
        : cloneElement(React.Children.only(children), {
            ref: scrollContainerRefLocal,
          })}
    </div>
  )
}

GradientBoundaries.defaultProps = {
  offset: 5,
  scrollContainerRef: undefined,
}

GradientBoundaries.propTypes = {
  scrollContainerRef: PropTypes.any,
  offset: PropTypes.number,
  className: PropTypes.string,
}
