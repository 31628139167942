import React from 'react'

import Icon from '~globalComponents/Icon'

import * as s from './Option.module.scss'

export const Option = ({ innerProps, isSelected, children }) => (
  <div {...innerProps} className={s.option}>
    {children}

    {isSelected && (
      <span className={s.optionCheck}>
        <Icon name="icon-check_medium" />
      </span>
    )}
  </div>
)
