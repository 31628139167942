import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { NO_DATA_MESSAGE, SERVER_ERROR } from './constants'

import * as s from './NoDataPlaceholder.module.scss'

export const NoDataPlaceholder = ({
  message,
  isError,
  hasMargin = false,
  variant,
}) => {
  const defaultContent = isError ? SERVER_ERROR : NO_DATA_MESSAGE
  return (
    <div
      className={cn(s.wrap, {
        [s.error]: isError,
        [s.margin]: hasMargin,
        [s[variant]]: variant,
      })}
    >
      {message || defaultContent}
    </div>
  )
}

NoDataPlaceholder.propTypes = {
  message: PropTypes.string,
  isError: PropTypes.bool,
  hasMargin: PropTypes.bool,
  variant: PropTypes.string,
}
