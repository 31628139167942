import { ROUTES } from '~routes'
import { MENU } from '~globalComponents/Header/constants'

const getFirstDashboard = (menu) => {
  if (Array.isArray(menu) && !menu[0]?.dashboard) {
    return getFirstDashboard(menu[0].children)
  }
  return menu?.[0]
}

export const getFirstDashboardPath = (menu) => {
  const { dashboard, name } = getFirstDashboard(menu)
  return {
    name,
    link: `${ROUTES.GENERIC_DASHBOARD}?id=${dashboard}&name=${name}`,
  }
}

export const searchRecursive = (data, id) => {
  let found = data.find((d) => d?.dashboard?.toString() === id?.toString())
  if (!found) {
    let i = 0
    while (!found && i < data.length) {
      if (data[i].children && data[i].children.length) {
        found = searchRecursive(data[i].children, id)
      }
      i += 1
    }
  }
  return found
}

export const searchMenuByDashboard = (data, id) =>
  data.find((tab) => {
    if (tab.children) {
      return searchMenuByDashboard(tab.children, id)
    }
    return tab?.dashboard?.toString() === id?.toString()
  })

export const getRedirectMenuItem = (modules, children) => {
  const firstDashboard = modules?.find(({ active }) => !!active)
  if (firstDashboard) {
    const firstDashboardPath = MENU[0].list.find(
      ({ name }) => name === firstDashboard.name
    )
    return firstDashboardPath
  }

  if (children) {
    return getFirstDashboardPath(children)
  }

  return null
}

export const getDashboardRedirectPath = ({
  modules,
  children,
  isHomeAvailable,
}) => {
  if (isHomeAvailable) {
    const routeToLogin = sessionStorage.getItem('routeToLogin')
    return routeToLogin || ROUTES.HOME
  }
  return getRedirectMenuItem(modules, children).link
}
