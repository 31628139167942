import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import * as s from './HamburgerButton.module.scss'

const HamburgerButton = (props) => {
  const { className, isMenuOpened, hamb, handleMenu, ...rest } = props

  return (
    <button
      {...rest}
      type="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded={isMenuOpened}
      ref={hamb}
      onClick={handleMenu}
      className={cn(s.hamburgerButton, className)}
    >
      <svg className={s.hamburgerButtonIcon} viewBox="0 0 100 100">
        <path
          className={cn(s.hamburgerButtonLine, s.hamburgerButtonLineTop)}
          d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
        />
        <path
          className={cn(s.hamburgerButtonLine, s.hamburgerButtonLineMiddle)}
          d="m 30,50 h 40"
        />
        <path
          className={cn(s.hamburgerButtonLine, s.hamburgerButtonLineBottom)}
          d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
        />
      </svg>
    </button>
  )
}

HamburgerButton.defaultProps = {
  className: undefined,
}

HamburgerButton.propTypes = {
  className: PropTypes.string,
}

export default HamburgerButton
