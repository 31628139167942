import React from 'react'
import MiddleEllipsis from 'react-middle-ellipsis'
import cn from 'classnames'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { Spinner } from 'react-bootstrap'

import Icon from '~globalComponents/Icon'
import { QUERY_KEYS } from '~api'
import { CACHE_TIME, EXPORT_STATUS } from '~constants'
import { downloadReport, hasExtension, getFallbackName } from './utils'
import * as s from './Report.module.scss'

const { DOWNLOADED, FAILED, READY, PROCESSING } = EXPORT_STATUS

const Report = ({
  id,
  format,
  name,
  subscription,
  status,
  created,
  setTooltipData,
}) => {
  const isFailed = status === FAILED
  const isProcessing = status === PROCESSING
  const iconName = isFailed ? `${format}-error` : format

  const fileName = hasExtension(name) ? name : getFallbackName(name, format)

  const queryClient = useQueryClient()
  const queryKey = [QUERY_KEYS.REPORTS]

  const { mutate: refetchDownload, isLoading } = useMutation(
    [QUERY_KEYS.REPORT, { id }],
    () => downloadReport({ fileName, queryId: id }),
    {
      onSuccess: () => {
        // update report status
        if (status !== DOWNLOADED) {
          const reportsData = queryClient.getQueryData(queryKey)
          const updatedReports = reportsData?.reports?.map((report) =>
            report?.id === id ? { ...report, status: DOWNLOADED } : report
          )

          queryClient.setQueryData(queryKey, {
            ...reportsData,
            reports: updatedReports,
          })
        }
      },
      cacheTime: CACHE_TIME,
    }
  )

  const handleDownload = () => {
    refetchDownload()
  }

  const dateObj = DateTime.fromSeconds(created)

  // less than 1 minute ago
  const isJustNow = dateObj.diffNow('minutes').minutes > -1

  const timeAgo = isJustNow ? 'just now' : dateObj.toRelative()

  return (
    <button
      type="button"
      disabled={isFailed}
      className={cn(s.report, { [s.processing]: isProcessing })}
      onClick={handleDownload}
    >
      <span
        className={cn(s.icon, {
          [s.newExport]: status === READY,
          [s[format]]: !isFailed,
          [s.failed]: isFailed,
        })}
      >
        {isLoading || isProcessing ? (
          <Spinner animation="border" role="status" className="spinner-icon">
            <Icon
              className={cn(s.spinner, s[format])}
              name="file-download-spinner"
            />
          </Spinner>
        ) : (
          <Icon name={iconName} />
        )}
      </span>

      <div className={s.info}>
        <MiddleEllipsis>
          <span className={cn(s.title, { [s.processing]: isProcessing })}>
            {!isProcessing ? fileName : 'Loading'}
          </span>
        </MiddleEllipsis>
        {!isFailed ? (
          <span className={cn(s.subText, s.time)}>{timeAgo}</span>
        ) : (
          <span className={cn(s.subText, s.error)}>
            Failed to generate report
          </span>
        )}
      </div>

      {subscription && (
        <Icon
          name="repeat"
          className={s.subscriptionIcon}
          onMouseEnter={() =>
            setTooltipData && setTooltipData({ hasTooltip: true })
          }
          onMouseLeave={() =>
            setTooltipData && setTooltipData({ hasTooltip: false })
          }
        />
      )}
    </button>
  )
}

export default Report
