import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import Icon from '~globalComponents/Icon'
import Tooltip from '~globalComponents/Tooltip'
import Report from '../Report'
import { TOOLTIP } from './constants'
import * as s from './ExportsPopUp.module.scss'

const ExportsPopUp = (props) => {
  const { reports, show, className, ...rest } = props

  const [tooltipData, setTooltipData] = useState({ hasTooltip: false })

  return (
    <div {...rest} className={cn(s.popUp, { [s.show]: show }, className)}>
      <Tooltip
        variant="exports"
        followMouse
        placement="followMouseBottom"
        withIcon={false}
        content={TOOLTIP}
        {...tooltipData}
      >
        <div className={cn(s.rowWrapper, { [s.empty]: !reports?.length })}>
          {reports?.length ? (
            reports?.map((report) => (
              <Report
                key={report.id}
                setTooltipData={setTooltipData}
                {...report}
              />
            ))
          ) : (
            <div className={s.noExport}>
              <Icon name="icon-file-reject" />
              <span>No exports in last 48 hours</span>
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  )
}

ExportsPopUp.defaultProps = {
  show: false,
  className: '',
}

ExportsPopUp.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
}

export default ExportsPopUp
