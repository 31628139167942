import * as palette from '~styles/palette.scss'

export const SELECT_STYLES = {
  menu: (provided) => ({
    ...provided,
    color: palette.gray70,
    marginTop: '0',
    backgroundColor: palette.white,
    boxShadow: 'none',
    zIndex: 2,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    borderTop: `1px solid ${palette.gray20}`,
    borderRadius: '0 0 4px 4px',
    boxShadow: '0 6px 24px rgba(206, 212, 218, 30%)',
  }),
  control: (provided, state) => ({
    ...provided,
    display: 'grid',
    columnGap: '2px',
    gridAutoFlow: 'column',
    borderWidth: 0,
    borderRadius: '4px 4px 0 0',
    boxShadow: state.menuIsOpen
      ? '0 6px 24px rgba(206, 212, 218, 30%)'
      : 'none',
    backgroundColor: palette.white,
    color: state.menuIsOpen ? palette.gray90 : palette.gray40,
    cursor: 'pointer',
    transition: 'background-color 300ms',
    padding: '4px 12px 4px 12px',
    textDecorationThickness: '1.5px',

    '&:hover': {
      color: palette.gray90,

      'div:first-of-type div': {
        boxShadow: state.menuIsOpen
          ? 'inset 0 -1.5px 0 transparent'
          : `inset 0 -1.5px 0 ${palette.gray90}`,
      },
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    position: 'relative',
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: palette.gray70,
    fontWeight: 500,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    margin: 0,
    color: state.isDisabled ? palette.gray40 : palette.gray80,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '1.43',
    textTransform: 'uppercase',
    boxShadow: 'inset 0 -1.5px 0 transparent',
    transition: 'box-shadow 150ms',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
}
