/* eslint-disable no-nested-ternary */
import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import { ROUTES } from '~routes'

import * as s from './NavLink.module.scss'

const DisabledLink = ({ name, handleDisabledLink }) => (
  <button
    type="button"
    onClick={handleDisabledLink}
    className={cn(s.navLink, s.isDisabled)}
  >
    {name}
  </button>
)

export const NavLink = ({
  link,
  name,
  display,
  dashboard,
  active,
  nested,
  pathname,
  activeDashboardId,
  handleDisabledLink,
}) => {
  const label = display || name

  return link || dashboard ? (
    active ? (
      link === pathname ? (
        <span
          className={cn(s.navLink, s.active, {
            [s.nested]: nested,
          })}
        >
          {label}
        </span>
      ) : (
        <Link
          className={cn(s.navLink, {
            [s.active]: String(dashboard) === activeDashboardId,
            [s.nested]: nested,
          })}
          to={
            dashboard
              ? `${ROUTES.GENERIC_DASHBOARD}?id=${dashboard}&name=${name}`
              : link
          }
        >
          {label}
        </Link>
      )
    ) : (
      <DisabledLink name={label} handleDisabledLink={handleDisabledLink} />
    )
  ) : (
    <button
      type="button"
      className={cn(s.navLink, {
        [s.nested]: nested,
      })}
      disabled
    >
      {label}
    </button>
  )
}
