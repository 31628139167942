import { formatChartLineData as formatDataFn } from '~dashboardComponents/charts'
import { getMetricTypeProps, getMetricTypesField } from '../../utils'
import { TBTopicType, TBChartScoreType } from '../../enums'

export const formatChartLineData = ({
  data,
  topicName,
  metricName,
  score,
  dashboardOptions,
  isPIT,
}: any) => {
  switch (topicName) {
    case TBTopicType.BRAND_PERCEPTION: {
      const props = formatDataFn({ data, showPercentSymbolSuffix: true })
      return { ...props, period: 'Quarter' }
    }

    case TBTopicType.CUSTOMER_EXPERIENCE_RESTAURANT:
    case TBTopicType.CUSTOMER_EXPERIENCE_RETAIL: {
      const isAverageScore = score === TBChartScoreType.AVERAGE_SCORE
      const metricTypesField = getMetricTypesField(topicName)

      const { isNPS, isScore } = getMetricTypeProps({
        name: metricName,
        metricTypes: dashboardOptions?.[metricTypesField],
        isScoreMode: isPIT && isAverageScore,
      })

      const percent = !isNPS && !isScore
      const props = formatDataFn({
        data,
        showPercentSymbolSuffix: false,
      })

      return {
        ...props,
        period: 'Quarter',
        unit: percent ? '%' : '',
        showPercentSymbolSuffix: percent,
        isNumber: !percent,
      }
    }

    default: {
      return formatDataFn({ data, showPercentSymbolSuffix: false })
    }
  }
}
