import React from 'react'
import cn from 'classnames'

import * as s from './Loader.module.scss'

export const Loader = ({ variant = '', color = '' }) => {
  return (
    <div
      className={cn('loader', s.loader, {
        [s[variant]]: variant,
        [s[color]]: color,
      })}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 80 80">
        <g fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path
            fill="url(#b)"
            d="M40 56.5c-9.113 0-16.5-7.387-16.5-16.5 0-1.998.355-3.914 1.006-5.686L2.45 26.219a40 40 0 0 0 47.906 52.417l-6.082-22.694A16.522 16.522 0 0 1 40 56.5Z"
          />
          <path
            fill="url(#c)"
            d="M40 56.5c-9.113 0-16.5-7.387-16.5-16.5 0-1.998.355-3.914 1.006-5.686L2.45 26.219a40 40 0 0 0 47.906 52.417l-6.082-22.694A16.522 16.522 0 0 1 40 56.5Z"
          />
          <path
            fill="url(#d)"
            d="m55.948 44.249 22.704 6.048a40 40 0 0 1-28.316 28.345l-6.071-22.698a16.53 16.53 0 0 0 11.683-11.695Z"
          />
          <path
            fill="currentColor"
            d="M4.359 19C1.069 24.697-.763 32.117-.979 38.691a40 40 0 0 0 4.104 19c2.91 5.9 7.229 11.991 12.576 15.823 5.347 3.831 12.556 6.285 19.078 7.144a40 40 0 0 0 19.311-2.222c6.156-2.317 12.646-6.117 16.984-11.062a40 40 0 0 0 8.881-17.29C81.45 43.676 81.34 35 79.637 28.646l-4.864 2.036A36 36 0 1 1 8.823 22l-4.464-3Zm7.928 5a32 32 0 1 0 58.623 7.718l-3.678.985a28.193 28.193 0 1 1-51.647-6.8L12.287 24Zm3.726 15.215A24 24 0 0 1 19.215 28l3.258 1.88a20.237 20.237 0 1 0 37.075 4.882l3.634-.974a23.999 23.999 0 1 1-47.17 5.427Z"
          />
        </g>
        <defs>
          <linearGradient
            id="b"
            x1="27.5"
            x2="44.126"
            y1="49"
            y2="51.846"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#41C499" stopOpacity=".64" />
            <stop offset="1" stopColor="#41C499" stopOpacity=".44" />
          </linearGradient>
          <linearGradient
            id="c"
            x1="15.861"
            x2="30.055"
            y1="23.5"
            y2="52.168"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#41C499" />
            <stop offset="1" stopColor="#41C499" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="d"
            x1="48.5"
            x2="56.788"
            y1="58.5"
            y2="49.14"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#41C499" stopOpacity=".44" />
            <stop offset="1" stopColor="#41C499" stopOpacity="0" />
          </linearGradient>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h80v80H0z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
