/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useContext } from 'react'

import { TBCreateCardModalMode } from './enums'

const OverlayManagerContext = createContext<TBOverlayManager>({
  // Create card modal
  createCardModalMode: TBCreateCardModalMode.CREATION,
  createCardModalPosition: -1,
  isCreateCardModalVisible: false,
  showInitialCreateCardModal: () => {},
  showCreateCardModalAtPosition: (pos, mode) => {},
  hideCreateCardModal: () => {},

  // Delete block modal
  isDeleteBlockModalVisible: false,
  deleteBlockModalOptions: null,
  showDeleteBlockModalWithOptions: (options) => {},
  hideDeleteBlockModal: () => {},

  // DeleteOnSave modal
  isDeleteOnSaveModalVisible: false,
  deleteOnSaveModalOptions: null,
  showDeleteOnSaveModal: (options) => {},
  hideDeleteOnSaveModal: () => {},

  // Subscribe offcanvas
  isSubscribeOffcanvasVisible: false,
  showSubscribeOffcanvas: () => {},
  hideSubscribeOffcanvas: () => {},
})

interface OverlayManagerProviderProps extends React.PropsWithChildren {
  overlayManager: TBOverlayManager
}

export const OverlayManagerProvider: React.FC<OverlayManagerProviderProps> = ({
  children,
  overlayManager,
}) => {
  return (
    <OverlayManagerContext.Provider value={overlayManager}>
      {children}
    </OverlayManagerContext.Provider>
  )
}

export const useOverlayManagerContext = () => {
  return useContext(OverlayManagerContext)
}
