import React from 'react'
import { Ratio } from 'react-bootstrap'

import { ModalWrapper } from '~globalComponents/ModalWrapper'
import { VideoPlayer } from '~globalComponents/VideoPlayer'
import { video } from './ModalVideo.module.scss'

export const ModalVideo = ({ isOpen, handleClose, settings }) => {
  const { videoUrl, aspectRatio = [16, 9] } = settings || {}
  const [width, height] = aspectRatio || []

  return (
    <ModalWrapper isOpen={isOpen} handleClose={handleClose}>
      <Ratio aspectRatio={height / width} className={video}>
        <VideoPlayer
          url={videoUrl}
          config={{
            vimeo: {
              playerOptions: {
                autoplay: true,
              },
            },
            title: 'tutorial',
          }}
        />
      </Ratio>
    </ModalWrapper>
  )
}
