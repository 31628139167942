import React, { PropsWithChildren } from 'react'
import { Button } from 'react-bootstrap'
import cn from 'classnames'

import SenseModal from '~globalComponents/SenseModal'
import Icon from '~globalComponents/Icon'
import { CANCEL_BTN_TEXT, DELETE_BTN_TEXT } from './constants'
import * as s from './ModalDelete.module.scss'

interface ModalDeleteProps extends PropsWithChildren {
  show: boolean
  handleClose: (v: boolean) => void
  handleDelete: () => void
  cancelBtnText?: string
  deleteBtnText?: string
  isDeleting?: boolean
  variant?: string
}

const ModalDelete: React.FC<ModalDeleteProps> = (props) => {
  const {
    show,
    handleClose,
    handleDelete,
    children,
    cancelBtnText = CANCEL_BTN_TEXT,
    deleteBtnText = DELETE_BTN_TEXT,
    isDeleting,
    variant = '',
    ...rest
  } = props

  return (
    <SenseModal
      {...rest}
      size="xs"
      variant="deletePrompt"
      show={show}
      handleClose={handleClose}
    >
      <span className={s.iconWrapper}>
        <Icon name="cross" size={18} />
      </span>

      {children}

      <div className={cn(s.gridButtons, { [s[variant]]: variant })}>
        <Button
          variant="outline-gray btn-center"
          onClick={() => handleClose(false)}
        >
          {cancelBtnText}
        </Button>
        <Button
          variant="orange60 btn-center"
          className={cn({ loading: isDeleting })}
          onClick={!isDeleting ? handleDelete : undefined}
        >
          {deleteBtnText}
        </Button>
      </div>
    </SenseModal>
  )
}

export default ModalDelete
