import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

import { QUERY_KEYS } from '~api'

export const useDashboards = (brand?: string, onSuccess?: () => void) => {
  const { data, isSuccess, ...rest } = useQuery<any>(
    [QUERY_KEYS.DASHBOARDS, brand],
    {
      enabled: !!brand,
      refetchOnMount: 'always',
      cacheTime: 0,
    }
  )

  // TODO: try to avoid using useEffect
  useEffect(() => {
    if (isSuccess && !!onSuccess) {
      onSuccess()
    }
  }, [isSuccess, data, brand])

  return { data, isSuccess, ...rest }
}
