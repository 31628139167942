import React, { useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useNavigate, useLocation } from 'react-router-dom'
import qs from 'query-string'
import { omit } from 'lodash-es'
import { useAccount } from '~hooks'
import api, { QUERY_KEYS } from '~api'
import { ROUTES } from '~routes'
import { Option } from './Option'
import * as s from './style.module.scss'

const accountQueryFn = () => {
  return api.get(QUERY_KEYS.ACCOUNT).then((res) => res.data)
}

const selectBrandQueryFn = ({ queryKey }) => {
  const [, { params }] = queryKey
  return api
    .get(QUERY_KEYS.SELECT_BRAND, { params })
    .then((res) => res.data?.token)
}

export const OptionList = React.forwardRef(
  (
    {
      options,
      handleSuccessQuery,
      omittedParams,
      mergeParams,
      isVisible,
      ...rest
    },
    ref
  ) => {
    const { data: accountInfo } = useAccount()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const { search, pathname } = useLocation()

    const [brand, setBrand] = useState(null)

    const { refetch: refetchAccount } = useQuery(
      ['account-on-brand-selecting'],
      accountQueryFn,
      {
        enabled: false,
        onSuccess: (res) => {
          if (pathname === '/' || pathname.includes(ROUTES.GENERIC_DASHBOARD)) {
            return
          }

          // use default_brand on brand changing
          const parsedSearch = qs.parse(search)
          const mergedURLParams = Object.assign(parsedSearch, mergeParams)
          const queryStartParams = omittedParams
            ? omit(mergedURLParams, omittedParams)
            : mergedURLParams
          switch (true) {
            case pathname.includes(ROUTES.BRAND_PERCEPTION):
              queryStartParams.brand = res.brand_perceptions?.default_brand
              break
            case pathname.includes(ROUTES.CUSTOMER_EXPERIENCE): {
              /*  
              Customer Experience has two types of brands - restaurant and retail.
              Each brand has its specific fields: 
                for restaurant - daypart and order; 
                for retail - channel and buyer_status.
              The request will fail if we miss these props, or add extra. 
              So we remove unused props and add the necessary ones 
            */
              const {
                default_brand,
                purchase_channels,
                buyer_status,
                order_methods,
                dayparts,
              } = res.customer_experience
              queryStartParams.brand = default_brand
              const isRetailer = !!purchase_channels
              if (isRetailer) {
                queryStartParams.channel =
                  parsedSearch.channel ?? purchase_channels?.[0]
                queryStartParams.buyer_status =
                  parsedSearch.channel ?? buyer_status?.[0]
              } else {
                queryStartParams.order =
                  parsedSearch.order ?? order_methods?.[0]
                queryStartParams.daypart = parsedSearch.daypart ?? dayparts?.[0]
              }
              break
            }
            default:
              queryStartParams.brand = res.default_brand
              break
          }
          const queryURLParams = qs.stringify(queryStartParams)
          navigate(`${pathname}?${queryURLParams}`)
        },
        cacheTime: 0,
      }
    )

    useQuery(
      [QUERY_KEYS.SELECT_BRAND, { params: { brand } }],
      selectBrandQueryFn,
      {
        enabled: !!brand,
        onSuccess: (token) => {
          if (token) {
            handleSuccessQuery()
            localStorage.setItem('token', token)
            refetchAccount().then(() =>
              queryClient.invalidateQueries([QUERY_KEYS.ACCOUNT])
            )
          }
        },
        onError: (e) => {
          alert(e.response?.data?.error)
          if (e.response?.data?.error?.includes('Missing credentials')) {
            navigate(ROUTES.LOGIN)
          }
        },
        cacheTime: 0,
      }
    )

    const handleOption = (b) => () => setBrand(b)

    return (
      <div {...rest} className={s.body} ref={ref}>
        {options.map((option) => (
          <Option
            brand={option}
            isActive={option === accountInfo?.brand}
            key={option}
            handleOption={handleOption}
          />
        ))}
      </div>
    )
  }
)
