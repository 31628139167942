/* eslint-disable */
// <![CDATA[
;(function (d, e, c, i, b, el, it) {
  d._da_ = d._da_ || []
  _da_.oldErr = d.onerror
  _da_.err = []

  d.onerror = function () {
    _da_.err.push(arguments)
    _da_.oldErr && _da_.oldErr.apply(d, Array.prototype.slice.call(arguments))
  }

  d.DecibelInsight = b
  d[b] =
    d[b] ||
    function () {
      ;(d[b].q = d[b].q || []).push(arguments)
    }

  // eslint-disable-next-line no-unused-expressions
  ;(el = e.createElement(c)), (it = e.getElementsByTagName(c)[0])
  el.async = 1
  el.src = i
  it.parentNode.insertBefore(el, it)
})(
  window,
  document,
  'script',
  'https://cdn.decibelinsight.net/i/13798/524725/di.js',
  'decibelInsight'
)
// ]]>
