import React, { useState, useRef } from 'react'
import { useController } from 'react-hook-form'
import cn from 'classnames'

import { useClickOutside } from '~hooks'
import * as s from './style.module.scss'

export const DatepickerWrap = ({
  name,
  control,
  rules,
  defaultDate,
  formatValue,
  clearErrors,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const inputRef = useRef()

  const {
    field: { ref, onChange, value },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    rules,
    defaultValue: { startDate: defaultDate, endDate: defaultDate },
  })

  useClickOutside([inputRef], setIsOpen)

  const toggleCalendar = () => setIsOpen((prevState) => !prevState)

  const formattedValue = formatValue(value.startDate, value.endDate)

  const handleRange = (range) => {
    onChange(range)

    if (clearErrors) {
      clearErrors()
    }
  }

  return (
    <div className={s.wrap} ref={inputRef}>
      <input
        className={cn('form-control', s.input, {
          [s.error]: invalid,
          [s.focused]: isOpen,
        })}
        readOnly
        onClick={toggleCalendar}
        ref={ref}
        value={formattedValue}
      />

      {children({ isOpen, range: value, setRange: handleRange })}
    </div>
  )
}
