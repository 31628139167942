import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Modal } from 'react-bootstrap'

import * as s from './SenseModal.module.scss'

const SenseModal = (props) => {
  const {
    backdrop,
    title,
    isTitleAccent,
    variant,
    show,
    handleExited,
    handleClose,
    children,
    size,
    restoreFocus = true,
  } = props

  return (
    <Modal
      show={show}
      centered
      size={size}
      onExited={handleExited}
      onHide={handleClose}
      backdrop={backdrop}
      restoreFocus={restoreFocus}
      className={cn({ [`modal-${variant}`]: variant })}
    >
      <Modal.Header closeButton>
        {title && (
          <h2 className={cn(s.title, { [s.isAccent]: isTitleAccent })}>
            {title}
          </h2>
        )}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  )
}

SenseModal.defaultProps = {
  title: '',
  isTitleAccent: false,
  show: false,
  size: 'sm',
  children: '',
  variant: '',
  backdrop: true,
}

SenseModal.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string,
  isTitleAccent: PropTypes.bool,
  show: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  backdrop: PropTypes.oneOf([false, true, 'static']),
  children: PropTypes.node,
  handleClose: PropTypes.func,
}

export default SenseModal
