import React from 'react'
import cn from 'classnames'

import Icon from '../Icon'

export const SearchBar = ({
  id,
  value,
  onChange,
  onClear,
  variant = '',
  hasLabel = true,
  hasAutoFocus = false,
  isMultiline = false,
  isPlaceholderVisible = false,
  placeholder = 'Search brand',
  label = 'Search brand',
  className = '',
}) => {
  return (
    <div
      className={cn(
        'field field--text field--brand',
        {
          active: value,
          [variant]: variant,
        },
        className
      )}
    >
      <div
        className={cn('field_input', 'field_input--search', {
          multiline: isMultiline,
        })}
      >
        {React.createElement(isMultiline ? 'textarea' : 'input', {
          id,
          className: cn('form-control', {
            'placeholder-visible': isPlaceholderVisible,
          }),
          type: 'search',
          name: 'brand',
          autoComplete: 'off',
          placeholder,
          value,
          onChange,
          autoFocus: hasAutoFocus,
        })}
        {hasLabel && (
          <label className="field_label" htmlFor={id}>
            {label}
          </label>
        )}
        <button className="field_reset" type="button" onClick={onClear}>
          <Icon name="cross" size={24} />
        </button>
        {/* <div className="field_error visible">Please select the Brand</div> */}
      </div>
    </div>
  )
}
