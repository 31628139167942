import { isDataDifferencePositive } from '~utils'

export const getBadgeClass = ({ data, benchmark, isInverted, zeroValues }) => {
  if (zeroValues) return `badge-xs badge-gray20`

  const primary = isInverted ? 'secondary' : 'primary'
  const secondary = isInverted ? 'primary' : 'secondary'
  return `badge-xs badge-${
    isDataDifferencePositive(data, benchmark) ? primary : secondary
  }`
}
