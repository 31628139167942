import { TBTopicType } from '../../enums'
import formatBrandPerceptionData from './formatBrandPerceptionData'
import formatCustomerExperienceData from './formatCustomerExperienceData'

export const formatSingleBarChartData = ({
  data,
  topicName,
  metricName,
  benchmark,
  score,
  dashboardOptions,
  isPIT,
}: any) => {
  switch (topicName) {
    case TBTopicType.BRAND_PERCEPTION: {
      return formatBrandPerceptionData({
        data: data.data?.[0],
        benchmark,
        isPIT,
      })
    }

    case TBTopicType.CUSTOMER_EXPERIENCE_RESTAURANT:
    case TBTopicType.CUSTOMER_EXPERIENCE_RETAIL: {
      return formatCustomerExperienceData({
        data,
        topicName,
        metricName,
        score,
        dashboardOptions,
        isPIT,
      })
    }

    default: {
      const { cols, data: dataPoints } = data

      if (!dataPoints?.length) return { chartData: undefined }

      const preparedData = cols.map((col: string, i: number) => {
        const value = dataPoints.at(-1)?.values[i] // use last data array
        return { name: col, value }
      })

      return { chartData: preparedData }
    }
  }
}
