import React from 'react'

import { formatMonthsTitle } from '~utils/datepicker'
import { DatepickerWrap, MonthCalendar } from '../components'

export const MonthDatepicker = ({ name, control, rules, minDate, maxDate }) => (
  <DatepickerWrap
    name={name}
    control={control}
    rules={rules}
    defaultDate={maxDate}
    formatValue={formatMonthsTitle}
  >
    {(props) => (
      <MonthCalendar minDate={minDate} maxDate={maxDate} {...props} />
    )}
  </DatepickerWrap>
)
