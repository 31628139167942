import React from 'react'
import { Controller } from 'react-hook-form'
import ReactSelect from 'react-select'

import Tooltip from '~globalComponents/Tooltip'
import CompetitiveSetTooltip from '~globalComponents/CompetitiveSetTooltip'
import { LABELS } from '~constants'

import { Option } from './Option'
import { SELECT_STYLES, SELECT_STYLES_ABSOLUTE } from './constants'
import { ArrowIcon, SearchIcon } from './Icons'

export const Select = ({
  name,
  control,
  onChange,
  isShort,
  handleEditClick,
  rules,
  isDisabled,
  disabledMessage,
  withSearchIcon,
  selectedValue,
  isCompetitors,
  competitors,
  menuPortalTarget = null,
  ...rest
}) => {
  const getIndicatorsContainer = () => {
    switch (true) {
      case selectedValue === LABELS.COMPETITIVE_SET && isCompetitors:
        return CompetitiveSetTooltip
      case withSearchIcon:
        return SearchIcon
      default:
        return ArrowIcon
    }
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        const props = {
          ...field,
          styles: menuPortalTarget
            ? { ...SELECT_STYLES, ...SELECT_STYLES_ABSOLUTE }
            : SELECT_STYLES,
          isShort,
          competitors,
          handleEditClick,
          isDisabled,
          withSearchIcon,
          onChange: (e) => {
            field.onChange(e)
            if (onChange) onChange(e)
          },
          components: {
            Option,
            IndicatorsContainer: getIndicatorsContainer(),
          },
          ...rest,
        }

        return isDisabled && disabledMessage ? (
          <Tooltip
            variant="search"
            placement="bottomStart"
            content={disabledMessage}
          >
            <ReactSelect {...props} menuPortalTarget={menuPortalTarget} />
          </Tooltip>
        ) : (
          <ReactSelect {...props} menuPortalTarget={menuPortalTarget} />
        )
      }}
    />
  )
}
