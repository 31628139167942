import qs from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'

export const useHandleActiveTab = () => {
  const navigate = useNavigate()
  const { search, pathname } = useLocation()

  const handleActiveTab = (field) => (tab) => {
    const previousParams = qs.parse(search)

    if (previousParams?.[field] === tab) return

    const queryURLParams = qs.stringify({
      ...previousParams,
      [field]: tab,
    })
    navigate(`${pathname}?${queryURLParams}`)
  }

  return handleActiveTab
}
