import { QueryFunctionContext } from '@tanstack/react-query'

/* eslint-disable import/no-cycle */
import api, { QUERY_KEYS } from '~api'

import { TBBlockType, TBChartTrendType } from './enums'
import formatChartData from './formatChartData'

export const customDashboardQueryFn = ({ queryKey }: QueryFunctionContext) => {
  const [endpoint, id, rid] = queryKey

  return api
    .get<TBCustomerDashboardResponse.RootObject>(`${endpoint}/${id}`, {
      params: { data_url: true, rid },
    })
    .then(({ data }) => {
      const {
        report: { name, elements, global_filters, competitive_set },
      } = data

      const { benchmark, brand } = global_filters
      const chartBlocks: TBChartBlock[] = elements
        .map(({ id: elementId, chart, separator }) =>
          // eslint-disable-next-line no-nested-ternary
          chart
            ? ({
                id: elementId,
                blockType: TBBlockType.CHART,
                chartOptions: {
                  metricName: chart.chart,
                  metricType: chart.id,
                  chartType: chart.type,
                  chartFilters: { ...chart.filters },
                  topicName: chart.topic,
                  dataUrl: chart.data_url,
                },
              } as TBChartBlock)
            : separator
            ? ({
                id: elementId,
                blockType: TBBlockType.DIVIDER,
                dividerOptions: { text: separator.text },
              } as TBDividerBlock)
            : null
        )
        .filter((chartBlock) => chartBlock) as TBChartBlock[]

      return {
        settings: {
          dashboardName: name,
          benchmark: { label: benchmark, value: benchmark },
          brand: { label: brand, value: brand },
          competitiveSet: competitive_set,
        },
        blocks: chartBlocks,
      }
    })
}

export const customChartUrlQueryFn = ({ queryKey }: any) => {
  const [endpoint, params] = queryKey
  const { trend } = params

  const isPIT = trend === TBChartTrendType.POINT_IN_TIME

  return api.get(endpoint).then((data) =>
    formatChartData({
      ...params,
      data: data?.data,
      isPIT,
    })
  )
}

export const postDashboardQueryFn = (requestJson: any) =>
  api
    .post(QUERY_KEYS.TrackerBuilder.CUSTOM_DASHBOARD, requestJson, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.data)

export const putDashboardQueryFn = ({ id, requestJson }: any) =>
  api.put(`${QUERY_KEYS.TrackerBuilder.CUSTOM_DASHBOARD}/${id}`, requestJson, {
    headers: {
      'Content-Type': 'application/json',
    },
    // Allow 304 - Not Modified
    validateStatus: (status) =>
      (status >= 200 && status < 300) || status === 304,
  })

export const deleteDashboardQueryFn = (id: string | number) =>
  api
    .delete(`${QUERY_KEYS.TrackerBuilder.CUSTOM_DASHBOARD}/${id}`)
    .then((res) => res?.data)
