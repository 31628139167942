import React from 'react'

import Icon from '~globalComponents/Icon'
import SenseModal from '~globalComponents/SenseModal'
import { useAccount } from '~hooks'

import * as s from './DisabledLinkModal.module.scss'

const DisabledLinkModal = ({ isOpen, handleClose }) => {
  const { data: accountInfo } = useAccount()

  return (
    <SenseModal variant="disabledLink" show={isOpen} handleClose={handleClose}>
      <div className={s.top}>
        <h3>This dashboard is not currently included in your contract</h3>
        <p>Please contact your client engagement manager to discuss</p>
      </div>
      <div className={s.bottom}>
        <h3>{accountInfo?.rep?.name}</h3>
        <ul>
          <li>
            <Icon name="phone" />
            <a href={`tel:${accountInfo?.rep?.phone}`}>
              {accountInfo?.rep?.phone}
            </a>
          </li>
          <li>
            <Icon name="email" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`mailto:${accountInfo?.rep?.email}`}
            >
              {accountInfo?.rep?.email}
            </a>
          </li>
        </ul>
      </div>
    </SenseModal>
  )
}

export default DisabledLinkModal
