import React from 'react'
import { Spinner } from 'react-bootstrap'
import cn from 'classnames'

import { VARIANTS } from './constants'

import Icon from '../../Icon'

export const SubmitButton = ({
  title,
  isLoading,
  iconProps = { name: 'check' },
  variant = VARIANTS.GREEN,
}) => {
  return (
    <button
      type="submit"
      disabled={isLoading ? 'disabled' : false}
      className={cn('btn', variant)}
    >
      <span>{title}</span>

      {isLoading ? (
        <Spinner animation="border" role="status" className="spinner-white" />
      ) : (
        <Icon {...iconProps} />
      )}
    </button>
  )
}
