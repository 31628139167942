import React from 'react'

import { useSearch } from '~hooks'
import { SearchBar } from '../../SearchBar'
import { GradientBoundaries, LoadingPlaceholder } from '../../UI'

import { AddBrandsList } from './AddBrandsList'
import { useSearchBrands, useAddCompetitor } from '../hooks'

export const AddBrands = ({
  userCompetitors,
  currentBrand,
  handleAddBrand,
}) => {
  const { searchTerm, debouncedSearchTerm, handleChange, handleClear } =
    useSearch()
  const { filteredCompetitors } = useSearchBrands(
    debouncedSearchTerm,
    userCompetitors,
    currentBrand
  )

  const { mutate: onAddBrand, isLoading } = useAddCompetitor()

  return (
    <>
      <h3>Add Brands to Competitive Set</h3>
      <div className="cs__search">
        <SearchBar
          id="settingsSearch"
          onChange={handleChange}
          onClear={handleClear}
          value={searchTerm}
        />
      </div>
      <GradientBoundaries className="cs__add-list">
        <div className="cs__add-list-inner">
          <LoadingPlaceholder loading={isLoading} />
          <AddBrandsList
            brands={filteredCompetitors}
            onAddBrand={handleAddBrand ?? onAddBrand}
          />
        </div>
      </GradientBoundaries>
    </>
  )
}
