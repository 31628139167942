import { TBTopicType } from '../../enums'
import getChartBarOptions from './getChartBarOptions'
import formatBrandPerceptionData from './formatBrandPerceptionData'
import formatCustomerExperienceData from './formatCustomerExperienceData'

export const formatMultipleBarChartData = ({
  data,
  topicName,
  brand,
  benchmark,
  score,
  isPIT,
  dashboardOptions,
}: any) => {
  switch (topicName) {
    case TBTopicType.BRAND_PERCEPTION: {
      const props = formatBrandPerceptionData({
        data,
        brand,
        benchmark,
        isPIT,
      })
      return { ...props }
    }

    case TBTopicType.CUSTOMER_EXPERIENCE_RESTAURANT:
    case TBTopicType.CUSTOMER_EXPERIENCE_RETAIL: {
      const props = formatCustomerExperienceData({
        data,
        brand,
        score,
        isPIT,
        topicName,
        dashboardOptions,
      })
      return { ...props }
    }

    default: {
      const chartData = getChartBarOptions(data, brand)
      return { chartData, isPIT }
    }
  }
}
