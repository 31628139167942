import React, { useState, useEffect, useRef } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import { Spinner } from 'react-bootstrap'
import { groupBy } from 'lodash-es'
import cn from 'classnames'

import Tooltip from '~globalComponents/Tooltip'
import Icon from '~globalComponents/Icon'
import { events, QUERY_KEYS } from '~api'
import { useClickOutside, useShowTooltip } from '~hooks'
import { EXPORT_STATUS, EVENT_KEYS } from '~constants'
import { getStorageItem, setStorageItem } from '~utils/sessionStorage'
import ExportsPopUp from '../ExportsPopUp'
import { TOOLTIP } from './constants'
import * as s from './NanLinkExports.module.scss'

const { READY, FAILED, PROCESSING } = EXPORT_STATUS

const NavLinkExports = () => {
  const { search } = useLocation()
  const { exports } = qs.parse(search)

  const [isReportsProcessing, setIsReportsProcessing] = useState(false)
  const [watchExports, setWatchExports] = useState(true)
  const [hasNewExport, setHasNewExport] = useState(false)
  const [showPopUp, setShowPopUp] = useState(exports === '1')

  const popUpWrapperRef = useRef(null)
  useClickOutside([popUpWrapperRef], () => setShowPopUp(false))

  const {
    showTooltip,
    tooltipProps,
    handleContent: handleTooltipContent,
    setShowTooltip,
  } = useShowTooltip()

  const { data, refetch: refetchReports } = useQuery([QUERY_KEYS.REPORTS], {
    enabled: watchExports,
    refetchInterval: 5000,
    onSuccess: (res) => {
      const exportIds = getStorageItem('watchExportIds')
      const newReports = res?.reports?.filter((report) =>
        exportIds?.includes(report?.id)
      )
      const newReportsByStatus = groupBy(newReports, 'status')
      const reportsByStatus = groupBy(res?.reports, 'status')
      // handle ready exports
      setHasNewExport(!!reportsByStatus?.[READY]?.length) // add success export dot

      if (newReportsByStatus?.[READY]?.length) {
        handleTooltipContent(TOOLTIP.SUCCESS)
      }

      // handle failed exports
      if (newReportsByStatus?.[FAILED]?.length) {
        handleTooltipContent(TOOLTIP.ERROR)
      }

      // leave only reports in processing in sessionStorage
      const processingReportsId = newReportsByStatus?.[PROCESSING]?.map(
        (report) => report?.id
      )

      if (processingReportsId?.length) {
        setIsReportsProcessing(true)
        setStorageItem('watchExportIds', processingReportsId)
      } else {
        sessionStorage.removeItem('watchExportIds')
        setIsReportsProcessing(false)
      }

      // refetch only if any of watchExportIds still in processing
      setWatchExports(!!reportsByStatus?.[PROCESSING]?.length)
    },
    cacheTime: 0,
  })

  // handle '/queue' query events
  useEffect(() => {
    const onSuccess = () => {
      refetchReports() // refetch '/reports' query
      handleTooltipContent(TOOLTIP.PROCESSING) // show processing tooltip
    }

    const onError = () => {
      handleTooltipContent(TOOLTIP.ERROR) // show error tooltip
    }

    events.on(EVENT_KEYS.REPORT_EXPORT_SUCCESS, onSuccess)
    events.on(EVENT_KEYS.REPORT_EXPORT_ERROR, onError)

    return () => {
      events.off(EVENT_KEYS.REPORT_EXPORT_SUCCESS, onSuccess)
      events.off(EVENT_KEYS.REPORT_EXPORT_ERROR, onError)
    }
  }, [])

  const handleExportLink = () => {
    setShowTooltip(false)
    setShowPopUp(!showPopUp)
  }

  return (
    <Tooltip
      variant="exports"
      hasTooltip={false}
      show={showTooltip && !showPopUp}
      {...tooltipProps}
    >
      <div ref={popUpWrapperRef} className={s.wrapper}>
        <button
          type="button"
          className={cn(s.btnExport, {
            [s.notification]: hasNewExport,
          })}
          onClick={handleExportLink}
        >
          {!isReportsProcessing ? (
            <Icon name="icon-download" />
          ) : (
            <Spinner animation="border" role="status" className="spinner-icon">
              <Icon name="download-spinner" />
            </Spinner>
          )}
          Exports
        </button>
        <ExportsPopUp reports={data?.reports} show={showPopUp} />
      </div>
    </Tooltip>
  )
}

export default NavLinkExports
