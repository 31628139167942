/* eslint-disable no-nested-ternary */
import React, { forwardRef, memo, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import { Loader } from '~globalComponents/Loader'
import { NoDataPlaceholder } from '~globalComponents/NoDataPlaceholder'

import { CHART_HISTORY } from '~utils/chartHistory/constants'
import { getChartDataLink, dateDiff } from '~utils/chartHistory'

import { ChartLine, ChartLineCommonProps } from './ChartLine'
import { NO_CHART_DATA_TEXT } from './constants'
import { formatChartLineData } from './utils'

interface ChartLineWithRequestProps extends ChartLineCommonProps {
  data: any
  chartKey?: string
}

const ChartLineWithRequest = forwardRef<any, ChartLineWithRequestProps>(
  (props, ref) => {
    const {
      brand,
      aggregation,
      showPercentSymbolSuffix = true,
      shortDashLineSeriesNames,
      noAnimation,
      data,
      // Chart history
      chartKey,
      isHistorySelectorVisible,
    } = props
    const { start_date: startDate, end_date: endDate } = data || {}

    const [chartPeriodMonths, setChartPeriodMonths] = useState<{
      label: string
      value: number
    }>(CHART_HISTORY[0])

    const resetChartPeriodMonths = () => setChartPeriodMonths(CHART_HISTORY[0])

    useEffect(() => {
      if (chartKey) resetChartPeriodMonths()
    }, [chartKey])

    const dataLink = getChartDataLink(data, chartPeriodMonths.value)

    const { months } = dateDiff(startDate, endDate)
    const historyOptions = CHART_HISTORY.filter(({ value }) => value < months)

    const {
      data: rawData,
      isInitialLoading: isLoading,
      isError,
    } = useQuery([dataLink], { enabled: !!dataLink })

    const {
      chartData,
      isChartEmpty,
    }: {
      chartData: ChartLine.ChartData | undefined
      isChartEmpty: boolean
    } = useMemo(
      () => formatChartLineData({ data: rawData, showPercentSymbolSuffix }),
      [rawData, showPercentSymbolSuffix]
    )

    if (isLoading) {
      return <Loader variant="smallIcon" />
    }

    if (isChartEmpty) {
      return <NoDataPlaceholder variant="center" message={NO_CHART_DATA_TEXT} />
    }

    if (isError) {
      return <NoDataPlaceholder variant="center" isError />
    }

    if (chartData)
      return (
        <ChartLine
          {...{
            chartData,
            brand,
            aggregation,
            showPercentSymbolSuffix,
            shortDashLineSeriesNames,
            noAnimation,
            ref,
            // Chart history (period)
            historyOptions,
            chartPeriodMonths,
            setChartPeriodMonths,
            isHistorySelectorVisible,
          }}
        />
      )

    return null
  }
)

const MemoedChartLineWithRequest = memo(ChartLineWithRequest)

export { MemoedChartLineWithRequest as ChartLineWithRequest }

/* Usages:

Customer Experience
http://localhost:3000/customer_experience?benchmark=Competitive%20Set&brand=McDonald%27s&daypart=Entire%20Day&end_date=2022-01-01&order=Total&period=1&score=topbox&start_date=2022-01-01&trend=POP&vtab=Attribute%20Deep%20Dive
http://localhost:3000/petco_instore_customer_experience?benchmark=PetSmart&brand=Petco&channel=instore&end_date=2022-04-01&period=1&score=topbox&start_date=2022-04-01&trend=POP&vtab=Attribute%20Deep%20Dive
http://localhost:3000/petco_digital_customer_experience?benchmark=Chewy.com&brand=Petco.com&channel=online&end_date=2022-04-01&period=1&score=topbox&start_date=2022-04-01&trend=POP&vtab=Attribute%20Deep%20Dive
src/pages/Dashboard/CustomerExperience/components/Tabs/AttributeDeepDiveTab/index.ts

KPI Tracking
http://localhost:3000/kpi_tracking?benchmark=Competitive%20Set&brand=McDonald%27s&date_filter=November%202021&end_date=2021-11-02&geo=National&market=Market%20Benchmark&month=11&period=Month&stab=Panel%20Sales&start_date=2017-11-01&trend=YOY&vtab=Total%20Performance
src/pages/Dashboard/KeyPerformance/components/TabItem/index.ts

Brand Perception
http://localhost:3000/brand_perception?benchmark=Competitive%20Set&brand=McDonald%27s&ddtab=Convenience%20Average&end_date=2021-07-01&htab=Top%20Drivers&period=1&score=pct&start_date=2021-07-01&trend=POP&visitation=Total&vtab=Attribute%20Deep%20Dive
src/pages/Dashboard/BrandPerception/components/DeepDive/index.ts

Tracker Builder
http://localhost:3000/tracker_builder/146
src/pages/Dashboard/TrackerBuilder/components/SlidesEditor/components/GraphContainer/index.tsx

TODO (extra customization needed): src/pages/PublicMarketing/components/Card/components/LineChart/index.ts
TODO (if logic applicable): src/components/CustomerProfileSubcards/components/ExpandedSubcard.js

*/
