import React from 'react'
import cn from 'classnames'
import Icon from '~globalComponents/Icon'
import * as s from './Note.module.scss'

export const Note = ({ content, variant, iconSize = 24 }) => (
  <div className={cn(s.wrap, { [s[variant]]: variant })}>
    <Icon name="info_filled" size={iconSize} />
    <span className={s.content}>{content}</span>
  </div>
)
