import React from 'react'
import cn from 'classnames'

import * as s from './AutoTextarea.module.scss'

export const AutoTextarea = ({
  variant = '',
  value,
  handleChange,
  handleBlur,
  readOnly,
  maxLength,
  placeholder = '',
}) => (
  // using .inputSizer::after to calculate the height of the textarea based on the text entered
  <div
    className={cn(s.inputSizer, { [s[variant]]: variant })}
    data-value={value}
  >
    <textarea
      className={s.textarea}
      rows={1}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      readOnly={readOnly}
      maxLength={maxLength}
    />
  </div>
)
