import { useRef, useEffect } from 'react'
import { debounce } from 'lodash-es'

export const useResizeChart = () => {
  const chartRef = useRef(null)
  const chartWrapRef = useRef(null)

  const handleResize = () => {
    const container = chartRef.current?.container?.current
    if (container) {
      container.style.width = `${chartWrapRef.current.clientWidth}px`
      chartRef.current.chart.reflow()
    }
  }

  useEffect(() => {
    window.addEventListener('resize', debounce(handleResize, 300))
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { chartRef, chartWrapRef, handleResize }
}
