import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '~api'

import { queryFn } from './utils'

export const useDashboardOptions = (brand?: string, onSuccess?: () => void) => {
  const queryResult = useQuery<TBOptionsResponse.RootObject>(
    [
      QUERY_KEYS.TrackerBuilder.CUSTOM_DASHBOARDS_OPTIONS,
      { params: { brand } },
    ],
    // TODO: remove queryFn after adding drivers chart types
    queryFn,
    { enabled: !!brand }
  )

  // TODO: try to avoid using useEffect
  useEffect(() => {
    if (queryResult.isSuccess && onSuccess) {
      onSuccess()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult.data, queryResult.isSuccess, brand])

  return queryResult
}
