import React, { useMemo } from 'react'
import cn from 'classnames'

import Tooltip from '~globalComponents/Tooltip'
import { getIsCellDisabled, getActiveClass } from './utils'
import * as s from './Cell.module.scss'

export const Cell = ({
  date,
  type = 'month',
  range,
  phase,
  isFirstYear,
  isLastYear,
  minDate,
  maxDate,
  variant,
  onSelect,
  dateFormat = 'MMMM',
  tooltip,
}) => {
  const isEmptyCeil = useMemo(() => {
    if (!isFirstYear && !isLastYear) {
      return false
    }
    const firstItem = minDate.get(type)
    const lastItem = maxDate.get(type)
    const dateItem = date.get(type)

    return (
      (isFirstYear && dateItem < firstItem) ||
      (isLastYear && dateItem > lastItem)
    )
  }, [date, isFirstYear, isLastYear, maxDate, minDate])

  const isDisabled = getIsCellDisabled({ date, type, minDate, range, phase })

  return isEmptyCeil ? (
    <div />
  ) : (
    <button
      type="button"
      onClick={onSelect}
      className={cn(s.cell, {
        [s.active]: getActiveClass(date, range),
        [s[variant]]: variant,
      })}
      disabled={isDisabled}
    >
      {tooltip ? (
        <Tooltip
          variant="date"
          size="sm"
          content={tooltip.message}
          placement="bottomStart"
          withIcon
        >
          {date.toFormat(dateFormat)}
        </Tooltip>
      ) : (
        date.toFormat(dateFormat)
      )}
    </button>
  )
}
