import { TBChartType, TBChartTrendType, TBChartScoreType } from './enums'

import linearChartSkeleton from '../assets/LinearChartSkeleton.svg'
import singleMetricBarChartSkeleton from '../assets/SingleMetricBarChartSkeleton.svg'
import singleMetricBarChartTrendSkeleton from '../assets/SingleMetricBarChartTrendSkeleton.svg'
import singleMetricTableSkeleton from '../assets/SingleMetricTableSkeleton.svg'
import multipleMetricBarChartSkeleton from '../assets/MultipleMetricBarChartSkeleton.svg'
import multipleMetricBarChartTrendSkeleton from '../assets/MultipleMetricBarChartTrendSkeleton.svg'
import multipleMetricTableSkeleton from '../assets/MultipleMetricTableSkeleton.svg'

export const TB_LOCAL_STORAGE_KEY = 'sense360:trackerBuilder'

export const TB_CHART_TRENDS = [
  { label: 'Point in Time', value: TBChartTrendType.POINT_IN_TIME },
  { label: 'YoY', value: TBChartTrendType.YEAR_OVER_YEAR },
  { label: 'PoP', value: TBChartTrendType.PERIOD_OVER_PERIOD },
  { label: 'vs. 2019', value: TBChartTrendType.VS_2019 },
]

export const TB_CHART_SCORES = [
  { label: 'BIP Score', value: TBChartScoreType.BIP_SCORE },
  { label: 'Percentage Score', value: TBChartScoreType.PERCENT_SCORE },
  { label: 'Top Box', value: TBChartScoreType.TOP_BOX },
  { label: 'Average Score', value: TBChartScoreType.AVERAGE_SCORE },
]

export interface ITBChartType {
  name: string
  label: string
  iconName: string
  value: TBChartType
  skeletonImgSrc: string
  isPIP?: boolean
  isTrend?: boolean
}

// TODO: chart icons and placeholders
export const TB_CHART_TYPES: ITBChartType[] = [
  {
    name: 'Line Chart - Point in Time',
    label: 'Line Chart',
    iconName: 'icon-line_chart',
    value: TBChartType.LINE_CHART_PIT,
    skeletonImgSrc: linearChartSkeleton,
    isPIP: true,
  },
  {
    name: 'Line Chart - Trend',
    label: 'Line Chart',
    iconName: 'icon-line_chart',
    value: TBChartType.LINE_CHART_TREND,
    skeletonImgSrc: linearChartSkeleton,
    isTrend: true,
  },

  {
    name: 'Single Metric Table - Point in Time',
    label: 'Table',
    iconName: 'icon-table_chart',
    value: TBChartType.SINGLE_METRIC_TABLE_PIT,
    skeletonImgSrc: singleMetricTableSkeleton,
    isPIP: true,
  },
  {
    name: 'Single Metric Table - Trend',
    label: 'Table',
    iconName: 'icon-table_chart',
    value: TBChartType.SINGLE_METRIC_TABLE_TREND,
    skeletonImgSrc: singleMetricTableSkeleton,
    isTrend: true,
  },

  {
    name: 'Multiple Metric Table - Point in Time',
    label: 'Table',
    iconName: 'icon-table_chart',
    value: TBChartType.MULTI_METRIC_TABLE_PIT,
    skeletonImgSrc: multipleMetricTableSkeleton,
    isPIP: true,
  },
  {
    name: 'Multiple Metric Table - Trend',
    label: 'Table',
    iconName: 'icon-table_chart',
    value: TBChartType.MULTI_METRIC_TABLE_TREND,
    skeletonImgSrc: multipleMetricTableSkeleton,
    isTrend: true,
  },

  {
    name: 'Single Metric Bar Chart - Point in Time',
    label: 'Bar Chart',
    iconName: 'icon-bar_chart',
    value: TBChartType.SINGLE_METRIC_BAR_CHART_PIT,
    skeletonImgSrc: singleMetricBarChartSkeleton,
    isPIP: true,
  },
  {
    name: 'Single Metric Bar Chart - Trend',
    label: 'Bar Chart',
    iconName: 'icon-waterfall_chart',
    value: TBChartType.SINGLE_METRIC_BAR_CHART_TREND,
    skeletonImgSrc: singleMetricBarChartTrendSkeleton,
    isTrend: true,
  },

  {
    name: 'Multiple Metric Bar Chart - Point in Time',
    label: 'Bar Chart',
    iconName: 'icon-bar_chart',
    value: TBChartType.MULTI_METRIC_BAR_CHART_PIT,
    skeletonImgSrc: multipleMetricBarChartSkeleton,
    isPIP: true,
  },
  {
    name: 'Multiple Metric Bar Chart - Trend',
    label: 'Bar Chart',
    iconName: 'icon-waterfall_chart',
    value: TBChartType.MULTI_METRIC_BAR_CHART_TREND,
    skeletonImgSrc: multipleMetricBarChartTrendSkeleton,
    isTrend: true,
  },

  {
    name: 'Top Drivers (?)',
    label: 'Top Drivers (?)',
    iconName: 'icon-line_chart',
    value: TBChartType.TOP_DRIVERS,
    skeletonImgSrc: linearChartSkeleton,
    isPIP: true,
    isTrend: true,
  },
]

export const TB_METRIC_ICONS: Record<
  string,
  { iconName: string; isFromTBIconSprite?: boolean }
> = {
  'snp-total_performance_panel_sales': { iconName: 'income' },
  'snp-total_performance_wallet_share': {
    iconName: 'icon-account_balance_wallet',
    isFromTBIconSprite: true,
  },
  'snp-total_performance_top_drivers_of_performance': {
    iconName: 'icon-car_crash',
    isFromTBIconSprite: true,
  },
  'snp-total_performance_unique_drivers_of_performance': {
    iconName: 'icon-person_pin',
    isFromTBIconSprite: true,
  },
  'snp-market_trends_market_sales': {
    iconName: 'icon-account_box',
    isFromTBIconSprite: true,
  },
  'snp-transaction_kpis_physical_panel_sales': {
    iconName: 'icon-hand',
    isFromTBIconSprite: true,
  },
  'snp-transaction_kpis_digital_panel_sales': {
    iconName: 'icon-smartphone',
    isFromTBIconSprite: true,
  },
  'snp-transaction_kpis_3rd_party_delivery_panel_sales': {
    iconName: 'icon-delivery',
    isFromTBIconSprite: true,
  },
  'snp-transaction_kpis_penetration': {
    iconName: 'icon-penetration',
    isFromTBIconSprite: true,
  },
  'snp-transaction_kpis_frequency': {
    iconName: 'icon-frequency',
    isFromTBIconSprite: true,
  },
  'snp-transaction_kpis_average_check_size': {
    iconName: 'icon-check_size',
    isFromTBIconSprite: true,
  },
  'snp-foot_traffic_kpis_visit_share': {
    iconName: 'icon-share',
    isFromTBIconSprite: true,
  },
  'snp-foot_traffic_kpis_brand_draw': {
    iconName: 'icon-draw',
    isFromTBIconSprite: true,
  },

  // Customer Profile
  'snp-customer_profile_gender': {
    iconName: 'gender',
  },
  'snp-customer_profile_income': {
    iconName: 'income',
  },
  'snp-customer_profile_ethnicity': {
    iconName: 'icon-ethnicity',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_hispanic': {
    iconName: 'hispanic',
  },
  'snp-customer_profile_age': {
    iconName: 'age',
  },
  'snp-customer_profile_education': {
    iconName: 'education',
  },
  'snp-customer_profile_relationship': {
    iconName: 'relationship',
  },
  'snp-customer_profile_employment': {
    iconName: 'icon-profile_employment',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_household_size': {
    iconName: 'household_size',
  },
  'snp-customer_profile_less_than_40k_panel_sales': {
    iconName: 'income',
  },
  'snp-customer_profile_40_60k_panel_sales': {
    iconName: 'income',
  },
  'snp-customer_profile_60_80k_panel_sales': {
    iconName: 'income',
  },
  'snp-customer_profile_80k_panel_sales': {
    iconName: 'income',
  },
  'snp-customer_profile_african_american_panel_sales': {
    iconName: 'icon-accessibility',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_asian_panel_sales': {
    iconName: 'icon-accessibility',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_caucasian_panel_sales': {
    iconName: 'icon-accessibility',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_hispanic_panel_sales': {
    iconName: 'icon-accessibility',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_high_frequency_customer_panel_sales': {
    iconName: 'icon-ethnicity',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_low_frequency_customer_panel_sales': {
    iconName: 'icon-accessibility',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_new_returned_customer_panel_sales': {
    iconName: 'icon-person_add',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_less_than_40k_wallet_share': {
    iconName: 'icon-account_balance_wallet',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_40_60k_wallet_share': {
    iconName: 'icon-account_balance_wallet',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_60_80k_wallet_share': {
    iconName: 'icon-account_balance_wallet',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_80k_wallet_share': {
    iconName: 'icon-account_balance_wallet',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_african_american_wallet_share': {
    iconName: 'icon-accessibility',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_asian_wallet_share': {
    iconName: 'icon-accessibility',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_caucasian_wallet_share': {
    iconName: 'icon-accessibility',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_hispanic_wallet_share': {
    iconName: 'icon-accessibility',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_high_frequency_customer_wallet_share': {
    iconName: 'icon-ethnicity',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_low_frequency_customer_wallet_share': {
    iconName: 'icon-accessibility',
    isFromTBIconSprite: true,
  },
  'snp-customer_profile_new_returned_customer_wallet_share': {
    iconName: 'icon-person_add',
    isFromTBIconSprite: true,
  },

  // Brand Perception
  'snp-brand_perception_average_performance_by_category': {
    iconName: 'icon-category',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_drivers': {
    iconName: 'icon-car',
    isFromTBIconSprite: true,
  },
  'snp-convenience_attributes': {
    iconName: 'icon-task-checked',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_convenience_attributes': {
    iconName: 'icon-premium',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_food_attributes': {
    iconName: 'restaurant_wallet_share',
  },
  'snp-brand_perception_identity_attributes': {
    iconName: 'icon-branding_watermark',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_menu_attributes': {
    iconName: 'icon-summarize',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_value_attributes': {
    iconName: 'brand',
  },
  'snp-brand_perception_has_a_good_atmosphere': {
    iconName: 'icon-satisfied',
    isFromTBIconSprite: true,
  },

  'snp-brand_perception_is_clean': {
    iconName: 'icon-clean',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_is_consistent': {
    iconName: 'icon-task-checked',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_a_fun_atmosphere': {
    iconName: 'icon-very-satisfied',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_is_good_for_kids': {
    iconName: 'icon-family',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_doesn_t_feel_too_corporate': {
    iconName: 'icon-work_history',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_flavorful_food': {
    iconName: 'icon-dinner',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_food_that_tastes_homemade': {
    iconName: 'icon-food_bank',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_natural_authentic_food': {
    iconName: 'icon-rice_bowl',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_high_quality_food': {
    iconName: 'icon-brunch_dining',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_healthy_food': {
    iconName: 'icon-eco',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_offers_vegetarian_options': {
    iconName: 'icon-preference_eco',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_offers_low_calorie_options': {
    iconName: 'icon-carrot',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_is_conveniently_located': {
    iconName: 'icon-home_pin',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_menu_items_i_crave': {
    iconName: 'icon-menu_book',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_good_late_night_food': {
    iconName: 'icon-nights_stay',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_good_breakfast_food': {
    iconName: 'icon-lunch_dining',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_easily_customizable_menu_items': {
    iconName: 'icon-receipt_long',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_good_dessert_options': {
    iconName: 'icon-icecream',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_offers_good_non_alcoholic_beverages': {
    iconName: 'icon-no_drinks',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_a_good_variety_of_menu_items': {
    iconName: 'icon-add_check',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_offers_new_and_exciting_items': {
    iconName: 'icon-person_add',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_is_a_place_i_m_okay_with_other_people_knowing_i_visit':
    {
      iconName: 'icon-recommend',
      isFromTBIconSprite: true,
    },
  'snp-brand_perception_has_good_corporate_values': {
    iconName: 'icon-work_history_good',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_is_different_from_other_restaurants': {
    iconName: 'icon-tapas',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_cares_about_the_environment': {
    iconName: 'icon-deck',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_a_good_reputation': {
    iconName: 'icon-verified',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_good_customer_service': {
    iconName: 'icon-how_to_reg',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_quick_fast_service': {
    iconName: 'icon-cube-quick',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_friendly_service': {
    iconName: 'icon-very-satisfied',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_is_affordable': {
    iconName: 'icon-is_affordable',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_offers_specials_deals_or_discounts': {
    iconName: 'icon-offers_specials_deals_or_discounts',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_is_a_good_value_for_the_money': {
    iconName: 'icon-credit_score',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_has_good_portion_sizes': {
    iconName: 'icon-brunch_dining',
    isFromTBIconSprite: true,
  },
  'snp-brand_perception_uses_technology_eg_self_service_kiosks_mobile_ordering_etc_to_provide_a_better_customer_experience':
    {
      iconName: 'icon-precision_manufacturing',
      isFromTBIconSprite: true,
    },

  // CX Retail
  'snp-customer_experience_retail_customer_experience_overview': {
    iconName: 'icon-fact_check',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_customer_experience_attributes': {
    iconName: 'icon-list_alt',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_non_buyer_analysis': {
    iconName: 'icon-assignment_return',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_digital_why_did_customers_not_make_a_purchase':
    {
      iconName: 'icon-globe',
      isFromTBIconSprite: true,
    },
  'snp-customer_experience_retail_in_store_why_did_customers_not_make_a_purchase':
    {
      iconName: 'icon-storefront',
      isFromTBIconSprite: true,
    },
  'snp-customer_experience_retail_overall_satisfaction': {
    iconName: 'icon-auto_awesome',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_net_promoter_score': {
    iconName: 'icon-stars',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_likelihood_to_return': {
    iconName: 'icon-assignment_return',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_customer_service_satisfaction': {
    iconName: 'icon-satisfied',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_has_brands_other_stores_don_t_have': {
    iconName: 'icon-library_add_check',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_has_good_private_brands': {
    iconName: 'icon-recommend',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_has_a_good_selection_of_products': {
    iconName: 'icon-fact_check',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_items_are_in_stock': {
    iconName: 'icon-inventory',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_has_high_quality_products': {
    iconName: 'icon-hotel_class',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_has_new_and_exciting_products': {
    iconName: 'icon-verified',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_grocery_products_are_fresh': {
    iconName: 'icon-eco',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_has_low_prices': {
    iconName: 'icon-sell',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_offers_good_value_for_the_money': {
    iconName: 'icon-balance',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_has_valuable_membership_rewards_programs': {
    iconName: 'icon-work',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_has_good_deals_promotions': {
    iconName: 'icon-groups',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_it_s_easy_to_make_returns_exchanges': {
    iconName: 'icon-sync',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_employees_are_helpful_and_friendly': {
    iconName: 'icon-face_retouching_natural',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_employees_understand_my_needs': {
    iconName: 'icon-diversity_3',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_employees_are_available_to_help': {
    iconName: 'icon-diversity_2',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_stores_are_clean_and_attractive': {
    iconName: 'icon-store',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_does_a_good_job_with_covid_19_safety_precautions':
    {
      iconName: 'icon-sanitizer',
      isFromTBIconSprite: true,
    },
  'snp-customer_experience_retail_has_affordable_shipping_and_delivery_options':
    {
      iconName: 'icon-directions_bike',
      isFromTBIconSprite: true,
    },
  'snp-customer_experience_retail_has_fast_shipping_delivery_and_pick_up_options':
    {
      iconName: 'icon-fastfood',
      isFromTBIconSprite: true,
    },
  'snp-customer_experience_retail_purchase': {
    iconName: 'icon-shopping_bag',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_didn_t_purchase': {
    iconName: 'icon-shopping_bag_cross',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_retail_purchase_intent': {
    iconName: 'icon-account_balance_wallet',
    isFromTBIconSprite: true,
  },

  // CX Restaurant
  'snp-customer_experience_restaurant_customer_experience_overview': {
    iconName: 'icon-fact_check',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_customer_experience_attributes': {
    iconName: 'icon-list_alt',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_in_store_operational_cx': {
    iconName: 'icon-storefront',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_drivers_of_choice': {
    iconName: 'icon-psychology_alt',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_delivery_satisfaction_and_accuracy': {
    iconName: 'icon-delivery_stars',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_delivery_customer_experience': {
    iconName: 'icon-sentiment_satisfied',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_overall_satisfaction': {
    iconName: 'icon-auto_awesome',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_net_promoter_score': {
    iconName: 'icon-stars',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_likelihood_to_return': {
    iconName: 'icon-assignment_return',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_inaccurate_order': {
    iconName: 'icon-inventory_cross',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_did_you_experience_a_problem_': {
    iconName: 'icon-flag',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_did_you_report_a_problem_': {
    iconName: 'icon-problem',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_problem_resolution_satisfaction': {
    iconName: 'icon-sentiment_very_satisfied',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_delivery_overall_satisfaction': {
    iconName: 'icon-delivery_stars',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_delivery_order_accuracy': {
    iconName: 'icon-inventory_check',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_delivery_time_estimate_accuracy': {
    iconName: 'icon-delivery_time',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_delivery_food_temperature': {
    iconName: 'icon-lunch_dining_temperatute',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_delivery_food_freshness': {
    iconName: 'icon-brunch_dining_fresh',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_delivery_value_for_the_money': {
    iconName: 'icon-local_atm',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_delivery_ease_of_ordering': {
    iconName: 'icon-order_approve',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_delivery_food_packaging': {
    iconName: 'icon-inventory',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_delivery_drop_off_precautions': {
    iconName: 'icon-inventory_key',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_ease_of_ordering_your_food': {
    iconName: 'icon-order_approve',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_the_portion_size_of_the_food': {
    iconName: 'icon-brunch_dining',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_the_taste_of_the_food': {
    iconName: 'icon-brunch_dining',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_quality_of_the_food': {
    iconName: 'icon-brunch_dining_star',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_freshness_of_the_food': {
    iconName: 'icon-brunch_dining_fresh',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_temperature_of_the_food': {
    iconName: 'icon-lunch_dining_temperatute',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_menu_options': {
    iconName: 'icon-menu_book',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_friendliness_of_the_staff': {
    iconName: 'icon-volunteer_activism',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_speed_of_the_service': {
    iconName: 'icon-speed',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_cleanliness_of_the_restaurant': {
    iconName: 'icon-mop',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_appearance_of_the_restaurant': {
    iconName: 'icon-local_florist',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_staff_hygiene': {
    iconName: 'icon-clean_hands',
    isFromTBIconSprite: true,
  },
  'snp-customer_experience_restaurant_value_for_the_price_paid': {
    iconName: 'icon-check_size',
    isFromTBIconSprite: true,
  },
}
