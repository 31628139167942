import { DateTime } from 'luxon'

import { getYearsList } from '../../utils'

export const getCalendarData = (startDate, endDate) => {
  const yearsList = getYearsList(startDate, endDate)

  const calendarData = yearsList.reduce((acc, year) => {
    const quarters = [0, 1, 2, 3].map((quarter) =>
      DateTime.local(year, quarter * 3 + 1, 1)
    )
    acc.push({ year, quarters })
    return acc
  }, [])

  return calendarData
}
