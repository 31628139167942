import { useState } from 'react'

export const useFoldedState = () => {
  const [isUnfolded, setIsUnfolded] = useState(false)

  const closeFold = () => setIsUnfolded(false)
  const toggleFold = () => setIsUnfolded((prevState) => !prevState)

  return { isUnfolded, closeFold, toggleFold }
}
