import { useState, useEffect } from 'react'

export const useScrollLinks = ({ links, topOffset = 32 }) => {
  const [activeTab, setActiveTab] = useState(links?.[0].id)

  const handleScroll = () => {
    links.forEach(({ id }) => {
      const el = document.getElementById(id)
      if (el) {
        const isActive = window.pageYOffset - el.offsetTop > 0
        if (isActive) setActiveTab(id)
      }
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, false)
    return () => {
      window.removeEventListener('scroll', handleScroll, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLink = (e, id) => {
    e.preventDefault()

    const elPosition =
      document.getElementById(id).getBoundingClientRect().top +
      window.pageYOffset

    window.scrollTo({ top: elPosition - topOffset, behavior: 'smooth' })
  }

  return { activeTab, handleLink }
}
