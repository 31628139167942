import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Icon from '../Icon'

import { modal, closeButton } from './style.module.scss'

export const ModalWrapper = ({ isOpen, handleClose, children }) => (
  <Modal show={isOpen} onHide={handleClose} centered dialogClassName={modal}>
    {children}
    <button className={closeButton} type="button" onClick={handleClose}>
      <Icon name="cross_big" size={24} />
    </button>
  </Modal>
)
