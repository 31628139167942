import React, { useState, useMemo } from 'react'

import { CalendarLayout } from '../CalendarLayout'
import { Cell } from '../Cell'
import { TYPES } from '../constants'
import { PickerHeader } from './PickerHeader'
import { getCalendarData } from './utils'

import * as s from './MonthCalendar.module.scss'

export const MonthCalendar = ({
  minDate,
  maxDate,
  range,
  setRange,
  isOpen,
}) => {
  const [activeYear, setActiveYear] = useState(range?.endDate || maxDate)

  const isOneYearRange = minDate.year === maxDate.year

  const calendarData = useMemo(() => {
    const startYear = isOneYearRange
      ? activeYear
      : activeYear.minus({ years: 1 })
    return getCalendarData(startYear, activeYear)
  }, [activeYear, isOneYearRange])

  const yearsToStart = activeYear.year - minDate.year
  const yearsToEnd = Math.abs(activeYear.year - maxDate.year)

  const handleDecreaseYear = () => setActiveYear(activeYear.minus({ years: 1 }))
  const handleIncreaseYear = () => setActiveYear(activeYear.plus({ years: 1 }))

  const handleOption = (date, isFirstYear) => {
    setRange({ startDate: date.minus({ months: 11 }), endDate: date })

    const isLeftPage = date.year !== activeYear.year
    if (isLeftPage && !isFirstYear) {
      handleDecreaseYear()
    }
  }

  return (
    <CalendarLayout variant="month" isOpen={isOpen}>
      <div className={s.header}>
        <p className={s.headerDescr}>
          Select the end date of your analysis period.
        </p>
        {isOneYearRange ? (
          <p className={s.headerYear}>{activeYear.toFormat('yyyy')}</p>
        ) : (
          <PickerHeader
            date={activeYear}
            decreaseYear={handleDecreaseYear}
            increaseYear={handleIncreaseYear}
            prevYearButtonDisabled={yearsToStart < 2}
            nextYearButtonDisabled={yearsToEnd < 1}
          />
        )}
      </div>

      <div className={s.pagesWrap}>
        {calendarData.map(({ year, months }) => (
          <div className={s.page} key={year}>
            {months.map((date) => (
              <Cell
                {...{ date, range, minDate, maxDate }}
                key={date}
                type={TYPES.MONTH}
                onSelect={() => handleOption(date, minDate.year === year)} // isFirstYear
                isFirstYear={minDate.year === year}
                isLastYear={maxDate.year === year}
                dateFormat="MMMM"
              />
            ))}
          </div>
        ))}
      </div>
    </CalendarLayout>
  )
}
