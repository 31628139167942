import { TBTopicType } from '../../enums'
import { formatBrandPerceptionData } from './formatBrandPerceptionData'
import { formatCustomerExperienceData } from './formatCustomerExperienceData'

export const formatMultipleTableData = ({
  data,
  topicName,
  dashboardOptions,
}: any) => {
  switch (topicName) {
    case TBTopicType.BRAND_PERCEPTION: {
      const props = formatBrandPerceptionData(data)
      return { ...props, sample: data.sample }
    }

    case TBTopicType.CUSTOMER_EXPERIENCE_RESTAURANT:
      return formatCustomerExperienceData({
        data,
        metricTypes: dashboardOptions?.metric_types_restaurant,
      })

    case TBTopicType.CUSTOMER_EXPERIENCE_RETAIL:
      return formatCustomerExperienceData({
        data,
        metricTypes: dashboardOptions?.metric_types_retail,
      })

    default:
      return { chartData: undefined }
  }
}
