export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  SETTINGS: '/settings',
  COMPETITIVE_PERFORMANCE: '/competitive_performance',
  MARKET_PERFORMANCE: '/market_performance',
  CUSTOMER_PROFILE: '/customer_profile',
  CUSTOMER_EXPERIENCE: '/customer_experience',
  PETCO_INSTORE_CUSTOMER_EXPERIENCE: '/petco_instore_customer_experience',
  PETCO_DIGITAL_CUSTOMER_EXPERIENCE: '/petco_digital_customer_experience',
  CUSTOMER_MIGRATION: '/customer_migration',
  GENERIC_DASHBOARD: '/dashboard',
  BRAND_PERCEPTION: '/brand_perception',
  KPI_PERFORMANCE: '/kpi_tracking',
  ERROR: '/error',
  HEALTH: '/health',
  LOGIN_SSO: '/login/sso',
  CALLBACK: '/callback',
  PASSWORD_INIT_RESET: '/password/reset',
  PASSWORD_RESET: '/reset',
  INSIGHTS_BRIEFINGS: '/insights_briefings',
  UNSUBSCRIBE: '/unsubscribe',
  IDP: '/idp',
  BRAND_LOGOS: '/brand_logos',
  SPRITE_ICONS: '/sprite_icons',
  PUBLIC_MARKETING: '/public_marketing',
  TRACKER_BUILDER: '/tracker_builder',
}
